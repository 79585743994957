import { useState, useEffect } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import MiniSidebar from "./MiniSidebar";
import {
  CheckCircleOutline,
  InsertPhotoOutlined,
  Edit,
} from "@mui/icons-material";
import { ToastContainer } from "react-toastify";
import { BASE_URL, axiosInstance, toastError, toastSuccess } from "../../../Utils/Config";

const EditUpsell = () => {
  const [saved, setSaved] = useState(false);
  const [items, setItems] = useState([])
  const { company_name, id } = useParams();
  const navigate = useNavigate();
  const itemString = sessionStorage.getItem("Restaurant");
  const item = JSON.parse(itemString);
  const { userId } = JSON.parse(sessionStorage.getItem("userId"));
  const location = useLocation()
  const {data} = location.state
  const [formState, setFormState] = useState({
    upsell_description: data.description,
    where_to_appear: data.appear['name'],
    status: data.status
  })

  useEffect(()=>{
    console.log("Data: ", data)
    fetchItems()
  },[])

  const changeHandler = (event) => {
    const {name, value} = event.target
    setFormState(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const fetchItems = async () => {
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}/item/restaurant/${item["id"]}`
      );
      const data = response.data;
      if (data.code === 200) {
        setItems(data.items);
      } 
    } catch (error) {
      console.error("An error occured: ", error)
    }
  };

  const apiCall = async () => {
    const payload = {
      // restaurant: "",
      // branch: "",
      // items: "",
      appear: formState.where_to_appear,
      status: formState.status,
      description: formState.upsell_description
    }
    try {
      const response = await axiosInstance.put(`${BASE_URL}/upsell/${id}/${userId}`, payload)
      const data = response.data
      if(data.code === 200){
        toastSuccess(data.message)
        setTimeout(()=>{
          navigate(-1)
        },1700)
      }
    } catch (error) {
      toastError(error.code)
    }
  }

  return (
    <div>
      <div className="breadcrump">
        <Link to="/admin/restaurants" className="breadcrumb-not-active">
          Home
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link to="/admin/restaurants" className="breadcrumb-active">
          All Restaurants
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link
          onClick={(event) => event.preventDefault()}
          className="breadcrumb-active"
        >
          {company_name}
        </Link>
      </div>
      <div className="App-container-header-table-wrapper">
        <div className="App-container-header">
          <p>{company_name}</p> - <p>Edit Upsell</p>
        </div>
        <div className="App-container-table">
          <div className="restaurant-table-wrapper">
            <div style={{ paddingTop: "33px" }}>
              <MiniSidebar />
            </div>
            <div className="table-container">
              <div className="App-table restaurant-app-table">
                <div className="App-container-row-body-full">
                  <div className="App-input-group">
                    <label htmlFor="">Upsell Description</label>
                    <div className="input-container">
                      <input
                        type="text"
                        id=""
                        name="upsell_description"
                        value={formState.upsell_description}
                        onChange={changeHandler}
                        placeholder="Upsell Description"
                        className="App-input-add"
                        required
                      />
                      <span className="input-group-icon">
                        <Edit />
                      </span>
                    </div>
                  </div>
                  <div className="App-input-group">
                    <label htmlFor="">Status</label>
                    <div className="App-input-group-select">
                      <select
                        name="status"
                        id=""
                        value={formState.status}
                        onChange={changeHandler}
                      >
                        <option value="">select status</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </select>
                    </div>
                  </div>
                  <div className="App-input-group">
                    <label htmlFor="">Where To Appear</label>
                    <div className="App-input-group-select">
                      <select name="where_to_appear" onChange={changeHandler} value={formState.where_to_appear} id="">
                        {/* <option value="">{formState.where_to_appear}</option> */}
                        {items?.map(item => (
                          <option value={item.id} key={item.id}>{item.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div
                  className="card-footer-container"
                  style={{ paddingTop: "10px" }}
                >
                  {saved && (
                    <div className="saved-toast">
                      <span>
                        <CheckCircleOutline />
                      </span>
                      <span style={{ marginLeft: "10px" }}>
                        <strong>Success</strong>. You successfully edited an
                        upsell.
                      </span>
                    </div>
                  )}
                  <div
                    className="App-input-group-buttons"
                    style={{ paddingTop: saved ? "" : "60px" }}
                  >
                    <div className="App-input-group-button form-app-button">
                      <div
                        className="App-button-add"
                        onClick={apiCall}
                      >
                        Save Details
                      </div>
                    </div>
                    <div className="App-input-group-button form-app-button">
                      <div
                        className="App-button-cancel"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer theme="colored" autoClose={1500} hideProgressBar={true} />
    </div>
  );
};

export default EditUpsell;
