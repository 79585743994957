import { useState, useEffect } from "react";
import logo from "../../../../Images/logo.webp";
import { useNavigate, useLocation } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import GroupIcon from "@mui/icons-material/Group";
import LogsIcon from "../../../../Images/sys-logs.svg";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';

function SideBar() {
  const navigate = useNavigate();
  const [active, setActive] = useState(null);
  const [sidebar, setSidebar] = useState(false);
  const location = useLocation();

  const showSidebar = () => {
    setSidebar((prevState) => !prevState);
  };

  const setActiveLink = (index) => {
    setActive(index);
  };

  useEffect(() => {
    if (location.pathname.includes("restaurants")) {
      setActive(1);
    } else if (location.pathname.includes("reports")) {
      setActive(2);
    } else if (location.pathname.includes("users")) {
      setActive(3);
    } else if (location.pathname.includes("system-logs")) {
      setActive(4);
    } else {
      setActive(0);
    }
  }, [location.pathname]);

  const handleNavigation = (path, index) => {
    setActiveLink(index);
    navigate(path);
  };
  // const setActiveLink = (id) => {

  //     // console.log(id);
  //     setActive(true)
  // }

  const logoutHandler = () => {
    localStorage.clear()
    sessionStorage.clear()
    navigate("/")
  }

  return (
    <>
      <div className="App-container-restaurants2">
        <div className="App-container-restaurants2-wrapper">
          <img
            className="App-logo-home"
            src={logo}
            alt="Logo"
            // width="177px"
            // height="96px"
          />
          <div className="App-title-black">Admin Dashboard</div>
        </div>

        <div className="Menu-list-container">
          <div
            className={`Menu-list ${active === 0 ? "active" : ""}`}
            onClick={() => handleNavigation("/dashboard/restaurants", 0)}
          >
            <div className="App-container-icon">
              <HomeIcon style={{ fontSize: "32px" }} />
            </div>
            <div className="App-container-title">Home</div>
          </div>

          <div
            className={`Menu-list ${active === 1 ? "active" : ""}`}
            onClick={() => handleNavigation("/dashboard/restaurants", 1)}
          >
            <div className="App-container-icon">
              <RestaurantIcon style={{ fontSize: "32px" }} />
            </div>
            <div className="App-container-title">Restaurants</div>
          </div>
          <div
            className={`Menu-list ${active === 2 ? "active" : ""}`}
            onClick={() => handleNavigation("/dashboard/reports-all", 2)}
          >
            <div className="App-container-icon">
              <AssignmentOutlinedIcon style={{ fontSize: "32px" }} />
            </div>
            <div className="App-container-title">Reports</div>
          </div>
          <div
            className={`Menu-list ${active === 3 ? "active" : ""}`}
            onClick={() => handleNavigation("/users", 3)}
          >
            <div className="App-container-icon">
              <GroupIcon style={{ fontSize: "32px" }} />
            </div>
            <div className="App-container-title">Users</div>
          </div>
          <div
            className={`Menu-list ${active === 4 ? "active" : ""}`}
            onClick={() => handleNavigation("/dashboard/system-logs", 4)}
          >
            <div className="App-container-icon">
              <img src={LogsIcon} alt="" />
            </div>
            <div className="App-container-title">System Logs</div>
          </div>
          <div
            className={`Menu-list`}
            onClick={logoutHandler}
          >
            <div className="App-container-icon">
              <LogoutIcon style={{ fontSize: "32px" }} />
            </div>
            <div className="App-container-title">Logout</div>
          </div>
        </div>

        {/* <div onClick={() => navigate('/dashboard')} className="App-container-title">My Profile</div> */}
      </div>

      <div className="hamburger-menu" onClick={showSidebar}>
        <MenuIcon fontSize="large" />
      </div>
      <div
        className={sidebar ? "responsive-menu active-menu" : "responsive-menu"}
      >
        <div className="close-btn" onClick={showSidebar}>
          <CloseIcon/>
        </div>
        <div className="Menu-list-container">
          <div
            className={`Menu-list ${active === 0 ? "active" : ""}`}
            onClick={() => {
              handleNavigation("/dashboard/restaurants", 0);
              showSidebar();
            }}
          >
            <div className="App-container-icon">
              <HomeIcon style={{ fontSize: "32px" }} />
            </div>
            <div className="App-container-title">Home</div>
          </div>

          <div
            className={`Menu-list ${active === 1 ? "active" : ""}`}
            onClick={() => {
              handleNavigation("/dashboard/restaurants", 1);
              showSidebar();
            }}
          >
            <div className="App-container-icon">
              <RestaurantIcon style={{ fontSize: "32px" }} />
            </div>
            <div className="App-container-title">Restaurants</div>
          </div>
          <div
            className={`Menu-list ${active === 2 ? "active" : ""}`}
            onClick={() => {
              handleNavigation("/dashboard/reports-all", 2);
              showSidebar();
            }}
          >
            <div className="App-container-icon">
              <AssignmentOutlinedIcon style={{ fontSize: "32px" }} />
            </div>
            <div className="App-container-title">Reports</div>
          </div>
          <div
            className={`Menu-list ${active === 3 ? "active" : ""}`}
            onClick={() => {
              handleNavigation("/users", 3);
              showSidebar();
            }}
          >
            <div className="App-container-icon">
              <GroupIcon style={{ fontSize: "32px" }} />
            </div>
            <div className="App-container-title">Users</div>
          </div>
          <div
            className={`Menu-list ${active === 4 ? "active" : ""}`}
            onClick={() => {
              handleNavigation("/dashboard/system-logs", 4);
              showSidebar();
            }}
          >
            <div className="App-container-icon">
              <img src={LogsIcon} alt="" />
            </div>
            <div className="App-container-title">System Logs</div>
          </div>
          <div
            className={`Menu-list`}
            onClick={() => {
              logoutHandler()
              showSidebar();
            }}
          >
            <div className="App-container-icon">
            <LogoutIcon style={{ fontSize: "32px" }} />
            </div>
            <div className="App-container-title">Logout</div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SideBar;
