import { useState, useEffect } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import MiniSidebar from "./MiniSidebar";
import {
  CheckCircleOutline,
  InsertPhotoOutlined,
  Edit,
  Category,
} from "@mui/icons-material";
import { ToastContainer } from "react-toastify";
import {
  BASE_URL,
  IMAGE_URL,
  toastSuccess,
  toastError,
  axiosInstance,
  validateCategory,
} from "../../../Utils/Config";

const EditMenuCategory = () => {
  const [saved, setSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const { company_name, id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const item = location.state.data;
  const companyString = sessionStorage.getItem("Restaurant");
  const company = JSON.parse(companyString);
  const { userId } = JSON.parse(sessionStorage.getItem("userId"));
  const [file, setFile] = useState(null);
  const [formState, setFormState] = useState({
    name: item["name"],
    photo: IMAGE_URL + item["image"],
    status: item["status"],
  });
  const [showBranches, setShowBranches] = useState(false);
  const [activeBranches, setActiveBranches] = useState(
    item["branchCategories"].map((branch) => branch.branch.id)
  );
  const [branches, setBranches] = useState([]);

  const changeHandler = async (event) => {
    const { name, value, type, checked, files } = event.target;
    if (type === "file") {
      const newFile = event.target.files[0];
      setFile(URL.createObjectURL(files[0]));
      setFormState((prevState) => ({
        ...prevState,
        photo: newFile,
      }));
    } else if (name === "branch_id") {
      const branchId = parseInt(value);
      // if (checked) {
      //   setActiveBranches((prevState) => {
      //     // Check if branchId already exists in activeBranches
      //     if (prevState.includes(branchId)) {
      //       return prevState; // If exists, return prevState as it is
      //     } else {
      //       // If not exists, add branchId to prevState
      //       return [...prevState, branchId];
      //     }
      //   });
      // } else {
      //   // Remove branchId from activeBranches
      //   setActiveBranches((prevState) =>
      //     prevState.filter((id) => id !== branchId)
      //   );
      //   deleteBranch()
      // }
      if (checked) {
        // Call addBranch if branch is checked
        try {
          await addBranch(branchId);
          setActiveBranches((prevState) => [...prevState, branchId]);
        } catch (error) {
          console.error("Error adding branch:", error);
          // Handle error, if necessary
        }
      } else {
        // Call deleteBranch if branch is unchecked
        const branchCategoryid = item["branchCategories"].find(
          (branch) => branch.branch.id === branchId
        )?.id;
        if (branchCategoryid) {
          try {
            await deleteBranch(branchCategoryid);
            setActiveBranches((prevState) =>
              prevState.filter((id) => id !== branchId)
            );
          } catch (error) {
            console.error("Error deleting branch:", error);
            // Handle error, if necessary
          }
        }
      }
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const addBranch = async (branch_id) => {
    const response = await axiosInstance.post(
      `${BASE_URL}/category/branch_category/${id}/${branch_id}`
    );
    const data = response.data;
    if (data.code !== 200) {
      alert(data.message);
    }
  };
  const deleteBranch = async (branchCategoryid) => {
    const response = await axiosInstance.delete(
      `${BASE_URL}/category/branch_category/${branchCategoryid}`
    );
    const data = response.data;
    if (data.code !== 200) {
      alert(data.message);
    }
  };

  const apiCall = async () => {
    const formData = new FormData();
    formData.append("name", formState.name);
    formData.append("status", formState.status);
    formData.append("restaurant", company["id"]);
    activeBranches.forEach((branchId) =>
      formData.append("branch_id", branchId)
    ); // Add selected branches to form data

    const fileInput = document.querySelector('input[type="file"]');
    const file = fileInput?.files[0];
    if (file) {
      formData.append("image", file);
    }

    try {
      const response = await axiosInstance.put(
        `${BASE_URL}/category/${id}/${userId}`,
        formData
      );
      const data = response.data;
      if (data.code === 200) {
        toastSuccess(data.message);
        setFormState({
          name: "",
          photo: "",
          status: "",
        });
        setTimeout(() => {
          navigate(-1);
        }, 1700);
      } else {
        toastError(data.message);
      }
    } catch (error) {
      toastError(error.code);
      setLoading(false);
    }
  };

  const submitHandler = () => {
    const validate = validateCategory(
      formState.name,
      formState.status,
      formState.photo
    );
    if (validate === null) {
      apiCall();
    } else {
      toastError(validate);
    }
  };

  const showBranchHandler = () => {
    setShowBranches((prevState) => !prevState);
  };

  const fetchBranches = async () => {
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}/branch/restaurant/${company["id"]}`
      );
      const data = response.data;
      setLoading(false);
      if (data.code === 200) {
        setBranches(data.branches);
      } else {
        toastError(data.message);
      }
    } catch (error) {
      toastError(error.code);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBranches();
  }, []);

  return (
    <div>
      <div className="breadcrump">
        <Link to="/admin/restaurants" className="breadcrumb-not-active">
          Home
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link to="/admin/restaurants" className="breadcrumb-active">
          All Restaurants
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link
          onClick={(event) => event.preventDefault()}
          className="breadcrumb-active"
        >
          {company_name}
        </Link>
      </div>
      <div className="App-container-header-table-wrapper">
        <div className="App-container-header">
          <p>{company_name}</p> - <p>Edit menu category</p>
        </div>
        <div className="App-container-table">
          <div className="restaurant-table-wrapper">
            <div style={{ paddingTop: "33px" }}>
              <MiniSidebar />
            </div>
            <div className="table-container">
              <div className="App-table restaurant-app-table">
                <div className="App-container-row-body-full">
                  <div className="App-input-group">
                    <label htmlFor="">Category Name</label>
                    <div className="input-container">
                      <input
                        type="text"
                        id=""
                        name="name"
                        placeholder="Category Name"
                        value={formState.name}
                        onChange={changeHandler}
                        className="App-input-add"
                        required
                      />
                      <span className="input-group-icon">
                        <Edit />
                      </span>
                    </div>
                  </div>
                  <div className="App-input-group">
                    <label htmlFor="">Status</label>
                    <div className="App-input-group-select">
                      <select
                        name="status"
                        id=""
                        value={formState.status}
                        onChange={changeHandler}
                      >
                        <option value="">select status</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </select>
                    </div>
                  </div>

                  <div className="App-input-group-multiselect">
                    <label htmlFor="">Active Branches</label>
                    <button
                      onClick={showBranchHandler}
                      className="category-btn"
                    >
                      {item["branchCategories"].length === 0 && (
                        <p>No selected branches</p>
                      )}
                      {item["branchCategories"]?.map((branch, index) => (
                        <span key={branch.branch.branch_name}>
                          {branch.branch.branch_name}
                          {index < item["branchCategories"].length - 1 && ","}
                        </span>
                      ))}
                    </button>
                    {showBranches && (
                      <div className="show-panel">
                        {branches ? (
                          branches.map((branch) => (
                            <span key={branch.id}>
                              <input
                                type="checkbox"
                                name="branch_id"
                                id={`branch_${branch.id}`}
                                value={branch.id}
                                onChange={changeHandler}
                                checked={activeBranches.includes(branch.id)} // Check if branch ID is in activeBranches
                              />
                              <label htmlFor={`branch_${branch.id}`}>
                                {branch.branch_name}
                              </label>
                            </span>
                          ))
                        ) : (
                          <p>There are no branches</p>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="App-input-group">
                    <label htmlFor="">Upload Picture</label>
                    <div
                      className="image-container"
                      onClick={() =>
                        document.querySelector(".input-field").click()
                      }
                    >
                      <input
                        type="file"
                        name=""
                        id=""
                        className="input-field"
                        accept="image/*"
                        hidden
                        onChange={changeHandler}
                      />
                      {formState.photo ? (
                        <img
                          src={formState.photo}
                          alt={file}
                          width={80}
                          height={80}
                        />
                      ) : (
                        <InsertPhotoOutlined
                          style={{
                            fontSize: "80px",
                            color: "rgba(0, 0, 0, 0.5)",
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="card-footer-container"
                  style={{ paddingTop: "10px" }}
                >
                  {saved && (
                    <div className="saved-toast">
                      <span>
                        <CheckCircleOutline />
                      </span>
                      <span style={{ marginLeft: "10px" }}>
                        <strong>Success</strong>. You successfully added a new
                        menu category.
                      </span>
                    </div>
                  )}
                  <div className="App-input-group-buttons">
                    <div className="App-input-group-button form-app-button">
                      <div className="App-button-add" onClick={submitHandler}>
                        Save Details
                      </div>
                    </div>
                    <div className="App-input-group-button form-app-button">
                      <div
                        className="App-button-cancel"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer theme="colored" autoClose={1500} hideProgressBar={true} />
    </div>
  );
};

export default EditMenuCategory;
