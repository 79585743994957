import { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import MiniSidebar from "./MiniSidebar";
import { CheckCircleOutline } from "@mui/icons-material";
import {
  BASE_URL,
  toastError,
  toastSuccess,
  axiosInstance,
} from "../../../Utils/Config";
import { ToastContainer } from "react-toastify";

const AddPromotion = () => {
  const [saved, setSaved] = useState(false);
  const [items, setItems] = useState([]);
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showCategories, setShowCategories] = useState(false);
  const { company_name } = useParams();
  const navigate = useNavigate();
  const itemString = sessionStorage.getItem("Restaurant");
  const item = JSON.parse(itemString);
  const [type, setType] = useState("percentage");
  const [formState, setFormState] = useState({
    promotion_name: "",
    discount_value: "",
    branch: "",
    start_date: "",
    end_date: "",
    from_time: "",
    to_time: "",
    item: "",
    days: [],
  });
  const {userId} = JSON.parse(sessionStorage.getItem("userId"))

  useEffect(() => {
    fetchItems();
    fetchBranches();
  }, []);

  const selectTypeHandler = (e) => {
    const { value } = e.target;
    if (value === "amount") {
      setType("amount");
    } else {
      setType("percentage");
    }
  };

  const changeHandler = (event) => {
    const { name, value, checked } = event.target;

    if (name === "days") {
      setFormState((prevState) => ({
        ...prevState,
        days: checked
          ? [...prevState.days, value]
          : prevState.days.filter((day) => day !== value),
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const fetchItems = async () => {
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}/item/restaurant/${item["id"]}`
      );
      const data = response.data;
      setLoading(false);
      if (data.code === 200) {
        setItems(data.items);
      } else {
        toastError(data.message);
      }
    } catch (error) {
      toastError(error.code);
      setLoading(false);
    }
  };

  const fetchBranches = async () => {
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}/branch/restaurant/${item["id"]}`
      );
      const data = response.data;
      setLoading(false);
      if (data.code === 200) {
        setBranches(data.branches);
      } else {
        toastError(data.message);
      }
    } catch (error) {
      toastError(error.code);
      setLoading(false);
    }
  };

  const showCategoryHandler = () => {
    setShowCategories((prevState) => !prevState);
  };

  function uncheckAllCheckboxes() {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');

    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
  }

  const apiCall = async () => {
    const payload = {
      name: formState.promotion_name,
      discountType: type,
      discountValue: formState.discount_value,
      branch: formState.branch,
      start: formState.start_date + " " + formState.from_time,
      end: formState.end_date + " " + formState.to_time,
      item: formState.item,
      days: formState.days,
      restaurant: String(item["id"]),
    };
    try {
      const response = await axiosInstance.post(
        `${BASE_URL}/promotion/${userId}`,
        payload
      );
      const data = response.data;
      if (data.code === 200) {
        toastSuccess(data.message);
        setFormState({
          promotion_name: "",
          discount_value: "",
          branch: "",
          start_date: "",
          end_date: "",
          from_time: "",
          to_time: "",
          item: "",
          days: [],
        });
        uncheckAllCheckboxes();
        setTimeout(() => {
          navigate(-1);
        }, 1700);
      }
    } catch (error) {
      console.log("Error: ", error);
      toastError(error.code);
    }
  };

  return (
    <div>
      <div className="breadcrump">
        <Link to="/admin/restaurants" className="breadcrumb-not-active">
          Home
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link to="/admin/restaurants" className="breadcrumb-active">
          All Restaurants
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link
          onClick={(event) => event.preventDefault()}
          className="breadcrumb-active"
        >
          {company_name}
        </Link>
      </div>
      <div className="App-container-header-table-wrapper">
        <div className="App-container-header">
          <p>{company_name}</p> - <p>Add Promotion</p>
        </div>
        <div className="App-container-table">
          <div className="restaurant-table-wrapper">
            <div style={{ paddingTop: "33px" }}>
              <MiniSidebar />
            </div>
            <div className="table-container">
              <div className="App-table restaurant-app-table">
                <div className="App-container-row-body">
                  <div className="App-input-group">
                    <label htmlFor="">Promotion Name</label>
                    <input
                      type="text"
                      id=""
                      name="promotion_name"
                      placeholder="Promotion Name"
                      value={formState.promotion_name}
                      onChange={changeHandler}
                      className="App-input-add"
                      required
                    />
                  </div>
                  <div className="App-input-group">
                    <label htmlFor="">Change Discount Type</label>
                    <div className="App-input-group-select">
                      <select name="type" id="" onChange={selectTypeHandler}>
                        <option value="">Select discount type</option>
                        <option value="percentage">Percentage</option>
                        <option value="amount">Amount</option>
                      </select>
                    </div>
                  </div>
                  {type === "percentage" ? (
                    <div className="App-input-group">
                      <label htmlFor="">Discount (%)</label>
                      <input
                        type="text"
                        id=""
                        name="discount_value"
                        value={formState.discount_value}
                        onChange={changeHandler}
                        placeholder="Discount %"
                        className="App-input-add"
                        required
                      />
                    </div>
                  ) : (
                    <div className="App-input-group">
                      <label htmlFor="">Discount Amount(Ksh)</label>
                      <input
                        type="number"
                        id=""
                        name="discount_value"
                        value={formState.discount_value}
                        onChange={changeHandler}
                        placeholder="Discount Amount"
                        className="App-input-add"
                        required
                      />
                    </div>
                  )}
                  {/* <div className="App-input-group-multiselect">
                    <label htmlFor="">Branch(es)</label>
                    <button onClick={showCategoryHandler} className="show-btn">
                      Select Branch - Multiple/All
                    </button>
                    {showCategories && (
                      <div className="show-panel">
                        {branches ? (
                          branches.map((branch) => (
                            <span key={branch.id}>
                              <input
                                type="checkbox"
                                name="branch_id"
                                id=""
                                value={branch.id}
                                onChange={changeHandler}
                              />
                              <label htmlFor="">{branch.branch_name}</label>
                            </span>
                          ))
                        ) : (
                          <p>There is no active branches</p>
                        )}
                      </div>
                    )}
                  </div> */}
                  <div className="App-input-group">
                    <label htmlFor="">Select branch</label>
                    <div className="App-input-group-select">
                      <select name="branch" id="" onChange={changeHandler}>
                        <option value="">Select Branch</option>
                        {branches?.map((branch) => (
                          <option value={branch.id} key={branch.id}>
                            {branch.branch_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="App-input-group">
                    <label htmlFor="">Start Date</label>
                    <input
                      type="date"
                      id=""
                      name="start_date"
                      placeholder="Start Date"
                      value={formState.start_date}
                      onChange={changeHandler}
                      className="App-input-add"
                      required
                    />
                  </div>
                  <div className="App-input-group">
                    <label htmlFor="">End Date</label>
                    <input
                      type="date"
                      id=""
                      name="end_date"
                      placeholder="End Date"
                      value={formState.end_date}
                      onChange={changeHandler}
                      className="App-input-add"
                      required
                    />
                  </div>
                  <div className="App-input-group">
                    <label htmlFor="">From (Time)</label>
                    <input
                      type="time"
                      id=""
                      name="from_time"
                      placeholder="Contact Person"
                      value={formState.from_time}
                      onChange={changeHandler}
                      className="App-input-add"
                      required
                    />
                  </div>
                  <div className="App-input-group">
                    <label htmlFor="">To (Time)</label>
                    <input
                      type="time"
                      id=""
                      name="to_time"
                      placeholder="Contact Person"
                      value={formState.to_time}
                      onChange={changeHandler}
                      className="App-input-add"
                      required
                    />
                  </div>
                  <div className="App-input-group">
                    <label htmlFor="">Item Name</label>
                    <div className="App-input-group-select">
                      <select name="item" id="item" onChange={changeHandler}>
                        <option value="">Select Item</option>
                        {items.length > 0 &&
                          items.map((item) => (
                            <option value={item.id} key={item.id}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div
                    className="hidden-input"
                    style={{ opacity: 0, pointerEvents: "none" }}
                  >
                    <div className="App-input-group">
                      <label htmlFor=""></label>
                      <input
                        type=""
                        id=""
                        name=""
                        placeholder=""
                        className="App-input-add"
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="" style={{ fontWeight: "bolder" }}>
                      Day of the Week
                    </label>
                    <div className="days-of-week">
                      <div className="checkbox-wrapper">
                        <input
                          type="checkbox"
                          name="days"
                          id=""
                          value="1"
                          onChange={changeHandler}
                        />
                        <label htmlFor="" className="checkbox-label">
                          Mon
                        </label>
                      </div>
                      <div className="checkbox-wrapper">
                        <input
                          type="checkbox"
                          name="days"
                          id=""
                          value="2"
                          onChange={changeHandler}
                        />
                        <label htmlFor="" className="checkbox-label">
                          Tue
                        </label>
                      </div>
                      <div className="checkbox-wrapper">
                        <input
                          type="checkbox"
                          name="days"
                          id=""
                          value="3"
                          onChange={changeHandler}
                        />
                        <label htmlFor="" className="checkbox-label">
                          Wed
                        </label>
                      </div>
                      <div className="checkbox-wrapper">
                        <input
                          type="checkbox"
                          name="days"
                          id=""
                          value="4"
                          onChange={changeHandler}
                        />
                        <label htmlFor="" className="checkbox-label">
                          Thur
                        </label>
                      </div>
                      <div className="checkbox-wrapper">
                        <input
                          type="checkbox"
                          name="days"
                          id=""
                          value="5"
                          onChange={changeHandler}
                        />
                        <label htmlFor="" className="checkbox-label">
                          Fri
                        </label>
                      </div>
                      <div className="checkbox-wrapper">
                        <input
                          type="checkbox"
                          name="days"
                          id=""
                          value="6"
                          onChange={changeHandler}
                        />
                        <label htmlFor="" className="checkbox-label">
                          Sat
                        </label>
                      </div>
                      <div className="checkbox-wrapper">
                        <input
                          type="checkbox"
                          name="days"
                          id=""
                          value="7"
                          onChange={changeHandler}
                        />
                        <label htmlFor="" className="checkbox-label">
                          Sun
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer-container">
                  {saved && (
                    <div className="saved-toast">
                      <span>
                        <CheckCircleOutline />
                      </span>
                      <span style={{ marginLeft: "10px" }}>
                        <strong>Success</strong>. You successfully added a new
                        branch.
                      </span>
                    </div>
                  )}
                  <div className="App-input-group-buttons">
                    <div className="App-input-group-button form-app-button">
                      <div className="App-button-add" onClick={apiCall}>
                        Submit
                      </div>
                    </div>
                    <div className="App-input-group-button form-app-button">
                      <div
                        className="App-button-cancel"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer theme="colored" autoClose={1500} hideProgressBar={true} />
    </div>
  );
};

export default AddPromotion;
