import { useState, useEffect } from "react";
import Empty from "../../../Utils/Empty";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import MiniSidebar from "./MiniSidebar";
import { AddCircleOutlineRounded, Search } from "@mui/icons-material";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
	BASE_URL,
	toastError,
	axiosInstance,
	IMAGE_URL,
} from "../../../Utils/Config";
import { ToastContainer } from "react-toastify";
import Loader from "../../../Utils/Loader";

const MenuCategory = () => {
	const navigate = useNavigate();
	const [showEmpty, setShowEmpty] = useState(false);
	const [data, setData] = useState([]);
	const { company_name } = useParams();
	const [loading, setLoading] = useState(true);
	const itemString = sessionStorage.getItem("Restaurant");
	const item = JSON.parse(itemString);
	const [query, setQuery] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(10);
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

	const keys = ["name", "status"];

	const editMenuCategory = (data) => {
		navigate(
			`/dashboard/restaurants/${company_name}/edit-menu-category/${data.id}`,
			{ state: { data } }
		);
	};

	const viewMenuCategory = (data) => {
		navigate(
			`/dashboard/restaurants/${company_name}/view-menu-category/${data.id}`,
			{ state: { data } }
		);
	};

	useEffect(() => {
		fetchMenuCategory();
	}, []);

	const fetchMenuCategory = async () => {
		try {
			const response = await axiosInstance.get(
				`${BASE_URL}/category/restaurant/${item["id"]}`
			);
			const data = response.data;
			setLoading(false);
			if (data.code === 200) {
				setData(data.categories);
			} else {
				toastError(data.message);
			}
		} catch (error) {
			toastError(error.code);
			setLoading(false);
		}
	};

	const formatToEAT = (timestamp) => {
		const date = new Date(timestamp);
		const options = { timeZone: "Africa/Nairobi" };
		return date.toLocaleString("en-US", options);
	};

	return (
		<div>
			<div className="breadcrump">
				<Link to="/dashboard/restaurants" className="breadcrumb-not-active">
					Home
				</Link>
				<span className="breadcrumb-arrow">&gt;</span>
				<Link to="/dashboard/restaurants" className="breadcrumb-active">
					All Restaurants
				</Link>
				<span className="breadcrumb-arrow">&gt;</span>
				<Link
					onClick={(event) => event.preventDefault()}
					className="breadcrumb-active">
					{company_name}
				</Link>
			</div>
			<div className="App-container-header-table-wrapper">
				<div className="App-container-header">
					<p>{company_name}</p> - <p>Menu Categories</p>
				</div>
				<div className="App-container-table">
					<div className="App-container-table-top-bar">
						<div
							className="Search-bar"
							style={{ backgroundColor: "#83B3020D" }}>
							<Search />
							<input
								type="search"
								name=""
								id=""
								placeholder="Search..."
								style={{ backgroundColor: "inherit" }}
								onChange={(e) => setQuery(e.target.value)}
							/>
						</div>
						<div
							className="App-right"
							onClick={() =>
								navigate(
									`/dashboard/restaurants/${company_name}/add-menu-category`
								)
							}>
							<AddCircleOutlineRounded
								sx={{ color: "white", marginRight: "10px" }}
							/>
							<div className="App-title-right">Add Menu Category</div>
						</div>
					</div>
					<div className="restaurant-table-wrapper">
						<MiniSidebar />
						<div className="table-container">
							<div className="App-table restaurants-app-table">
								{loading === true ? (
									<div className="App-loader">
										<Loader />
									</div>
								) : (
									<></>
								)}
								{showEmpty !== true ? (
									<>
										<Table stripped="true" bordered responsive size="xl">
											<thead id="Table-heading-wrapper">
												<tr>
													<th id="t-heading">Category Name</th>
													<th id="t-heading">Branches</th>
													<th id="t-heading">Last Updated</th>
													<th id="t-heading">Photo</th>
													<th id="t-heading">Status</th>
													<th id="t-heading" style={{ width: "14%" }}>
														Action
													</th>
												</tr>
											</thead>
											<tbody>
												{currentItems
													?.filter((item) =>
														keys.some((key) =>
															item[key].toLowerCase().includes(query)
														)
													)
													.map((item) => {
														const formattedTime = formatToEAT(item.updatedAt);
														return (
															<tr key={item.id}>
																<td id="t-body">
																	<p>{item.name}</p>
																</td>
																<td id="t-body">
																	<p>
																		{item.branchCategories?.map(
																			(branchCategory, index) => (
																				<span>
																					{branchCategory.branch.branch_name}
																					{index <
																						item.branchCategories.length - 1 &&
																						","}
																				</span>
																			)
																		)}
																	</p>
																	{/* </p> */}
																</td>
																<td id="t-body">
																	<p>
																		{item.updatedAt !== null
																			? formattedTime
																			: "n/a"}
																	</p>
																</td>
																<td id="t-body-image">
																	<div id="p-image">
																		<img
																			src={`${IMAGE_URL}${item.image}`}
																			alt="logo"
																		/>
																	</div>
																</td>
																<td id="t-body-status">
																	{item.status === "1" ? (
																		<div className="company-status">
																			<p id="status" className="active">
																				Active
																			</p>
																		</div>
																	) : (
																		<div className="company-status">
																			<p id="status" className="inactive">
																				Inactive
																			</p>
																		</div>
																	)}
																</td>
																<td className="App-table-actions">
																	<div
																		className="App-table-action-button"
																		//   onClick={() => editRestaurant(item.id)}
																		onClick={() => editMenuCategory(item)}
																		style={{
																			backgroundColor: "#464E47",
																		}}>
																		Edit
																	</div>
																	<div
																		className="App-table-action-button"
																		//   onClick={() => viewRestaurant(item)}
																		onClick={() => viewMenuCategory(item)}
																		style={{
																			background: "#337CA0",
																		}}>
																		View
																	</div>
																</td>
															</tr>
														);
													})}
											</tbody>
										</Table>
										<Pagination className="pagination">
											<Pagination.Prev
												onClick={() =>
													setCurrentPage((prev) => Math.max(prev - 1, 1))
												}
											/>
											{Array.from({
												length: Math.ceil(data?.length / itemsPerPage),
											}).map((page, index) => (
												<Pagination.Item
													key={index + 1}
													active={index + 1 === currentPage}
													onClick={() => setCurrentPage(index + 1)}>
													{index + 1}
												</Pagination.Item>
											))}
											<Pagination.Next
												onClick={() =>
													setCurrentPage((prev) =>
														Math.min(
															prev + 1,
															Math.ceil(data?.length / itemsPerPage)
														)
													)
												}
											/>
										</Pagination>
									</>
								) : (
									<div className="App-empty">
										<Empty />
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<ToastContainer theme="colored" autoClose={1500} hideProgressBar={true} />
		</div>
	);
};

export default MenuCategory;
