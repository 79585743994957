import React, { useState, useEffect } from "react";
import Empty from "../../../Utils/Empty";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import MiniSidebar from "./MiniSidebar";
import { AddCircleOutlineRounded, Search } from "@mui/icons-material";
import { useParams, Link, useNavigate } from "react-router-dom";
import { BASE_URL, axiosInstance, toastError } from "../../../Utils/Config";

const CreateMenu = () => {
	const navigate = useNavigate();
	const [showEmpty, setShowEmpty] = useState(false);
	const [data, setData] = useState([]);
	const { company_name } = useParams();
	const itemString = sessionStorage.getItem("Restaurant");
	const item = JSON.parse(itemString);
	const [query, setQuery] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(10);
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

	const keys = ["item.name"];

	const getValueByKey = (object, key) => {
		const keys = key.split(".");
		let value = object;
		keys.forEach((k) => {
			if (value && value.hasOwnProperty(k)) {
				value = value[k];
			} else {
				value = undefined;
			}
		});
		return value;
	};

	useEffect(() => {
		fetchMenus();
	}, []);

	const fetchMenus = async () => {
		const response = await axiosInstance.get(
			`${BASE_URL}/menu/restaurant/${item["id"]}`
		);
		const data = response.data;
		if (data.code === 200) {
			setData(data.menu);
		} else {
			toastError(data.message);
		}
	};

	const editMenu = (data) => {
		navigate(`/dashboard/restaurants/${company_name}/edit-menu/${data.id}`, {
			state: { data },
		});
	};

	return (
		<div>
			<div className="breadcrump">
				<Link to="/dashboard/restaurants" className="breadcrumb-not-active">
					Home
				</Link>
				<span className="breadcrumb-arrow">&gt;</span>
				<Link to="/dashboard/restaurants" className="breadcrumb-active">
					All Restaurants
				</Link>
				<span className="breadcrumb-arrow">&gt;</span>
				<Link
					onClick={(event) => event.preventDefault()}
					className="breadcrumb-active">
					{company_name}
				</Link>
			</div>
			<div className="App-container-header-table-wrapper">
				<div className="App-container-header">
					<p>{company_name}</p> - <p>Create Menu</p>
				</div>
				<div className="App-container-table">
					<div className="App-container-table-top-bar">
						<div
							className="Search-bar"
							style={{ backgroundColor: "#83B3020D" }}>
							<Search />
							<input
								type="search"
								name=""
								id=""
								placeholder="Search..."
								style={{ backgroundColor: "inherit" }}
								onChange={(e) => setQuery(e.target.value)}
							/>
						</div>
						<div
							className="App-right"
							//   onClick={() => navigate("/dashboard/add-restaurant")}
							onClick={() =>
								navigate(`/dashboard/restaurants/${company_name}/add-menu`)
							}>
							<AddCircleOutlineRounded
								sx={{ color: "white", marginRight: "10px" }}
							/>
							<div className="App-title-right">Create Menu</div>
						</div>
					</div>
					<div className="restaurant-table-wrapper">
						<MiniSidebar />
						<div className="table-container">
							<div
								className="App-table restaurants-app-table"
								// style={{ width: "85%", margin: "auto" }}
							>
								{data.length > 0 ? (
									<>
										<Table stripped="true" bordered responsive size="xl">
											<thead id="Table-heading-wrapper">
												<tr>
													{/* <th>#</th> */}
													<th id="t-heading">Item Name</th>
													{/* <th id="t-heading">Category Name</th> */}
													<th id="t-heading">Modifiers</th>
													<th id="t-heading">Action</th>
												</tr>
											</thead>
											<tbody>
												{currentItems
													?.filter((menu) =>
														keys.some((key) =>
															getValueByKey(menu, key)
																.toLowerCase()
																.includes(query)
														)
													)
													.map((menu) => (
														<tr key={menu.id}>
															<td id="t-body">
																<p>{menu.item.name}</p>
															</td>
															{/* <td id="t-body">
                          </td> */}
															<td id="t-body">
																<div
																	style={{
																		height: "100%",
																		display: "flex",
																		alignItems: "center",
																	}}>
																	{menu.menuItems[0]?.menuItemOptions.map(
																		(option, index) => (
																			<p key={option.id}>
																				{option.modifiers.name}
																				{index <
																					menu.menuItems[0].menuItemOptions
																						.length -
																						1 && ", "}
																			</p>
																		)
																	)}
																</div>
															</td>
															<td className="App-table-actions">
																<div
																	className="App-table-action-button"
																	//   onClick={() => viewRestaurant(item)}
																	onClick={() => editMenu(menu)}
																	style={{
																		background: "#337CA0",
																	}}>
																	Edit
																</div>
																<div
																	className="App-table-action-button"
																	//   onClick={() => editRestaurant(item.id)}
																	onClick={() =>
																		navigate(
																			`/dashboard/restaurants/${company_name}/view-menu`
																		)
																	}
																	style={{
																		backgroundColor: "#464E47",
																	}}>
																	View
																</div>
															</td>
														</tr>
													))}
											</tbody>
										</Table>
										<Pagination className="pagination">
											<Pagination.Prev
												onClick={() =>
													setCurrentPage((prev) => Math.max(prev - 1, 1))
												}
											/>
											{Array.from({
												length: Math.ceil(data?.length / itemsPerPage),
											}).map((page, index) => (
												<Pagination.Item
													key={index + 1}
													active={index + 1 === currentPage}
													onClick={() => setCurrentPage(index + 1)}>
													{index + 1}
												</Pagination.Item>
											))}
											<Pagination.Next
												onClick={() =>
													setCurrentPage((prev) =>
														Math.min(
															prev + 1,
															Math.ceil(data?.length / itemsPerPage)
														)
													)
												}
											/>
										</Pagination>
									</>
								) : (
									<div className="App-empty">
										<Empty />
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CreateMenu;
