import React, { useState, useRef, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import Empty from "../../../Utils/Empty";
import MiniSidebar from "./MiniSidebar";
import { AddCircleOutlineRounded, Search } from "@mui/icons-material";
import { useParams, Link, useNavigate } from "react-router-dom";
import QRCodeDetails from "./Components/QRCodeDetails";
import jsPDF from "jspdf";
// import html2pdf from 'html2pdf.js';
import { useReactToPrint } from "react-to-print";
// import "./qrCodeDetails.css";
import "./Components/qrCodeDetails.css";
import {
	QrCodeScanner,
	StayPrimaryPortrait,
	Payment,
	RestaurantMenu,
} from "@mui/icons-material";
import QRCode from "qrcode";
import {
	BASE_URL,
	IMAGE_URL,
	WEBSITE_URL,
	axiosInstance,
	toastError,
} from "../../../Utils/Config";

const QrCodeDownload = ({ details, generateQRCode, qrCode, logo }) => {
	useEffect(() => {
		generateQRCode(details);
	}, []);

	return (
		<div className="qr-details-wrapper">
			<div className="top-section">
				<h3>Table: {details.tableNumber}</h3>
				<div className="operation-wrapper">
					<div
						style={{
							display: "flex",
							alignItems: "center",
							marginBottom: "8px",
						}}>
						<RestaurantMenu fontSize="large" />
					</div>
					<h3>Menu</h3>
				</div>
				<div className="operations-container">
					<div className="operation-wrapper">
						<div
							style={{
								display: "flex",
								alignItems: "center",
								marginBottom: "8px",
							}}>
							<QrCodeScanner />
						</div>
						<h3>Scan</h3>
					</div>
					<div className="operation-wrapper">
						<div
							style={{
								display: "flex",
								alignItems: "center",
								marginBottom: "8px",
							}}>
							<StayPrimaryPortrait />
						</div>
						<h3>Order</h3>
					</div>
					<div className="operation-wrapper">
						<div
							style={{
								display: "flex",
								alignItems: "center",
								marginBottom: "8px",
							}}>
							<Payment />
						</div>
						<h3>Pay</h3>
					</div>
				</div>
			</div>
			<div className="bottom-section">
				<div className="bottom-section-wrapper">
					<div className="qr-code">{qrCode && <img src={qrCode} alt="" />}</div>
					<div className="logo">
						<img src={logo} alt="restaurant image" />
					</div>
				</div>
			</div>
		</div>
	);
};

const QRCodes = () => {
	const [showEmpty, setShowEmpty] = useState(false);
	const { company_name } = useParams();
	const navigate = useNavigate();
	const componentPDF = useRef();
	const [print, setPrint] = useState(false);
	const [qrCode, setQrCode] = useState("");
	const companyString = sessionStorage.getItem("Restaurant");
	const company = JSON.parse(companyString);
	const [data, setData] = useState([]);
	const logo = IMAGE_URL + company["logo"];
	const [query, setQuery] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(10);
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

	const keys = ["tableNumber", "branch.branch_name"];

	const getValueByKey = (object, key) => {
		const keys = key.split(".");
		let value = object;
		keys.forEach((k) => {
			if (value && value.hasOwnProperty(k)) {
				value = value[k];
			} else {
				value = undefined;
			}
		});
		return value;
	};

	const formatToEAT = (timestamp) => {
		const date = new Date(timestamp);
		const options = { timeZone: "Africa/Nairobi" };
		return date.toLocaleString("en-US", options);
	};

	useEffect(() => {
		fetchCodes();
	}, []);

	const fetchCodes = async () => {
		try {
			const response = await axiosInstance.get(
				`${BASE_URL}/qr/restaurant/${company["id"]}`
			);
			const data = response.data;
			if (data.code === 200) {
				setData(data.qr);
			} else {
				toastError(data.message);
			}
		} catch (error) {
			toastError(error.code);
		}
	};

	const editQrCode = (qrcode) => {
		navigate(`/dashboard/restaurants/${company_name}/edit-qr-code/${1}`, {
			state: { qrcode },
		});
	};

	const generateQRCode = (details) => {
		const { branch } = details;
		const link = `${WEBSITE_URL}?restaurant_id=${company["id"]}&branch_id=${branch.id}`;
		QRCode.toDataURL(
			link,
			{
				width: 300,
				margin: 1,
			},
			(err, url) => {
				if (err) return console.error(err);
				setQrCode(url);
			}
		);
	};

	const generatePDF = useReactToPrint({
		content: () => componentPDF.current,
		documentTitle: "QR code",
	});

	return (
		<div>
			<div className="breadcrump">
				<Link to="/dashboard/restaurants" className="breadcrumb-not-active">
					Home
				</Link>
				<span className="breadcrumb-arrow">&gt;</span>
				<Link to="/dashboard/restaurants" className="breadcrumb-active">
					All Restaurants
				</Link>
				<span className="breadcrumb-arrow">&gt;</span>
				<Link
					onClick={(event) => event.preventDefault()}
					className="breadcrumb-active">
					{company_name}
				</Link>
			</div>
			<div className="App-container-header-table-wrapper">
				<div className="App-container-header">
					<p>{company_name}</p> - <p>QR Code</p>
				</div>
				<div className="App-container-table">
					<div className="App-container-table-top-bar">
						<div
							className="Search-bar"
							style={{ backgroundColor: "#83B3020D" }}>
							<Search />
							<input
								type="search"
								name=""
								id=""
								placeholder="Search..."
								style={{ backgroundColor: "inherit" }}
								onChange={(e) => setQuery(e.target.value)}
							/>
						</div>
						<div
							className="App-right"
							//   onClick={() => navigate("/dashboard/add-restaurant")}
							onClick={() =>
								navigate(`/dashboard/restaurants/${company_name}/add-qr-code`)
							}>
							<AddCircleOutlineRounded
								sx={{ color: "white", marginRight: "10px" }}
							/>
							<div className="App-title-right">Add QR Code</div>
						</div>
					</div>
					<div className="restaurant-table-wrapper">
						<MiniSidebar />
						<div className="table-container">
							<div className="App-table restaurants-app-table">
								{showEmpty !== true ? (
									<>
										<Table stripped="true" bordered responsive size="xl">
											<thead id="Table-heading-wrapper">
												<tr>
													<th id="t-heading">Branch Name</th>
													<th id="t-heading">QR Code</th>
													<th id="t-heading">Last Updated</th>
													<th id="t-heading">Table #</th>
													{/* <th id="t-heading">Status</th> */}
													<th id="t-heading">Action</th>
												</tr>
											</thead>
											<tbody>
												{currentItems
													?.filter((code) =>
														keys.some((key) =>
															getValueByKey(code, key)
																.toLowerCase()
																.includes(query)
														)
													)
													.map((code, index) => {
														const formattedTime = formatToEAT(code.createdAt);
														return (
															<React.Fragment key={index}>
																<tr>
																	<td id="t-body">
																		<p>{code.branch.branch_name}</p>
																	</td>
																	<td id="t-body">
																		{/* <p>{code.link}</p> */}
																		<p>{`${WEBSITE_URL}/${company["id"]}/${code.branch.id}/${code.tableNumber}`}</p>
																	</td>
																	<td id="t-body">
																		<p>{formattedTime}</p>
																	</td>
																	<td id="t-body-number">
																		<p>{code.tableNumber}</p>
																	</td>
																	<td className="App-table-actions">
																		<div
																			className="App-table-action-button"
																			style={{
																				background: "#337CA0",
																			}}
																			onClick={() => {
																				setPrint(true);
																				generatePDF();
																			}}>
																			Download
																		</div>
																		<div
																			className="App-table-action-button"
																			onClick={() => editQrCode(code)}
																			style={{
																				backgroundColor: "#464E47",
																			}}>
																			Edit
																		</div>
																	</td>
																</tr>
																<div style={{ display: "none" }}>
																	<div ref={componentPDF}>
																		<QrCodeDownload
																			details={code}
																			generateQRCode={generateQRCode}
																			qrCode={qrCode}
																			logo={logo}
																		/>
																	</div>
																</div>
															</React.Fragment>
														);
													})}
											</tbody>
										</Table>
										<Pagination className="pagination">
											<Pagination.Prev
												onClick={() =>
													setCurrentPage((prev) => Math.max(prev - 1, 1))
												}
											/>
											{Array.from({
												length: Math.ceil(data?.length / itemsPerPage),
											}).map((page, index) => (
												<Pagination.Item
													key={index + 1}
													active={index + 1 === currentPage}
													onClick={() => setCurrentPage(index + 1)}>
													{index + 1}
												</Pagination.Item>
											))}
											<Pagination.Next
												onClick={() =>
													setCurrentPage((prev) =>
														Math.min(
															prev + 1,
															Math.ceil(data?.length / itemsPerPage)
														)
													)
												}
											/>
										</Pagination>
									</>
								) : (
									<div className="App-empty">
										<Empty />
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <QrCodeDownload details={newDetails} /> */}
			{/* <QRCodeDetails tableNo={table_no} menu={menu} operation1={operation1} operation2={operation2} operation3={operation3} logo={logo} /> */}
		</div>
	);
};

export default QRCodes;
