import Logo from "../../../../Images/logo.webp";

const ChartFooter = () => {
  return (
    <div className="footer-container">
      Powered by:
      <div className="footer-logo">
        <img src={Logo} alt="orderease logo" className="footer-logo-image" />
      </div>
    </div>
  );
};

export default ChartFooter;
