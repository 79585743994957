import { useNavigate } from 'react-router-dom';
import React from 'react';

function NotFound() {
    const navigate = useNavigate();
  return (
    <div className="App-body-restaurants">
        <div className='App-auto'>
            <div className='App-title-black-not-found'><h1>404 Not Found</h1></div>
            <div className='App-title-black-not-found'><p>The page you are looking for does not exist.</p></div>
            <div className='App-button-NotFound' onClick={() => navigate(-1)}>Go Back</div>
        </div>
    </div>
  );
}

export default NotFound;