import { Add, CheckCircleOutline, Edit } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import logo from "../../../Images/logo.webp";
import { ToastContainer } from "react-toastify";
import Loader from "../../../Utils/Loader";
import {
  useNavigate,
  useSearchParams,
  Link,
  useLocation,
} from "react-router-dom";
import {
  BASE_URL,
  toastError,
  toastSuccess,
  validateData,
  ID,
  TOKEN,
} from "../../../Utils/Config";
import Header from "./Components/Header";
import SideBar from "./Components/SideBar";
import QuickLinks from "./Components/QuickLinks";

const EditUser = () => {
  document.title = "Edit User";
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);
  const { item } = location.state;

  const [formState, setFormState] = useState({
    first_name: item.firstname,
    last_name: item.lastname,
    email: item.username,
    telephone: item.phone,
    role: item.role,
    status: "Active",
  });

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    console.log("State: ", item);
  }, []);

  return (
    <div>
      <div className="breadcrump">
        <Link to="/admin/restaurants" className="breadcrumb-not-active">
          Home
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link onClick={() => navigate(-1)} className="breadcrumb-not-active">
          Users
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link
          onClick={(event) => event.preventDefault()}
          className="breadcrumb-active"
        >
          Edit User
        </Link>
      </div>

      <div className="App-container-header-table-wrapper">
        <div className="App-container-header">
          <p>Edit User</p>
        </div>

        <div className="App-container-table">
          <div className="App-table edit-app-table">
            {loading === true ? (
              <div className="App-loader">
                <Loader />
              </div>
            ) : (
              <></>
            )}
            <div className="App-container-row-body">
              <div className="App-input-group">
                <label htmlFor="">Fisrt Name</label>
                <div className="input-container">
                  <input
                    type="text"
                    id=""
                    name="first_name"
                    placeholder="Noella"
                    value={formState.first_name}
                    onChange={changeHandler}
                    className="App-input-add"
                    required
                  />
                  <span className="input-group-icon">
                    <Edit />
                  </span>
                </div>
              </div>
              <div className="App-input-group">
                <label htmlFor="">Email</label>
                <div className="input-container">
                  <input
                    type="email"
                    id=""
                    name="email"
                    placeholder="noella@heartyishes.co.ke"
                    value={formState.email}
                    onChange={changeHandler}
                    className="App-input-add"
                    required
                  />
                  <span className="input-group-icon">
                    <Edit />
                  </span>
                </div>
              </div>
              <div className="App-input-group">
                <label htmlFor="">Last Name</label>
                <div className="input-container">
                  <input
                    type="text"
                    id=""
                    name="last_name"
                    placeholder="Mwanzia"
                    value={formState.last_name}
                    onChange={changeHandler}
                    className="App-input-add"
                    required
                  />
                  <span className="input-group-icon">
                    <Edit />
                  </span>
                </div>
              </div>
              <div className="App-input-group">
                <label htmlFor="">User Role</label>
                <div className="input-container">
                  <select
                    name="role"
                    id=""
                    onChange={changeHandler}
                    // value={formState.role}
                    style={{ border: "0", outline: "none" }}
                  >
                    <option value={formState.role}>{formState.role}</option>
                  </select>
                  <span className="input-group-icon">
                    <Edit />
                  </span>
                </div>
              </div>
              <div className="App-input-group">
                <label htmlFor="">Telephone</label>
                <div className="input-container">
                  <input
                    type="tel"
                    id=""
                    name="telephone"
                    placeholder="0742555666"
                    value={formState.telephone === null ? "N/A" : formState.telephone}
                    onChange={(e) =>
                      setFormState({
                        ...formState,
                        telephone: e.target.value,
                      })
                    }
                    className="App-input-add"
                    required
                  />
                  <span className="input-group-icon">
                    <Edit />
                  </span>
                </div>
              </div>
            </div>
            <div className="card-footer-container">
              {saved && (
                <div className="saved-toast">
                  <span>
                    <CheckCircleOutline />
                  </span>
                  <span style={{ marginLeft: "10px" }}>
                    <strong>Success</strong>. You successfully edited a user.
                  </span>
                </div>
              )}
              <div className="App-input-group-buttons">
                <div className="App-input-group-button form-app-button">
                  <div
                    className="App-button-add"
                  >
                    Save Details
                  </div>
                </div>
                <div className="App-input-group-button form-app-button">
                  <div
                    className="App-button-cancel"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUser;
