import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Components/Auth/Login';
import Restaurants from './Components/App/Admin/Restaurants';
import AddRestaurant from './Components/App/Admin/AddRestaurant';
import EditRestaurant from './Components/App/Admin/EditRestaurant';
import NotFound from './Utils/NotFound';
import Reports from './Components/App/Admin/Reports';
import Users from './Components/App/Admin/Users';
import AddUser from './Components/App/Admin/AddUser';
import EditUser from './Components/App/Admin/EditUser';
import SystemLogs from './Components/App/Admin/SystemLogs'
import ViewRestaurant from './Components/App/Admin/ViewRestaurant';
import Admin from './Components/App/Admin/Admin';
import Restaurant from './Components/App/Admin/Restaurant';
import MenuCategory from './Components/App/Admin/MenuCategory';
import Items from './Components/App/Admin/Items';
import Modifiers from './Components/App/Admin/Modifiers';
import Upsells from './Components/App/Admin/Upsells';
import CreateMenu from './Components/App/Admin/CreateMenu';
import Devices from './Components/App/Admin/Devices';
import Payments from './Components/App/Admin/Payments';
import QRCodes from './Components/App/Admin/QRCodes';
import AddBranch from './Components/App/Admin/AddBranch';
import EditBranch from './Components/App/Admin/EditBranch';
import Device from './Components/App/Admin/Device'
import AddDevice from './Components/App/Admin/AddDevice';
import AddMenuCategory from './Components/App/Admin/AddMenuCategory';
import EditMenuCategory from './Components/App/Admin/EditMenuCategory';
import ViewMenuCategory from './Components/App/Admin/ViewMenuCategory';
import AddItem from './Components/App/Admin/AddItem';
import EditItem from './Components/App/Admin/EditItem';
import AddUpsell from './Components/App/Admin/AddUpsell';
import EditUpsell from './Components/App/Admin/EditUpsell';
import AddMenu from './Components/App/Admin/AddMenu';
import AddModifier from './Components/App/Admin/AddModifier';
import EditModifier from './Components/App/Admin/EditModifier';
import EditMenu from './Components/App/Admin/EditMenu';
import ViewMenu from './Components/App/Admin/ViewMenu';
import AddQRCode from './Components/App/Admin/AddQRCode';
import EditQrCode from './Components/App/Admin/EditQrCode';
import Orders from './Components/App/Admin/Orders';
import Promotions from './Components/App/Admin/Promotions';
import AddPromotion from './Components/App/Admin/AddPromotion';
import EditPromotion from './Components/App/Admin/EditPromotion';
import DailyAnalysis from './Components/App/Admin/DailyAnalysis';
import WeeklyAnalysis from './Components/App/Admin/WeeklyAnalysis';
import AnnualAnalysis from './Components/App/Admin/AnnualAnalysis';
import PaymentAnalysis from './Components/App/Admin/PaymentAnalysis';
import OrdersByHourAnalysis from './Components/App/Admin/OrdersByHourAnalysis';
import CustomerOrderReport from './Components/App/Admin/CustomerOrderReport';
import CampaignPromotionAnalysis from './Components/App/Admin/CampaignPromotionAnalysis';
import AddPayment from './Components/App/Admin/AddPayment';
import ViewOrder from './Components/App/Admin/ViewOrder';
import PaymentBranch from './Components/App/Admin/PaymentBranch';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Login />}></Route>
        <Route path='dashboard' element={<Admin />} >
          <Route path='restaurants' element={<Restaurants />} />
          <Route path='add-restaurant' element={<AddRestaurant />} />
          <Route path='edit-restaurant/:id' element={<EditRestaurant />} />
          <Route path='restaurants/:company_name' element={<Restaurant />}/>
          <Route path='restaurants/:company_name/add-branch' element={<AddBranch />} />
          <Route path='restaurants/:company_name/edit-branch/:branch_id' element={<EditBranch />} />
          <Route path='restaurants/:company_name/:branch_id/add-payment-method' element={<PaymentBranch />} />
          <Route path="restaurants/:company_name/add-device" element={<AddDevice />} />
          <Route path="restaurants/:company_name/add-menu-category" element={<AddMenuCategory />} />
          <Route path='restaurants/:company_name/edit-menu-category/:id' element={<EditMenuCategory />} />
          <Route path='restaurants/:company_name/view-menu-category/:id' element={<ViewMenuCategory />} />
          <Route path='restaurants/:company_name/add-item' element={<AddItem />} />
          <Route path='restaurants/:company_name/edit-item/:id' element={<EditItem />} />
          <Route path='restaurants/:company_name/add-upsell' element={<AddUpsell />} />
          <Route path='restaurants/:company_name/edit-upsell/:id' element={<EditUpsell />} />
          <Route path='restaurants/:company_name/add-menu' element={<AddMenu />} />
          <Route path="restaurants/:company_name/add-modifier" element={<AddModifier />} />
          <Route path='restaurants/:company_name/edit-modifier/:id' element={<EditModifier />} />
          <Route path='restaurants/:company_name/edit-menu/:id' element={<EditMenu />} />
          <Route path='restaurants/:company_name/view-menu' element={<ViewMenu />} />
          <Route path='restaurants/:company_name/view-order' element={<ViewOrder />} />
          <Route path="restaurants/:company_name/add-qr-code" element={<AddQRCode />} />
          <Route path='restaurants/:company_name/edit-qr-code/:id' element={<EditQrCode />} />
          <Route path='restaurants/:company_name/promotions' element={<Promotions />} />
          <Route path='restaurants/:company_name/edit-promotion/:id' element={<EditPromotion />} />
          <Route path='restaurants/:company_name/orders' element={<Orders />} />
          <Route path='restaurants/:company_name/add-promotions' element={<AddPromotion />} />

          <Route path='restaurants/:company_name/menu-category' element={<MenuCategory />} />
          <Route path='restaurants/:company_name/items' element={<Items />} />
          <Route path='restaurants/:company_name/modifiers' element={<Modifiers />} />
          <Route path='restaurants/:company_name/upsells' element={<Upsells />} />
          <Route path='restaurants/:company_name/create-menu' element={<CreateMenu />} />
          <Route path='restaurants/:company_name/device' element={<Device />} />
          <Route path='restaurants/:company_name/devices' element={<Devices />} />
          <Route path='restaurants/:company_name/payments' element={<Payments />} />
          <Route path='restaurants/:company_name/payments/add-payment' element={<AddPayment />} />
          <Route path='restaurants/:company_name/qr-codes' element={<QRCodes />} />

          <Route path='reports-all' element={<Reports />} />
          <Route path='add-user' element={<AddUser />} />
          <Route path='edit-user/:id' element={<EditUser />} />
          <Route path='system-logs' element={<SystemLogs />} />

        </Route>
        <Route path='users' element={<Users />} />
        <Route path="reports/daily-analysis" element={<DailyAnalysis />} />
        <Route path="reports/weekly-analysis" element={<WeeklyAnalysis />} />
        <Route path="reports/annual-analysis" element={<AnnualAnalysis />} />
        <Route path='reports/customer-order-report' element={<CustomerOrderReport />} />
        <Route path="reports/payment-analysis" element={<PaymentAnalysis />} />
        <Route path="reports/order-hour-analysis" element={<OrdersByHourAnalysis />} />
        <Route path='reports/campaign-promotion-analysis' element={<CampaignPromotionAnalysis/>} />

        <Route path='*' element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
