import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import MiniSidebar from "./MiniSidebar";
import {
  Search,
  AddCircleOutlineRounded,
  HighlightOff,
} from "@mui/icons-material";
import {
  BASE_URL,
  axiosInstance,
  toastError,
  validateModifierGroup,
  toastSuccess,
} from "../../../Utils/Config";
import { ToastContainer } from "react-toastify";

const EditMenu = () => {
  const { company_name } = useParams();
  const navigate = useNavigate();
  const [modifiers, setModifiers] = useState([]);
  const [addGroup, setAddGroup] = useState(false);
  const location = useLocation();
  const item = location.state.data;
  const companyString = sessionStorage.getItem("Restaurant");
  const company = JSON.parse(companyString);
  const {userId} = JSON.parse(sessionStorage.getItem("userId"))
  const [modifierGroup, setModifierGroup] = useState([]);
  const [modGroupItems, setModGroupItems] = useState({
    title: "",
    subtitle: "",
    required: 0,
    modifiers: [],
  });

  useEffect(() => {
    console.log("Item: ", item);
    console.log("Existing menuItems: ", item.menuItems);
    fetchModifiers();
  }, []);

  function uncheckAllCheckboxes() {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');

    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
  }

  const handleAddGroup = async (e) => {
    if (e.target.value === "yes") {
      setAddGroup(true);
    } else {
      setAddGroup(false);
    }
  };

  const modGrpChangeHandler = (event) => {
    const { name, value } = event.target;
    setModGroupItems((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleModifierNameChange = (event) => {
    const { value, checked } = event.target;
    setModGroupItems((prevState) => ({
      ...prevState,
      modifiers: checked
        ? [...prevState.modifiers, value]
        : prevState.modifiers.filter((modifier) => modifier !== value),
    }));
  };

  const fetchModifiers = async () => {
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}/modifier/restaurant/${company["id"]}`
      );
      const data = response.data;
      if (data.code === 200) {
        setModifiers(data.modifiers);
      } else {
        toastError(data.message);
      }
    } catch (error) {
      alert(error);
    }
  };

  const addModifierGroup = () => {
    const validate = validateModifierGroup(modGroupItems);
    if (validate === null) {
      toastSuccess("Modifier added successfully");
      setModifierGroup((prevState) => [...prevState, { ...modGroupItems }]);
      fetchModifiers();
      setModGroupItems({
        title: "",
        subtitle: "",
        required: 0,
        modifiers: [],
      });
      uncheckAllCheckboxes();
    } else {
      toastError(validate);
    }
  };

  const apiCall = async () => {
    const existingMenuItems = item.menuItems;

    const payload = {
      items: modifierGroup,
    };
    try {
      const response = await axiosInstance.put(`${BASE_URL}/menu/${item.id}/${userId}`, payload)
      const data = response.data
      if(data.code === 200){
        toastSuccess(data.message)
        setTimeout(()=>{
          navigate(-1)
        },1000)
      } else{
        toastError(data.message)
      }
    } catch (error) {
      toastError(error.code)
    }
  };

  const deleteModGroup = async (id) => {
    try{
      const response = await axiosInstance.delete(`${BASE_URL}/menu/menu_item/${id}`)
      const data = response.data
      if(data.code === 200){
        toastSuccess(data.message)
        setTimeout(()=>{
          navigate(-1)
        },1000)
      } else {
        toastError(data.message)
      }
    } catch(error){
      toastError(error.code)
    }
  }

  return (
    <div>
      <div className="breadcrump">
        <Link to="/admin/restaurants" className="breadcrumb-not-active">
          Home
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link to="/admin/restaurants" className="breadcrumb-active">
          All Restaurants
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link
          onClick={(event) => event.preventDefault()}
          className="breadcrumb-active"
        >
          {company_name}
        </Link>
      </div>
      <div className="App-container-header-table-wrapper">
        <div className="App-container-header">
          <p>{company_name}</p> - <p>Edit Menu</p>
        </div>
        <div className="App-container-table">
          <div className="restaurant-table-wrapper">
            <div style={{ paddingTop: "33px" }}>
              <MiniSidebar />
            </div>
            <div className="table-container">
              <div className="App-table restaurant-app-table">
                <div
                  className="App-container-row-body-full"
                  style={{
                    border: "1px solid #83B302",
                    marginTop: "33px",
                    paddingTop: 0,
                    borderRadius: "7px",
                  }}
                >
                  <div className="create-menu-container">
                    <div className="create-menu-header">
                      <div className="header-name">Edit Menu Item</div>
                    </div>
                    <div className="category-view-head">
                      <label htmlFor="">Menu Category :</label>
                      <p>{item.category['name']}</p>
                    </div>
                    <div>
                      <div className="category-label">
                        <p>Food Item(s)</p>
                      </div>
                      <div className="category-items">
                        {/* <p className="category-item">Tea</p> */}
                        <p className="category-item">
                          <div className="checkbox-wrapper">
                            <input type="radio" name="" id="" checked />
                            <label htmlFor="" className="checkbox-label">
                              {item.item["name"]}
                            </label>
                          </div>
                        </p>
                      </div>
                    </div>
                    <div style={{ marginTop: "32px" }}>
                      <div className="category-label">
                        <p>Edit Selected Modifier(s)</p>
                      </div>
                    </div>
                    <div className="modifier-group-wrapper">
                      {item.menuItems?.map((menuItem, index) => (
                        <div className="modifier-group-card">
                          <div className="mod-group-title">
                            <label htmlFor="">Title: </label>
                            <p>{menuItem.title}</p>
                          </div>
                          <div className="mod-group-subtitle">
                            <label htmlFor="">Sub-title: </label>
                            <p>{menuItem.subtitle}</p>
                          </div>
                          <div className="mod-group-required">
                            <label htmlFor="">Required: </label>
                            <p>{menuItem.required}</p>
                          </div>
                          <div className="mod-group-modifiers-wrapper">
                            <label htmlFor="">Modifiers: </label>
                            <div className="mod-group-modifiers">
                              {menuItem.menuItemOptions?.map(
                                (modifier, modifierIndex) => (
                                  <div
                                    key={modifierIndex}
                                    style={{ display: "flex" }}
                                  >
                                    <p>{modifier.modifiers.name}</p>
                                    {modifierIndex <
                                      menuItem.menuItemOptions.length - 1 &&
                                      ","}
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                          <div className="mod-group-delete" onClick={()=>deleteModGroup(menuItem.id)}>
                            <HighlightOff fontSize="small" />
                          </div>
                        </div>
                      ))}
                    </div>
                    <div
                      style={{
                        width: "50%",
                        margin: "auto",
                        marginTop: "20px",
                      }}
                    >
                      <label htmlFor="">
                        Do you want to add another modifier group?
                      </label>
                      <div className="App-input-group-select">
                        <select name="" id="" onChange={handleAddGroup}>
                          <option value="" selected disabled>
                            Add modifier
                          </option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </div>
                    </div>
                    {/* CONDITIONSAL RENDER THESE COMPONENTS */}
                    {addGroup && (
                      <>
                        <div style={{ marginTop: "32px" }}>
                          <div className="category-label">
                            <p>Select Modifier(s)</p>
                          </div>
                          <div
                            style={{
                              width: "35%",
                              marginTop: "15px",
                              marginLeft: "15px",
                            }}
                          >
                            <div
                              className="Search-bar"
                              style={{ backgroundColor: "#83B3020D" }}
                            >
                              <Search />
                              <input
                                type="search"
                                name=""
                                id=""
                                placeholder="Search Modifier(s)"
                                style={{
                                  padding: "5px 0 5px 0",
                                  width: "100%",
                                }}
                              />
                            </div>
                          </div>

                          {modifiers.length > 0 ? (
                            <div
                              className="checkboxes-container"
                              style={{
                                borderBottom: "2px solid #83b302",
                                paddingBottom: "30px",
                              }}
                            >
                              {modifiers.map((modifier) => (
                                <div
                                  className="checkbox-wrapper"
                                  key={modifier.id}
                                >
                                  <input
                                    type="checkbox"
                                    name="modifiers"
                                    id={modifier.id}
                                    value={modifier.id}
                                    // onChange={modGrpChangeHandler}
                                    onChange={handleModifierNameChange}
                                    // checked={modGroupItems.modifiers.includes(modifier.id)}
                                  />
                                  <label
                                    htmlFor={modifier.id}
                                    className="checkbox-label"
                                  >
                                    {modifier.name}
                                  </label>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div
                              style={{
                                marginTop: "10px",
                                paddingLeft: "20px",
                                borderBottom: "2px solid #83b302",
                                paddingBottom: "30px",
                              }}
                            >
                              <p>There are no modifiers</p>
                            </div>
                          )}
                        </div>

                        <div
                          className="menu-category-inputs"
                          style={{ marginTop: "32px" }}
                        >
                          <div className="App-input-group">
                            <label htmlFor="">Title Name</label>
                            <input
                              type="text"
                              id=""
                              name="title"
                              value={modGroupItems.title}
                              onChange={modGrpChangeHandler}
                              placeholder="Title Name"
                              className="App-input-add"
                              required
                            />
                          </div>
                          <div className="App-input-group">
                            <label htmlFor="">Subtitle Name</label>
                            <input
                              type="text"
                              id=""
                              name="subtitle"
                              value={modGroupItems.subtitle}
                              onChange={modGrpChangeHandler}
                              placeholder="Subtitle Name"
                              className="App-input-add"
                              required
                            />
                          </div>
                          <div
                            className="checkbox-wrapper"
                            style={{ marginTop: "15px" }}
                          >
                            <input
                              type="checkbox"
                              name="required"
                              checked={modGroupItems.required}
                              onChange={modGrpChangeHandler}
                              id=""
                            />
                            <label htmlFor="" className="checkbox-label">
                              Required
                            </label>
                          </div>
                        </div>
                        <div className="modifier-button">
                          <div className="App-right" onClick={addModifierGroup}>
                            <AddCircleOutlineRounded
                              sx={{ color: "white", marginRight: "10px" }}
                            />
                            <div className="App-title-right">Add Modifier</div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    className="card-footer-container"
                    style={{ paddingTop: "10px" }}
                  >
                    <div className="App-input-group-buttons">
                      <div className="App-input-group-button form-app-button">
                        <div className="App-button-add" onClick={apiCall}>
                          Submit
                        </div>
                      </div>
                      <div className="App-input-group-button form-app-button">
                        <div
                          className="App-button-cancel"
                          onClick={() => navigate(-1)}
                        >
                          Cancel
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer theme="colored" autoClose={1500} hideProgressBar={true} />
    </div>
  );
};

export default EditMenu;
