import Empty from "../../../Utils/Empty";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import { useState, useEffect } from "react";
import MiniSidebar from "./MiniSidebar";
import { AddCircleOutlineRounded, Search } from "@mui/icons-material";
import { useParams, Link, useNavigate } from "react-router-dom";
import { BASE_URL, toastError, axiosInstance } from "../../../Utils/Config";
import { ToastContainer } from "react-toastify";
import Loader from "../../../Utils/Loader";

const Restaurant = () => {
	const [showEmpty, setShowEmpty] = useState(false);
	const { company_name } = useParams();
	const navigate = useNavigate();
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const itemString = sessionStorage.getItem("Restaurant");
	const item = JSON.parse(itemString);
	const [query, setQuery] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(10);
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

	const keys = [
		"branch_name",
		"physical_address",
		"email_address",
		"telephone",
		"contact_person",
		"status",
	];

	useEffect(() => {
		fetchBranches();
	}, []);

	const fetchBranches = async () => {
		try {
			const response = await axiosInstance.get(
				`${BASE_URL}/branch/restaurant/${item["id"]}`
			);
			const data = response.data;
			setLoading(false);
			if (data.code === 200) {
				setData(data.branches);
			} else {
				toastError(data.message);
			}
		} catch (error) {
			toastError(error.code);
			setLoading(false);
		}
	};

	const editBranch = (data) => {
		navigate(`/dashboard/restaurants/${company_name}/edit-branch/${data.id}`, {
			state: { data },
		});
	};

	const addPaymentMethod = (id) => {
		navigate(`/dashboard/restaurants/${company_name}/${id}/add-payment-method`);
	};

	return (
		<div>
			<div className="breadcrump">
				<Link to="/dashboard/restaurants" className="breadcrumb-not-active">
					Home
				</Link>
				<span className="breadcrumb-arrow">&gt;</span>
				<Link to="/dashboard/restaurants" className="breadcrumb-active">
					All Restaurants
				</Link>
				<span className="breadcrumb-arrow">&gt;</span>
				<Link
					onClick={(event) => event.preventDefault()}
					className="breadcrumb-active">
					{company_name}
				</Link>
			</div>
			<div className="App-container-header-table-wrapper">
				<div className="App-container-header">
					<p>{company_name}</p>
				</div>
				<div className="App-container-table">
					<div className="App-container-table-top-bar">
						<div
							className="Search-bar"
							style={{ backgroundColor: "#83B3020D" }}>
							<Search />
							<input
								type="search"
								name=""
								id=""
								placeholder="Search..."
								style={{ backgroundColor: "inherit" }}
								onChange={(e) => setQuery(e.target.value)}
							/>
						</div>
						<div
							className="App-right"
							onClick={() =>
								navigate(`/dashboard/restaurants/${company_name}/add-branch`)
							}>
							<AddCircleOutlineRounded
								sx={{ color: "white", marginRight: "10px" }}
							/>
							<div className="App-title-right">Add Branch</div>
						</div>
					</div>
					<div className="restaurant-table-wrapper">
						<MiniSidebar />
						<div className="table-container">
							<div className="App-table restaurants-app-table">
								{loading === true ? (
									<div className="App-loader">
										<Loader />
									</div>
								) : (
									<></>
								)}
								{showEmpty !== true ? (
									<>
										<Table stripped="true" bordered responsive size="xl">
											<thead id="Table-heading-wrapper">
												<tr>
													<th id="t-heading">Branch Name</th>
													<th id="t-heading">Location</th>
													<th id="t-heading">Email</th>
													<th id="t-heading">Telephone</th>
													<th id="t-heading">Contact Person</th>
													<th id="t-heading">Status</th>
													<th id="t-heading">Action</th>
												</tr>
											</thead>
											<tbody>
												{currentItems
													?.filter((item) =>
														keys.some((key) =>
															item[key].toLowerCase().includes(query)
														)
													)
													.map((item) => (
														<tr key={item.id}>
															<td id="t-body" style={{ height: "80px" }}>
																<p>{item.branch_name}</p>
															</td>
															<td id="t-body" style={{ height: "80px" }}>
																<p>{item.physical_address}</p>
															</td>
															<td id="t-body" style={{ height: "80px" }}>
																<p>{item.email_address}</p>
															</td>
															<td id="t-body" style={{ height: "80px" }}>
																<p>{item.telephone}</p>
															</td>
															<td id="t-body" style={{ height: "80px" }}>
																<p>{item.contact_person}</p>
															</td>
															<td id="t-body-status" style={{ height: "80px" }}>
																{item.status === "Active" ? (
																	// <Badge bg="success">Active</Badge>
																	<div className="company-status">
																		<p id="status" className="active">
																			Active
																		</p>
																	</div>
																) : (
																	<div className="company-status">
																		<p id="status" className="inactive">
																			Inactive
																		</p>
																	</div>
																)}
															</td>
															<td className="App-table-actions">
																<div
																	className="App-table-action-button"
																	onClick={() => editBranch(item)}
																	style={{
																		background: "#337CA0",
																	}}>
																	Edit
																</div>
																<div
																	className="App-table-action-button"
																	onClick={() => addPaymentMethod(item.id)}
																	style={{
																		background: "#337CA0",
																	}}>
																	Payment
																</div>
															</td>
														</tr>
													))}
											</tbody>
										</Table>
										<Pagination className="pagination">
											<Pagination.Prev
												onClick={() =>
													setCurrentPage((prev) => Math.max(prev - 1, 1))
												}
											/>
											{Array.from({
												length: Math.ceil(data?.length / itemsPerPage),
											}).map((page, index) => (
												<Pagination.Item
													key={index + 1}
													active={index + 1 === currentPage}
													onClick={() => setCurrentPage(index + 1)}>
													{index + 1}
												</Pagination.Item>
											))}
											<Pagination.Next
												onClick={() =>
													setCurrentPage((prev) =>
														Math.min(
															prev + 1,
															Math.ceil(data?.length / itemsPerPage)
														)
													)
												}
											/>
										</Pagination>
									</>
								) : (
									<div className="App-empty">
										<Empty />
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<ToastContainer theme="colored" autoClose={1500} hideProgressBar={true} />
		</div>
	);
};

export default Restaurant;
