import { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
	Pause,
	ConfirmationNumberOutlined,
	RestaurantMenu,
} from "@mui/icons-material";
import BranchIcon from "../../../Images/bi_houses.svg";
import CategoryIcon from "../../../Images/collapse-categories.svg";
import ModifierIcon from "../../../Images/icon-park-outline.svg";
import SellsIcon from "../../../Images/sellsy.svg";
import JamMenuIcon from "../../../Images/jam_menu.svg";
import DevicesIcon from "../../../Images/material-symbols_devices.svg";
import PaymentIcon from "../../../Images/outline-payments.svg";
import QrIcon from "../../../Images/qrcode.svg";

const MiniSidebar = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { company_name } = useParams();
	const [active, setActive] = useState(null);

	useEffect(() => {
		if (location.pathname.includes("menu-category")) {
			setActive(1);
		} else if (location.pathname.includes("items")) {
			setActive(2);
		} else if (location.pathname.includes("modifiers")) {
			setActive(3);
		} else if (location.pathname.includes("upsells")) {
			setActive(4);
		} else if (location.pathname.includes("create-menu")) {
			setActive(5);
		} else if (location.pathname.includes("devices")) {
			setActive(6);
		} else if (location.pathname.includes("orders")) {
			setActive(7);
		} else if (location.pathname.includes("promotions")) {
			setActive(8);
		} else if (location.pathname.includes("payments")) {
			setActive(9);
		} else if (location.pathname.includes("qr-codes")) {
			setActive(10);
		} else {
			setActive(0); // default
		}
	}, [location.pathname]);

	const setActiveLink = (index) => {
		setActive(index);
	};

	return (
		<div className="restaurant-mini-sidebar-container">
			<div className="restaurant-mini-sidebar">
				<div
					className={`mini-sidebar-item ${active === 0 ? "active" : ""}`}
					onClick={() => {
						setActiveLink(0);
						navigate(`/dashboard/restaurants/${company_name}`);
					}}>
					<span className="mini-sidebar-icon">
						<img src={BranchIcon} alt="" />
					</span>
					<span>Branch</span>
				</div>
				<div
					className={`mini-sidebar-item ${active === 1 ? "active" : ""}`}
					onClick={() => {
						setActiveLink(1);
						navigate(`/dashboard/restaurants/${company_name}/menu-category`);
					}}>
					<span className="mini-sidebar-icon">
						<img src={CategoryIcon} alt="" />
					</span>
					<span>Menu Categories</span>
				</div>
				<div
					className={`mini-sidebar-item ${active === 2 ? "active" : ""}`}
					onClick={() => {
						setActiveLink(2);
						navigate(`/dashboard/restaurants/${company_name}/items`);
					}}>
					<span className="mini-sidebar-icon">
						<Pause style={{ fontSize: "large" }} />
						{/* <img src={BranchIcon} alt="" /> */}
					</span>
					<span>Items</span>
				</div>
				<div
					className={`mini-sidebar-item ${active === 3 ? "active" : ""}`}
					onClick={() => {
						setActiveLink(3);
						navigate(`/dashboard/restaurants/${company_name}/modifiers`);
					}}>
					<span className="mini-sidebar-icon">
						<img src={ModifierIcon} alt="" />
					</span>
					<span>Modifiers</span>
				</div>
				<div
					className={`mini-sidebar-item ${active === 4 ? "active" : ""}`}
					onClick={() => {
						setActiveLink(4);
						navigate(`/dashboard/restaurants/${company_name}/upsells`);
					}}>
					<span className="mini-sidebar-icon">
						<img src={SellsIcon} alt="" />
					</span>
					<span>Upsells</span>
				</div>
				<div
					className={`mini-sidebar-item ${active === 5 ? "active" : ""}`}
					onClick={() => {
						setActiveLink(5);
						navigate(`/dashboard/restaurants/${company_name}/create-menu`);
					}}>
					<span className="mini-sidebar-icon">
						<img src={JamMenuIcon} alt="" />
					</span>
					<span>Create Menu</span>
				</div>
				<div
					className={`mini-sidebar-item ${active === 6 ? "active" : ""}`}
					onClick={() => {
						setActiveLink(6);
						navigate(`/dashboard/restaurants/${company_name}/devices`);
					}}>
					<span className="mini-sidebar-icon">
						<img src={DevicesIcon} alt="" />
					</span>
					<span>Devices</span>
				</div>
				<div
					className={`mini-sidebar-item ${active === 7 ? "active" : ""}`}
					onClick={() => {
						setActiveLink(7);
						navigate(`/dashboard/restaurants/${company_name}/orders`);
					}}>
					<span className="mini-sidebar-icon">
						{/* <img src={DevicesIcon} alt="" /> */}
						<RestaurantMenu />
					</span>
					<span>Orders</span>
				</div>
				<div
					className={`mini-sidebar-item ${active === 8 ? "active" : ""}`}
					onClick={() => {
						setActiveLink(8);
						navigate(`/dashboard/restaurants/${company_name}/promotions`);
					}}>
					<span className="mini-sidebar-icon">
						<ConfirmationNumberOutlined />
					</span>
					<span>Promotions</span>
				</div>
				<div
					className={`mini-sidebar-item ${active === 9 ? "active" : ""}`}
					onClick={() => {
						setActiveLink(9);
						navigate(`/dashboard/restaurants/${company_name}/payments`);
					}}>
					<span className="mini-sidebar-icon">
						<img src={PaymentIcon} alt="" />
					</span>
					<span>Payments Taxes</span>
				</div>
				<div
					className={`mini-sidebar-item ${active === 10 ? "active" : ""}`}
					onClick={() => {
						setActiveLink(10);
						navigate(`/dashboard/restaurants/${company_name}/qr-codes`);
					}}>
					<span className="mini-sidebar-icon">
						<img src={QrIcon} alt="" />
					</span>
					<span>QR Codes</span>
				</div>
			</div>
		</div>
	);
};

export default MiniSidebar;
