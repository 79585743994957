import { useState, useEffect } from "react";
import Empty from "../../../Utils/Empty";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import MiniSidebar from "./MiniSidebar";
import { AddCircleOutlineRounded, Search } from "@mui/icons-material";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Modal, Button, Box, Typography } from "@mui/material";
import Loader from "../../../Utils/Loader";
import { ToastContainer } from "react-toastify";
import {
	BASE_URL,
	IMAGE_URL,
	toastError,
	toastSuccess,
	axiosInstance,
} from "../../../Utils/Config";

const Items = () => {
	const navigate = useNavigate();
	const [showEmpty, setShowEmpty] = useState(false);
	const [data, setData] = useState([]);
	const { company_name } = useParams();
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const [loading, setLoading] = useState(true);
	const itemString = sessionStorage.getItem("Restaurant");
	const item = JSON.parse(itemString);
	const [query, setQuery] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(10);
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

	const keys = ["name", "price", "description"];

	const handleStock = (e) => {
		e.preventDefault();
		toastSuccess("Successfully Added");
		handleClose();
	};

	useEffect(() => {
		fetchItems();
	}, []);

	const fetchItems = async () => {
		try {
			const response = await axiosInstance.get(
				`${BASE_URL}/item/restaurant/${item["id"]}`
			);
			const data = response.data;
			setLoading(false);
			if (data.code === 200) {
				setData(data.items);
			} else {
				toastError(data.message);
			}
		} catch (error) {
			toastError(error.code);
			setLoading(false);
		}
	};

	const editItem = (data) => {
		navigate(`/dashboard/restaurants/${company_name}/edit-item/${data.id}`, {
			state: { data },
		});
	};

	const formatToEAT = (timestamp) => {
		const date = new Date(timestamp);
		const options = { timeZone: "Africa/Nairobi" };
		return date.toLocaleString("en-US", options);
	};

	return (
		<div>
			<div className="breadcrump">
				<Link to="/dashboard/restaurants" className="breadcrumb-not-active">
					Home
				</Link>
				<span className="breadcrumb-arrow">&gt;</span>
				<Link to="/dashboard/restaurants" className="breadcrumb-active">
					All Restaurants
				</Link>
				<span className="breadcrumb-arrow">&gt;</span>
				<Link
					onClick={(event) => event.preventDefault()}
					className="breadcrumb-active">
					{company_name}
				</Link>
			</div>
			<div className="App-container-header-table-wrapper">
				<div className="App-container-header">
					<p>{company_name}</p> - <p>Items</p>
				</div>
				<div className="App-container-table">
					<div className="App-container-table-top-bar">
						<div
							className="Search-bar"
							style={{ backgroundColor: "#83B3020D" }}>
							<Search />
							<input
								type="search"
								name=""
								id=""
								placeholder="Search..."
								style={{ backgroundColor: "inherit" }}
								onChange={(e) => setQuery(e.target.value)}
							/>
						</div>
						<div
							className="App-right"
							onClick={() =>
								navigate(`/dashboard/restaurants/${company_name}/add-item`)
							}>
							<AddCircleOutlineRounded
								sx={{ color: "white", marginRight: "10px" }}
							/>
							<div className="App-title-right">Add Item</div>
						</div>
					</div>
					<div className="restaurant-table-wrapper">
						<MiniSidebar />
						<div className="table-container">
							<div className="App-table restaurants-app-table">
								{loading === true ? (
									<div className="App-loader">
										<Loader />
									</div>
								) : (
									<></>
								)}
								{showEmpty !== true ? (
									<>
										<Table stripped="true" bordered responsive size="xl">
											<thead id="Table-heading-wrapper">
												<tr>
													{/* <th>#</th> */}
													<th id="t-heading">Item Name</th>
													<th id="t-heading">Price/Unit</th>
													<th id="t-heading">Item Description</th>
													<th id="t-heading">Photo</th>
													<th id="t-heading">Date Added</th>
													<th id="t-heading" style={{ width: "14%" }}>
														Action
													</th>
												</tr>
											</thead>
											<tbody>
												{currentItems
													?.filter((item) =>
														keys.some((key) =>
															item[key].toLowerCase().includes(query)
														)
													)
													.map((item) => {
														const formattedTime = formatToEAT(item.createdAt);
														return (
															<tr key={item.id}>
																<td id="t-body">
																	<p>{item.name}</p>
																</td>
																<td id="t-body">
																	<p>{item.price}</p>
																</td>
																<td id="t-description">
																	<p>{item.description}</p>
																</td>
																<td id="t-body-image">
																	<div id="p-image">
																		<img
																			src={IMAGE_URL + item.image}
																			alt="image"
																		/>
																	</div>
																</td>
																<td id="t-body">
																	<p>{formattedTime}</p>
																</td>
																<td className="App-table-actions">
																	<div
																		className="App-table-action-button"
																		//   onClick={() => editRestaurant(item.id)}
																		onClick={() => editItem(item)}
																		style={{
																			backgroundColor: "#337CA0",
																		}}>
																		Edit
																	</div>
																</td>
															</tr>
														);
													})}
											</tbody>
										</Table>
										<Pagination className="pagination">
											<Pagination.Prev
												onClick={() =>
													setCurrentPage((prev) => Math.max(prev - 1, 1))
												}
											/>
											{Array.from({
												length: Math.ceil(data?.length / itemsPerPage),
											}).map((page, index) => (
												<Pagination.Item
													key={index + 1}
													active={index + 1 === currentPage}
													onClick={() => setCurrentPage(index + 1)}>
													{index + 1}
												</Pagination.Item>
											))}
											<Pagination.Next
												onClick={() =>
													setCurrentPage((prev) =>
														Math.min(
															prev + 1,
															Math.ceil(data?.length / itemsPerPage)
														)
													)
												}
											/>
										</Pagination>
									</>
								) : (
									<div className="App-empty">
										<Empty />
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<ToastContainer theme="colored" autoClose={3500} hideProgressBar={true} />
		</div>
	);
};

export default Items;
