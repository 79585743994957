import { useState, useEffect } from "react";
import Empty from "../../../Utils/Empty";
import Table from "react-bootstrap/Table";
import MiniSidebar from "./MiniSidebar";
import { AddCircleOutlineRounded, Search } from "@mui/icons-material";
import { useParams, Link, useNavigate } from "react-router-dom";
import { BASE_URL, axiosInstance, toastError } from "../../../Utils/Config";

import { styled } from "@mui/material/styles";
import {
	FormGroup,
	FormControlLabel,
	Switch,
	Stack,
	Typography,
} from "@mui/material";
import { SwitchProps } from "@mui/material/Switch";

const Payments = () => {
	const IOSSwitch = styled((props) => (
		<Switch
			focusVisibleClassName=".Mui-focusVisible"
			disableRipple
			{...props}
		/>
	))(({ theme }) => ({
		width: 42,
		height: 26,
		padding: 0,
		"& .MuiSwitch-switchBase": {
			padding: 0,
			margin: 2,
			transitionDuration: "300ms",
			"&.Mui-checked": {
				transform: "translateX(16px)",
				color: "#fff",
				"& + .MuiSwitch-track": {
					backgroundColor:
						theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
					opacity: 1,
					border: 0,
				},
				"&.Mui-disabled + .MuiSwitch-track": {
					opacity: 0.5,
				},
			},
			"&.Mui-focusVisible .MuiSwitch-thumb": {
				color: "#33cf4d",
				border: "6px solid #fff",
			},
			"&.Mui-disabled .MuiSwitch-thumb": {
				color:
					theme.palette.mode === "light"
						? theme.palette.grey[100]
						: theme.palette.grey[600],
			},
			"&.Mui-disabled + .MuiSwitch-track": {
				opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
			},
		},
		"& .MuiSwitch-thumb": {
			boxSizing: "border-box",
			width: 22,
			height: 22,
		},
		"& .MuiSwitch-track": {
			borderRadius: 26 / 2,
			backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
			opacity: 1,
			transition: theme.transitions.create(["background-color"], {
				duration: 500,
			}),
		},
	}));

	const navigate = useNavigate();
	const [paymentMethods, setPaymentMethods] = useState([]);
	const [showEmpty, setShowEmpty] = useState(false);
	const { company_name } = useParams();
	const itemString = sessionStorage.getItem("Restaurant");
	const item = JSON.parse(itemString);

	useEffect(() => {
		fetchPaymentMethods();
	}, []);

	const fetchPaymentMethods = async () => {
		try {
			const response = await axiosInstance.get(`${BASE_URL}/payment_method`);
			const data = response.data;
			if (data.code === 200) {
				setPaymentMethods(data.paymentMethods);
			} else {
				toastError(data.message);
			}
		} catch (error) {
			toastError(error.code);
		}
	};

	return (
		<div>
			<div className="breadcrump">
				<Link to="/admin/restaurants" className="breadcrumb-not-active">
					Home
				</Link>
				<span className="breadcrumb-arrow">&gt;</span>
				<Link to="/admin/restaurants" className="breadcrumb-active">
					All Restaurants
				</Link>
				<span className="breadcrumb-arrow">&gt;</span>
				<Link
					onClick={(event) => event.preventDefault()}
					className="breadcrumb-active">
					{company_name}
				</Link>
			</div>
			<div className="App-container-header-table-wrapper">
				<div className="App-container-header">
					<p>{company_name}</p>
				</div>
				<div className="App-container-table">
					<div className="App-container-table-top-bar">
						<div
							className="Search-bar"
							style={{
								backgroundColor: "#83B3020D",
								opacity: 0,
								pointerEvents: "none",
							}}>
							<Search />
							<input
								type="search"
								name=""
								id=""
								placeholder="Search..."
								style={{ backgroundColor: "inherit" }}
							/>
						</div>
						<div
							className="App-right"
							onClick={() =>
								navigate(
									`/dashboard/restaurants/${company_name}/payments/add-payment`
								)
							}>
							<AddCircleOutlineRounded
								sx={{ color: "white", marginRight: "10px" }}
							/>
							<div className="App-title-right">Add Payment Method</div>
						</div>
					</div>
					<div className="restaurant-table-wrapper">
						<MiniSidebar />
						<div className="table-container">
							<div className="payments-taxes-container">
								<div className="payments-container">
									<div className="payments-header">
										<p>Payments</p>
									</div>
									<div className="payments-body">
										<FormGroup>
											{paymentMethods.length > 0 ? (
												paymentMethods.map(
													(paymentMethod) =>
														paymentMethod.name !== null && (
															<div
																style={{
																	display: "flex",
																	alignItems: "flex-end",
																	height: "fit-content",
																}}>
																<span>
																	<FormControlLabel
																		control={
																			<IOSSwitch
																				sx={{ ml: 4, mt: 2 }}
																				defaultChecked
																			/>
																		}
																	/>
																</span>
																<span>{paymentMethod.name}</span>
															</div>
														)
												)
											) : (
												<p>No payment method is added</p>
											)}
											{/* <div
                        style={{
                          display: "flex",
                          alignItems: "flex-end",
                          height: "fit-content",
                        }}
                      >
                        <span>
                          <FormControlLabel
                            control={
                              <IOSSwitch sx={{ ml: 4, mt: 2 }} defaultChecked />
                            }
                            // label="Mpesa"
                          />
                        </span>
                        <span>Mpesa</span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "flex-end",
                          height: "fit-content",
                        }}
                      >
                        <span>
                          <FormControlLabel
                            control={
                              <IOSSwitch sx={{ ml: 4, mt: 2 }} defaultChecked />
                            }
                          />
                        </span>
                        <span>Visa/MasterCard</span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "flex-end",
                          height: "fit-content",
                        }}
                      >
                        <span>
                          <FormControlLabel
                            control={
                              <IOSSwitch sx={{ ml: 4, mt: 2 }} defaultChecked />
                            }
                          />
                        </span>
                        <span>Cash</span>
                      </div> */}
										</FormGroup>
									</div>
								</div>
								<div className="taxes-container">
									<div className="payments-header">
										<p>Taxes</p>
									</div>
									<div className="payments-body">
										<FormGroup>
											<div
												style={{
													display: "flex",
													alignItems: "flex-end",
													height: "fit-content",
												}}>
												<span>
													<FormControlLabel
														control={
															<IOSSwitch sx={{ ml: 4, mt: 2 }} defaultChecked />
														}
														// label="Mpesa"
													/>
												</span>
												<span>VAT(16%)</span>
											</div>
											<div
												style={{
													display: "flex",
													alignItems: "flex-end",
													height: "fit-content",
												}}>
												<span>
													<FormControlLabel
														control={
															<IOSSwitch sx={{ ml: 4, mt: 2 }} defaultChecked />
														}
													/>
												</span>
												<span>Catering Levy (2%)</span>
											</div>
											<div
												style={{
													display: "flex",
													alignItems: "flex-end",
													height: "fit-content",
												}}>
												<span>
													<FormControlLabel
														control={
															<IOSSwitch sx={{ ml: 4, mt: 2 }} defaultChecked />
														}
													/>
												</span>
												<span>Service Fee(1%)</span>
											</div>
										</FormGroup>
									</div>
								</div>
							</div>
							{/* <div className="App-table restaurants-app-table">
                  {showEmpty !== true ? (
                    <Table stripped="true" bordered responsive size="xl">
                      <thead id="Table-heading-wrapper">
                        <tr>
                          <th id="t-heading" style={{ width: "5%" }}>
                            #
                          </th>
                          <th id="t-heading" style={{ width: "45%" }}>
                            Payment Name
                          </th>
                          <th id="t-heading" style={{ width: "14%" }}>
                            Status
                          </th>
                          <th id="t-heading" style={{ width: "14%" }}>
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td id="t-body-number" style={{ height: "80px" }}>
                            <p>1</p>
                          </td>
                          <td
                            id="t-body"
                            style={{ width: "14%", height: "80px" }}
                          >
                            <p>M-Pesa Payment</p>
                          </td>
                          <td
                            id="t-body-status"
                            style={{ width: "14%", height: "80px" }}
                          >
                            <div className="company-status">
                              <p id="status" className="active">
                                Active
                              </p>
                            </div>
                          </td>
                          <td className="App-table-actions">
                            <div className="App-table-action-button">
                              <select name="" id="payment-select">
                                <option value="">Select Branch</option>
                              </select>
                            </div>
                            <div
                              className="App-table-action-button"
                              //   onClick={() => handleClick(item)}
                              style={{
                                backgroundColor: "#FF1D15",
                              }}
                            >
                              Deactivate
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td
                            id="t-body-number"
                            style={{ height: "80px" }}
                          ></td>
                          <td
                            id="t-body"
                            style={{ width: "14%", height: "80px" }}
                          ></td>
                          <td
                            id="t-body"
                            style={{ width: "14%", height: "80px" }}
                          ></td>
                          <td
                            id="t-body"
                            style={{ width: "14%", height: "80px" }}
                          ></td>
                        </tr>
                        <tr>
                          <td
                            id="t-body-number"
                            style={{ height: "80px" }}
                          ></td>
                          <td
                            id="t-body"
                            style={{ width: "14%", height: "80px" }}
                          ></td>
                          <td
                            id="t-body"
                            style={{ width: "14%", height: "80px" }}
                          ></td>
                          <td
                            id="t-body"
                            style={{ width: "14%", height: "80px" }}
                          ></td>
                        </tr>
                        <tr>
                          <td
                            id="t-body-number"
                            style={{ height: "80px" }}
                          ></td>
                          <td
                            id="t-body"
                            style={{ width: "14%", height: "80px" }}
                          ></td>
                          <td
                            id="t-body"
                            style={{ width: "14%", height: "80px" }}
                          ></td>
                          <td
                            id="t-body"
                            style={{ width: "14%", height: "80px" }}
                          ></td>
                        </tr>
                      </tbody>
                    </Table>
                  ) : (
                    <div className="App-empty">
                      <Empty />
                    </div>
                  )}
                </div> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Payments;
