import { useState, useEffect } from "react";
import Empty from "../../../Utils/Empty";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import MiniSidebar from "./MiniSidebar";
import { AddCircleOutlineRounded, Search } from "@mui/icons-material";
import { useParams, Link, useNavigate } from "react-router-dom";
import { BASE_URL, axiosInstance, toastError } from "../../../Utils/Config";

const Upsells = () => {
	const navigate = useNavigate();
	const [data, setData] = useState([]);
	const { company_name } = useParams();
	const itemString = sessionStorage.getItem("Restaurant");
	const item = JSON.parse(itemString);
	const { userId } = JSON.parse(sessionStorage.getItem("userId"));
	const [query, setQuery] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(10);
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

	const keys = ["description", "item.name", "appear.name"];

	const getValueByKey = (object, key) => {
		const keys = key.split(".");
		let value = object;
		keys.forEach((k) => {
			if (value && value.hasOwnProperty(k)) {
				value = value[k];
			} else {
				value = undefined;
			}
		});
		return value;
	};

	useEffect(() => {
		fetchUpsells();
	}, []);

	const fetchUpsells = async () => {
		try {
			const response = await axiosInstance.get(`${BASE_URL}/upsell`);
			const data = response.data;
			if (data.code === 200) {
				setData(data.upsell);
			} else {
				toastError(data.message);
			}
		} catch (error) {
			alert(error);
		}
	};

	const editUpsell = (data) => {
		navigate(`/dashboard/restaurants/${company_name}/edit-upsell/${data.id}`, {
			state: { data },
		});
	};

	return (
		<div>
			<div className="breadcrump">
				<Link to="/admin/restaurants" className="breadcrumb-not-active">
					Home
				</Link>
				<span className="breadcrumb-arrow">&gt;</span>
				<Link to="/admin/restaurants" className="breadcrumb-active">
					All Restaurants
				</Link>
				<span className="breadcrumb-arrow">&gt;</span>
				<Link
					onClick={(event) => event.preventDefault()}
					className="breadcrumb-active">
					{company_name}
				</Link>
			</div>
			<div className="App-container-header-table-wrapper">
				<div className="App-container-header">
					<p>{company_name}</p> - <p>Upsells</p>
				</div>
				<div className="App-container-table">
					<div className="App-container-table-top-bar">
						<div
							className="Search-bar"
							style={{ backgroundColor: "#83B3020D" }}>
							<Search />
							<input
								type="search"
								name=""
								id=""
								placeholder="Search..."
								style={{ backgroundColor: "inherit" }}
								onChange={(e) => setQuery(e.target.value)}
							/>
						</div>
						<div
							className="App-right"
							//   onClick={() => navigate("/admin/add-restaurant")}
							onClick={() =>
								navigate(`/admin/restaurants/${company_name}/add-upsell`)
							}>
							<AddCircleOutlineRounded
								sx={{ color: "white", marginRight: "10px" }}
							/>
							<div className="App-title-right">Add Upsell</div>
						</div>
					</div>
					<div className="restaurant-table-wrapper">
						<MiniSidebar />
						<div className="table-container">
							<div className="App-table restaurants-app-table">
								{data.length > 0 ? (
									<>
										<Table stripped="true" bordered responsive size="xl">
											<thead id="Table-heading-wrapper">
												<tr>
													<th id="t-heading">Upsell Description</th>
													<th id="t-heading">Item(s) Name</th>
													<th id="t-heading">Where To Appear</th>
													<th id="t-heading">Status</th>
													<th id="t-heading">Action</th>
												</tr>
											</thead>
											<tbody>
												{currentItems
													?.filter((upsell) =>
														keys.some((key) =>
															getValueByKey(upsell, key)
																.toLowerCase()
																.includes(query)
														)
													)
													.map((upsell) => (
														<tr key={upsell.id}>
															<td id="t-body">
																<p>{upsell.description}</p>
															</td>
															<td id="t-body">
																<p>{upsell.item.name}</p>
															</td>
															<td id="t-body">
																<p>{upsell.appear.name}</p>
															</td>
															<td id="t-body-status">
																{upsell.status === "1" ? (
																	// <Badge bg="success">Active</Badge>
																	<div className="company-status">
																		<p id="status" className="active">
																			Active
																		</p>
																	</div>
																) : (
																	<div className="company-status">
																		<p id="status" className="inactive">
																			Inactive
																		</p>
																	</div>
																)}
															</td>
															<td className="App-table-actions">
																<div
																	className="App-table-action-button"
																	onClick={() => editUpsell(upsell)}
																	style={{
																		backgroundColor: "#337CA0",
																	}}>
																	Edit
																</div>
															</td>
														</tr>
													))}
											</tbody>
										</Table>
										<Pagination className="pagination">
											<Pagination.Prev
												onClick={() =>
													setCurrentPage((prev) => Math.max(prev - 1, 1))
												}
											/>
											{Array.from({
												length: Math.ceil(data?.length / itemsPerPage),
											}).map((page, index) => (
												<Pagination.Item
													key={index + 1}
													active={index + 1 === currentPage}
													onClick={() => setCurrentPage(index + 1)}>
													{index + 1}
												</Pagination.Item>
											))}
											<Pagination.Next
												onClick={() =>
													setCurrentPage((prev) =>
														Math.min(
															prev + 1,
															Math.ceil(data?.length / itemsPerPage)
														)
													)
												}
											/>
										</Pagination>
									</>
								) : (
									<div className="App-empty">
										<Empty />
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Upsells;
