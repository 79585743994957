import { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import MiniSidebar from "./MiniSidebar";
import { CheckCircleOutline, InsertPhotoOutlined } from "@mui/icons-material";
import {
  BASE_URL,
  axiosInstance,
  toastError,
  toastSuccess,
  validateUpsell,
} from "../../../Utils/Config";
import Empty from "../../../Utils/Empty";
import { ToastContainer } from "react-toastify";

const AddUpsell = () => {
  const [saved, setSaved] = useState(false);
  const { company_name } = useParams();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [branches, setBranches] = useState([]);
  const [showItems, setShowItems] = useState(false);
  const [formState, setFormState] = useState({
    items: [],
    upsell_description: "",
    where_to_appear: "",
    status: "",
    branch: "",
  });
  const itemString = sessionStorage.getItem("Restaurant");
  const item = JSON.parse(itemString);
  const { userId } = JSON.parse(sessionStorage.getItem("userId"));

  const changeHandler = (event) => {
    const { name, type } = event.target;

    if (type === "checkbox") {
      const itemName = event.target.value;
      if (formState.items.includes(itemName)) {
        const updatedItemNames = formState.items.filter(
          (id) => id !== itemName
        );
        setFormState((prevState) => ({
          ...prevState,
          items: updatedItemNames,
        }));
      } else {
        setFormState((prevState) => ({
          ...prevState,
          items: [...prevState.items, itemName],
        }));
      }
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [name]: event.target.value,
      }));
    }
  };

  useEffect(() => {
    fetchItems();
    fetchBranches();
  }, []);

  const fetchItems = async () => {
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}/item/restaurant/${item["id"]}`
      );
      const data = response.data;
      setLoading(false);
      if (data.code === 200) {
        setItems(data.items);
      } else {
        toastError(data.message);
      }
    } catch (error) {
      toastError(error.code);
      setLoading(false);
    }
  };

  const fetchBranches = async () => {
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}/branch/restaurant/${item["id"]}`
      );
      const data = response.data;
      setLoading(false);
      if (data.code === 200) {
        setBranches(data.branches);
      } else {
        toastError(data.message);
      }
    } catch (error) {
      toastError(error.code);
      setLoading(false);
    }
  };

  const showItemsHandler = () => {
    setShowItems((prevState) => !prevState);
  };

  const apiCall = async () => {
    const formData = new FormData();
    formData.append("restaurant", item["id"]);
    formData.append("branch", formState.branch);
    formData.append("items", formState.items);
    formData.append("appear", formState.where_to_appear);
    formData.append("status", formState.status);
    formData.append("description", formState.upsell_description);
    try {
      const response = await axiosInstance.post(
        `${BASE_URL}/upsell/${userId}`,
        formData
      );
      const data = response.data;
      setLoading(false);
      if (data.code === 200) {
        toastSuccess(data.message);
        setFormState({
          items: [],
          upsell_description: "",
          where_to_appear: "",
          branch: "",
        });
        setTimeout(() => {
          navigate(-1);
        }, 1700);
      } else {
        toastError(data.message);
      }
    } catch (error) {
      toastError(error.code);
      setLoading(false);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const validate = validateUpsell(
      formState.items,
      formState.upsell_description,
      formState.where_to_appear,
      formState.branch
    );
    if (validate === null) {
      setLoading(true);
      apiCall();
    } else {
      setLoading(false);
      toastError(validate);
    }
  };

  return (
    <div>
      <div className="breadcrump">
        <Link to="/admin/restaurants" className="breadcrumb-not-active">
          Home
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link to="/admin/restaurants" className="breadcrumb-active">
          All Restaurants
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link
          onClick={(event) => event.preventDefault()}
          className="breadcrumb-active"
        >
          {company_name}
        </Link>
      </div>
      <div className="App-container-header-table-wrapper">
        <div className="App-container-header">
          <p>{company_name}</p> - <p>Add Upsell</p>
        </div>
        <div className="App-container-table">
          <div className="restaurant-table-wrapper">
            <div style={{ paddingTop: "33px" }}>
              <MiniSidebar />
            </div>
            <div className="table-container">
              <div className="App-table restaurant-app-table">
                <div className="App-container-row-body-full">
                  <div className="App-input-group-multiselect">
                    <label htmlFor="">Select Items</label>
                    <button onClick={showItemsHandler} className="show-btn">
                      {formState.items.length > 0
                        ? formState.items + ","
                        : "Select Item(s) - (Multiple)"}
                    </button>
                    {showItems && (
                      <div className="show-panel">
                        {items.length > 0 ? (
                          items.map((item) => (
                            <span key={item.id}>
                              <input
                                type="checkbox"
                                name="items"
                                id=""
                                value={item.id}
                                onChange={changeHandler}
                              />
                              <label htmlFor="">{item.name}</label>
                            </span>
                          ))
                        ) : (
                          <p>There are no items</p>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="App-input-group">
                    <label htmlFor="">Upsell Description</label>
                    <input
                      type="text"
                      id=""
                      name="upsell_description"
                      value={formState.upsell_description}
                      onChange={changeHandler}
                      placeholder="Upsell Description"
                      className="App-input-add"
                      required
                    />
                  </div>
                  <div className="App-input-group">
                    <label htmlFor="">Where To Appear</label>
                    <div className="App-input-group-select">
                      <select
                        name="where_to_appear"
                        id=""
                        onChange={changeHandler}
                      >
                        <option value="">Select Item</option>
                        {items.length > 0 &&
                          items.map((item) => (
                            <option value={item.id}>{item.name}</option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="App-input-group">
                    <label htmlFor="">Status</label>
                    <div className="App-input-group-select">
                      <select name="status" id="" onChange={changeHandler}>
                        <option value="">select status</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </select>
                    </div>
                  </div>
                  <div className="restaurant-input-group">
                    <label htmlFor="">Select branch</label>
                    <div className="App-input-group-select">
                      <select name="branch" id="" onChange={changeHandler}>
                        <option value="">Select Branch</option>
                        {branches?.map((branch) => (
                          <option value={branch.id} key={branch.id}>
                            {branch.branch_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div
                  className="card-footer-container"
                  style={{ paddingTop: "10px" }}
                >
                  {saved && (
                    <div className="saved-toast">
                      <span>
                        <CheckCircleOutline />
                      </span>
                      <span style={{ marginLeft: "10px" }}>
                        <strong>Success</strong>. You successfully added an
                        upsell.
                      </span>
                    </div>
                  )}
                  <div
                    className="App-input-group-buttons"
                    style={{ paddingTop: saved ? "" : "60px" }}
                  >
                    <div className="App-input-group-button form-app-button">
                      <div
                        className="App-button-add"
                        //   onClick={submitData}
                        onClick={submitHandler}
                      >
                        Save Details
                      </div>
                    </div>
                    <div className="App-input-group-button form-app-button">
                      <div
                        className="App-button-cancel"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer theme="colored" autoClose={1500} hideProgressBar={true} />
    </div>
  );
};

export default AddUpsell;
