import { useState, useEffect, useRef } from "react";
import Empty from "../../../Utils/Empty";
import Table from "react-bootstrap/Table";
import MiniSidebar from "./MiniSidebar";
import { AddCircleOutlineRounded, Search } from "@mui/icons-material";
import { useParams, Link, useNavigate } from "react-router-dom";
import { BASE_URL, axiosInstance, toastError } from "../../../Utils/Config";
import { CSVLink } from "react-csv";
import { useReactToPrint } from "react-to-print";
import ViewOrder from "./ViewOrder";
import DeviceReceipt from "./DeviceReceipt";
import QRCodeReceipt from "./QRCodeReceipt";

const PrintableTable = ({ orders }) => {
  return (
    <Table stripped="true" bordered responsive size="xl">
      <thead id="Table-heading-wrapper">
        <tr>
          <th id="t-heading">Date/Time</th>
          <th id="t-heading">Order #</th>
          <th id="t-heading">Order Via</th>
          <th id="t-heading">Table #/Device</th>
          <th id="t-heading">Payment mode</th>
          <th id="t-heading">Order Total</th>
          {/* Exclude "Status" and "Action" columns */}
        </tr>
      </thead>
      <tbody>
        {orders.map((order) => (
          <tr key={order.id}>
            <td>
              <p style={{ whiteSpace: "pre-line" }}>{order.createdAt}</p>
            </td>
            <td>
              <p>{order.id}</p>
            </td>
            <td>
              <p>QR Code</p>
            </td>
            <td>
              <p>number</p>
            </td>
            <td>
              <p>{order.payment["name"] === null ? "M-Pesa" : "nothing"}</p>
            </td>
            <td>
              <p>{order.items.length}</p>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const FullTable = ({ orders }) => {
  // const navigate = useNavigate();
  const receiptRef = useRef();
  const [orderReceipt, setOrderReceipt] = useState(false);
  const [qrReceipt, setQrReceipt] = useState(false);
  const [deviceReceipt, setDeviceReceipt] = useState(false);

  const formatToEAT = (timestamp) => {
    const date = new Date(timestamp);
    const options = { timeZone: "Africa/Nairobi" };
    return date.toLocaleString("en-US", options);
  };

  const orderHandler = (orderVia) => {
    if (orderVia === "QR-code") {
      setDeviceReceipt(false);
      setQrReceipt(true);
    } else if (orderVia === null) {
      setQrReceipt(false);
      setDeviceReceipt(true);
    } else {
      return;
    }
  };

  const downloadReceipt = useReactToPrint({
    content: () => receiptRef.current,
    documentTitle: "Receipt",
  });

  return (
    <>
      <Table stripped="true" bordered responsive size="xl">
        <thead id="Table-heading-wrapper">
          <tr>
            <th id="t-heading">Date/Time</th>
            <th id="t-heading">Order #</th>
            <th id="t-heading">Order Via</th>
            <th id="t-heading">Table #/Device</th>
            <th id="t-heading">Payment mode</th>
            <th id="t-heading">Order Total</th>
            <th id="t-heading">Status</th>
            <th id="t-heading">Action</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => {
            const formattedCreatedAt = formatToEAT(order.createdAt);
            return (
              <tr key={order.id}>
                <td>
                  <p style={{ whiteSpace: "pre-line" }}>{formattedCreatedAt}</p>
                </td>
                <td>
                  <p>{order.id}</p>
                </td>
                <td>
                  <p>{order.orderVia === null ? "Device" : order.orderVia}</p>
                  {/* <p>QR Code</p> */}
                </td>
                <td>
                  <p>number</p>
                </td>
                <td>
                  <p>{order.payment["name"] === null ? "M-Pesa" : "nothing"}</p>
                </td>
                <td>
                  <p>{order.items.length}</p>
                </td>
                <td>
                  <div className="company-status">
                    <p id="status" className="active">
                      Active
                    </p>
                  </div>
                </td>
                <td className="App-table-actions">
                  <div
                    className="App-table-action-button"
                    onClick={() => orderHandler(order.orderVia)}
                    style={{
                      backgroundColor: "#83b302",
                    }}
                  >
                    View Order
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {qrReceipt && (
        <div className="modal-overlay">
          <span
            className="modal-close-button"
            onClick={() => setQrReceipt(false)}
          >
            &times;
          </span>
          <QRCodeReceipt
            receiptRef={receiptRef}
          />
          <button
            className="modal-download-btn"
            onClick={() => downloadReceipt()}
          >
            Download Pdf
          </button>
        </div>
      )}
      {deviceReceipt && (
        <div className="modal-overlay">
          <span
            className="modal-close-button"
            onClick={() => setDeviceReceipt(false)}
          >
            &times;
          </span>
          <DeviceReceipt
            receiptRef={receiptRef}
          />
          <button
            className="modal-download-btn"
            onClick={() => downloadReceipt()}
          >
            Download Pdf
          </button>
        </div>
      )}
    </>
  );
};

const CsvTable = ({ orders }) => {
  const csvData = orders.map((order) => ({
    "Date/Time": order.createdAt,
    "Order #": order.id,
    "Order Via": "QR Code",
    "Table #/Device": "number",
    "Payment mode": order.payment["name"] === null ? "M-Pesa" : "nothing",
    "Order Total": order.items.length,
  }));

  return (
    <CSVLink data={csvData} filename={"orders.csv"} style={{ all: "unset" }}>
      <div className="excel-btn">Export to Excel</div>
    </CSVLink>
  );
};

const Orders = () => {
  const componentPDF = useRef();
  const navigate = useNavigate();
  const { company_name } = useParams();
  const companyString = sessionStorage.getItem("Restaurant");
  const company = JSON.parse(companyString);
  const [loading, setLoading] = useState();
  const [branches, setBranches] = useState([]);
  const [orders, setOrders] = useState([]);
  const [print, setPrint] = useState(false);
  const [branch, setBranch] = useState(null);
  const [orderReceipt, setOrderReceipt] = useState(false);

  // const viewOrder = (order) => {
  //   setOrderReceipt(prevState => !prevState)
  // };

  useEffect(() => {
    fetchBranches();
  }, []);

  useEffect(() => {
    if (branch) {
      fetchOrders();
    }
  }, [branch]);

  const fetchBranches = async () => {
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}/branch/restaurant/${company["id"]}`
      );
      const data = response.data;
      setLoading(false);
      if (data.code === 200) {
        setBranches(data.branches);
        setBranch(data.branches.length > 0 ? data.branches[0].id : null);
      } else {
        toastError(data.message);
      }
    } catch (error) {
      toastError(error.code);
      setLoading(false);
    }
  };

  const fetchOrders = async () => {
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}/orders/branch/${branch}`
      );
      const data = response.data;
      setLoading(false);
      if (data.code === 200) {
        setOrders(data.orders);
      } else {
        toastError(data.message);
      }
    } catch (error) {
      toastError(error.code);
      setLoading(false);
    }
  };

  const branchHandler = (event) => {
    setBranch(event.target.value);
  };

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "Orders",
  });

  return (
    <div>
      <div className="breadcrump">
        <Link to="/admin/restaurants" className="breadcrumb-not-active">
          Home
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link to="/admin/restaurants" className="breadcrumb-active">
          All Restaurants
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link
          onClick={(event) => event.preventDefault()}
          className="breadcrumb-active"
        >
          {company_name}
        </Link>
      </div>
      <div className="App-container-header-table-wrapper">
        <div className="App-container-header">
          <p>{company_name}</p> - <p>Orders</p>
        </div>
        <div className="App-container-table">
          <div className="orders-container-table-top-bar">
            <div className="left">
              <div>
                {branches.length > 0 && (
                  <select name="branch" id="" onChange={branchHandler}>
                    {branches.map((branch) => (
                      <option value={branch.id} key={branch.id}>
                        {branch.branch_name}
                      </option>
                    ))}
                  </select>
                )}
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label htmlFor="">From</label>
                <input type="date" name="" id="" />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label htmlFor="">To</label>
                <input type="date" name="" id="" />
              </div>
            </div>
            <div className="right">
              <div
                className="pdf-btn"
                onClick={() => {
                  setPrint(true);
                  generatePDF();
                }}
              >
                Download Pdf
              </div>
              <CsvTable orders={orders} />
            </div>
          </div>
          <div className="restaurant-table-wrapper">
            <MiniSidebar />
            <div className="table-container">
              <div className="App-table restaurants-app-table">
                {orders.length > 0 ? (
                  <FullTable
                    orders={orders}
                    // viewOrder={viewOrder}
                  />
                ) : (
                  <div className="App-empty">
                    <Empty />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <div ref={componentPDF}>
          <PrintableTable orders={orders} />
        </div>
      </div>
      <div>{orderReceipt && <ViewOrder />}</div>
    </div>
  );
};

export default Orders;
