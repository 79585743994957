import { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import MiniSidebar from "./MiniSidebar";
import { CheckCircleOutline } from "@mui/icons-material";
import {
	BASE_URL,
	IMAGE_URL,
	toastError,
	axiosInstance,
	toastSuccess,
} from "../../../Utils/Config";
import QRCode from "qrcode";
import { ToastContainer } from "react-toastify";

const AddQRCode = () => {
	const [saved, setSaved] = useState(false);
	const { company_name } = useParams();
	const navigate = useNavigate();
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [branches, setBranches] = useState([]);
	const [qrCode, setQrCode] = useState("");
	const companyString = sessionStorage.getItem("Restaurant");
	const company = JSON.parse(companyString);
	const { userId } = JSON.parse(sessionStorage.getItem("userId"));
	const [formState, setFormState] = useState({
		table_number: "",
		branch: "",
		// status: ""
	});

	useEffect(() => {
		fetchBranches();
	}, []);

	const changeHandler = (event) => {
		const { name, value } = event.target;
		setFormState((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const fetchBranches = async () => {
		try {
			const response = await axiosInstance.get(
				`${BASE_URL}/branch/restaurant/${company["id"]}`
			);
			const data = response.data;
			setLoading(false);
			if (data.code === 200) {
				setBranches(data.branches);
			} else {
				toastError(data.message);
			}
		} catch (error) {
			toastError(error.code);
			setLoading(false);
		}
	};

	const apiCall = async () => {
		const payload = {
			restaurant: company["id"],
			branch: formState.branch,
			tableNumber: formState.table_number,
		};
		try {
			const response = await axiosInstance.post(
				`${BASE_URL}/qr/${userId}`,
				payload
			);
			const data = response.data;
			console.log("Link Code: ", data);
			if (data.code === 200) {
				toastSuccess(data.message);
				setTimeout(() => {
					navigate(-1);
				}, 1700);
			} else {
				toastError(data.message);
			}
		} catch (error) {
			toastError(error.code);
		}
	};

	const generateQRCode = () => {
		const link = IMAGE_URL + formState.branch + "/" + formState.table_number;
		QRCode.toDataURL(
			link,
			{
				width: 300,
				margin: 1,
			},
			(err, url) => {
				if (err) return console.error(err);
				//console.log(url);
				setQrCode(url);
			}
		);
	};

	return (
		<div>
			<div className="breadcrump">
				<Link to="/dashboard/restaurants" className="breadcrumb-not-active">
					Home
				</Link>
				<span className="breadcrumb-arrow">&gt;</span>
				<Link to="/dashboard/restaurants" className="breadcrumb-active">
					All Restaurants
				</Link>
				<span className="breadcrumb-arrow">&gt;</span>
				<Link
					onClick={(event) => event.preventDefault()}
					className="breadcrumb-active">
					{company_name}
				</Link>
			</div>
			<div className="App-container-header-table-wrapper">
				<div className="App-container-header">
					<p>{company_name}</p> - <p>Add QR Code</p>
				</div>
				<div className="App-container-table">
					<div className="restaurant-table-wrapper">
						<div style={{ paddingTop: "33px" }}>
							<MiniSidebar />
						</div>
						<div className="table-container">
							<div className="App-table restaurant-app-table">
								<div className="App-container-row-body-full">
									<div className="App-input-group">
										<label htmlFor="">Table Number</label>
										<input
											type="text"
											id=""
											name="table_number"
											placeholder="Table Number"
											value={formState.table_number}
											onChange={changeHandler}
											className="App-input-add"
											required
										/>
									</div>
									<div className="App-input-group">
										<label htmlFor="">Branch Name</label>
										<div className="App-input-group-select">
											<select name="branch" onChange={changeHandler} id="">
												<option value="">Select Branch</option>
												{branches?.map((branch) => (
													<option value={branch.id} key={branch.id}>
														{branch.branch_name}
													</option>
												))}
											</select>
										</div>
									</div>
									{/* <div className="App-input-group">
                    <label htmlFor="">Status</label>
                    <select name="status" id="" onChange={changeHandler} >
                      <option value="">select status</option>
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </select>
                  </div> */}
								</div>

								<div
									className="card-footer-container"
									style={{ paddingTop: "40px" }}>
									{qrCode && <img src={qrCode} alt="" />}
									{saved && (
										<div className="saved-toast">
											<span>
												<CheckCircleOutline />
											</span>
											<span style={{ marginLeft: "10px" }}>
												<strong>Success</strong>. You successfully generated a
												new QR code
											</span>
										</div>
									)}

									<div className="App-input-group-buttons">
										<div className="App-input-group-button form-app-button">
											<div
												className="App-button-add"
												// onClick={generateQRCode}
												onClick={apiCall}>
												Generate code
											</div>
										</div>
										<div className="App-input-group-button form-app-button">
											<div
												className="App-button-cancel"
												onClick={() => navigate(-1)}>
												Cancel
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ToastContainer theme="colored" autoClose={1500} hideProgressBar={true} />
		</div>
	);
};

export default AddQRCode;
