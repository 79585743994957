import { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import MiniSidebar from "./MiniSidebar";
import { CheckCircleOutline, InsertPhotoOutlined } from "@mui/icons-material";
import {
  BASE_URL,
  toastError,
  toastSuccess,
  validateEditCategory,
  validateCategory,
  axiosInstance,
} from "../../../Utils/Config";
import { ToastContainer } from "react-toastify";

const AddMenuCategory = () => {
  const [loading, setLoading] = useState(false);
  const [showCategories, setShowCategories] = useState(false);
  const [branches, setBranches] = useState(null);
  const [saved, setSaved] = useState(false);
  const { company_name } = useParams();
  const navigate = useNavigate();
  const [photo, setPhoto] = useState(null);
  const [formState, setFormState] = useState({
    name: "",
    image: null,
    status: "",
    branch: [],
  });
  const itemString = sessionStorage.getItem("Restaurant");
  const item = JSON.parse(itemString);
  const {userId} = JSON.parse(sessionStorage.getItem("userId"))

  useEffect(() => {
    fetchBranches();
  }, []);

  const apiCall = async () => {
    const formData = new FormData();
    formData.append("name", formState.name);
    formData.append("status", formState.status);
    formData.append("branch", formState.branch);
    formData.append("restaurant", item["id"]);

    const fileInput = document.querySelector('input[type="file"]');
    const file = fileInput?.files[0];
    if (file) {
      formData.append("image", file);
    }

    try {
      const response = await axiosInstance.post(`${BASE_URL}/category/${userId}`, formData);
      const data = response.data;
      setLoading(false);
      if (data.code === 200) {
        toastSuccess(data.message);
        setFormState({
          name: "",
          image: null,
          status: "",
          branch: [],
        });
        setTimeout(() => {
          navigate(-1);
        }, 1700);
      } else {
        toastError(data.message);
      }
    } catch (error) {
      toastError(error.code);
      setLoading(false);
    }
  };

  const fetchBranches = async () => {
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}/branch/restaurant/${item["id"]}`
      );
      const data = response.data;
      setLoading(false);
      if (data.code === 200) {
        setBranches(data.branches);
      } else {
        toastError(data.message);
      }
    } catch (error) {
      toastError(error.code);
      setLoading(false);
    }
  };

  const showCategoryHandler = () => {
    setShowCategories((prevState) => !prevState);
  };

  const changeHandler = (event) => {
    const { name, type, files } = event.target;

    if (type === "checkbox") {
      // const branchId = event.target.value;
      // if (formState.branch.includes(branchId)) {
      //   const updatedBranchIds = formState.branch.filter(
      //     (id) => id !== branchId
      //   );
      //   setFormState((prevState) => ({
      //     ...prevState,
      //     branch: updatedBranchIds,
      //   }));
      // } else {
      //   setFormState((prevState) => ({
      //     ...prevState,
      //     branch: [...prevState.branch, branchId],
      //   }));
      // }
      const branchId = parseInt(event.target.value, 10); // Parse as integer with base 10
      if (!isNaN(branchId)) {
        // Check if the parsing was successful and branchId is a valid integer
        if (formState.branch.includes(branchId)) {
          const updatedBranchIds = formState.branch.filter(
            (id) => id !== branchId
          );
          setFormState((prevState) => ({
            ...prevState,
            branch: updatedBranchIds,
          }));
        } else {
          setFormState((prevState) => ({
            ...prevState,
            branch: [...prevState.branch, branchId],
          }));
        }
      }
    } else if (type === "file") {
      const file = event.target.files[0];
      setPhoto(URL.createObjectURL(files[0]));
      setFormState((prevState) => ({
        ...prevState,
        image: file, // Store the file itself
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [name]: event.target.value,
      }));
    }
  };

  const submitData = (e) => {
    e.preventDefault();
    console.log(formState);
    const validate = validateCategory(
      formState.name,
      formState.status,
      formState.image
    );
    if (validate === null) {
      apiCall();
    } else {
      toastError(validate);
    }
  };

  return (
    <div>
      <div className="breadcrump">
        <Link to="/admin/restaurants" className="breadcrumb-not-active">
          Home
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link to="/admin/restaurants" className="breadcrumb-active">
          All Restaurants
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link
          onClick={(event) => event.preventDefault()}
          className="breadcrumb-active"
        >
          {company_name}
        </Link>
      </div>
      <div className="App-container-header-table-wrapper">
        <div className="App-container-header">
          <p>{company_name}</p> - <p>Add Menu Category</p>
        </div>
        <div className="App-container-table">
          <div className="restaurant-table-wrapper">
            <div style={{ paddingTop: "33px" }}>
              <MiniSidebar />
            </div>
            <div className="table-container">
              <div className="App-table restaurant-app-table">
                <div className="App-container-row-body-full">
                  <div className="App-input-group">
                    <label htmlFor="">Category Name</label>
                    <input
                      type="text"
                      id=""
                      name="name"
                      onChange={changeHandler}
                      placeholder="Category Name"
                      className="App-input-add"
                      required
                    />
                  </div>
                  <div className="App-input-group-multiselect">
                    <label htmlFor="">Select Branch(s)</label>
                    <button onClick={showCategoryHandler} className="show-btn">
                      Select Branch - Multiple/All
                    </button>
                    {showCategories && (
                      <div className="show-panel">
                        {branches ? (
                          branches.map((branch) => (
                            <span key={branch.id}>
                              <input
                                type="checkbox"
                                name="branch"
                                id=""
                                value={branch.id}
                                onChange={changeHandler}
                              />
                              <label htmlFor="">{branch.branch_name}</label>
                            </span>
                          ))
                        ) : (
                          <p>There is no active branches</p>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="App-input-group">
                    <label htmlFor="">Status</label>
                    <div className="App-input-group-select">
                    <select name="status" id="" onChange={changeHandler}>
                      <option value="">select status</option>
                      <option value="1">Active</option>
                      <option value="0">Inactive</option>
                    </select>
                    </div>
                  </div>
                  <div className="App-input-group">
                    <label htmlFor="">Upload Picture</label>
                    {/* <div
                      className="App-input-group-image"
                      // style={{height:"300px"}}
                    >
                      <input
                        type="file"
                        name=""
                        id=""
                        onChange={changeHandler}
                      />
                      <div className="group-image-container">
                        <InsertPhotoOutlined
                          style={{
                            fontSize: "80px",
                            color: "rgba(0, 0, 0, 0.5)",
                          }}
                        />
                      </div>
                    </div> */}
                    <div
                      className="image-container"
                      onClick={() =>
                        document.querySelector(".input-field").click()
                      }
                    >
                      <input
                        type="file"
                        name=""
                        id=""
                        className="input-field"
                        accept="image/*"
                        hidden
                        // onChange={({ target: { files } }) => {
                        //   files[0] && setFileName(files[0].name);
                        //   if (files) {
                        //     setFormState((prevState) => ({
                        //       ...prevState,
                        //       photo: URL.createObjectURL(files[0]),
                        //     }));
                        //   }
                        // }}
                        onChange={changeHandler}
                      />
                      {formState.image ? (
                        <img src={photo} alt={photo} width={80} height={80} />
                      ) : (
                        <InsertPhotoOutlined
                          style={{
                            fontSize: "80px",
                            color: "rgba(0, 0, 0, 0.5)",
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="card-footer-container"
                  style={{ paddingTop: "10px" }}
                >
                  {saved && (
                    <div className="saved-toast">
                      <span>
                        <CheckCircleOutline />
                      </span>
                      <span style={{ marginLeft: "10px" }}>
                        <strong>Success</strong>. You successfully added a new
                        menu category.
                      </span>
                    </div>
                  )}
                  <div className="App-input-group-buttons">
                    <div className="App-input-group-button form-app-button">
                      <div className="App-button-add" onClick={submitData}>
                        Save Details
                      </div>
                    </div>
                    <div className="App-input-group-button form-app-button">
                      <div
                        className="App-button-cancel"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer theme="colored" autoClose={1500} hideProgressBar={true} />
    </div>
  );
};

export default AddMenuCategory;
