import { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import MiniSidebar from "./MiniSidebar";
import { CheckCircleOutline } from "@mui/icons-material";
import {
  BASE_URL,
  axiosInstance,
  toastError,
  toastSuccess,
} from "../../../Utils/Config";
import { ToastContainer } from "react-toastify";

const AddDevice = () => {
  const [saved, setSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [branches, setBranches] = useState([]);
  const { company_name } = useParams();
  const navigate = useNavigate();
  const [formState, setFormState] = useState({
    branch: "",
    restaurant: "",
    address: "",
    status: "",
  });
  const itemString = sessionStorage.getItem("Restaurant");
  const item = JSON.parse(itemString);
  const {userId} = JSON.parse(sessionStorage.getItem("userId"))

  useEffect(() => {
    fetchBranches();
  }, []);

  const changeHandler = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const fetchBranches = async () => {
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}/branch/restaurant/${item["id"]}`
      );
      const data = response.data;
      setLoading(false);
      if (data.code === 200) {
        setBranches(data.branches);
      } else {
        toastError(data.message);
      }
    } catch (error) {
      toastError(error.code);
      setLoading(false);
    }
  };

  const apiCall = async () => {
    const payload = {
      branch: formState.branch,
      restaurant: item["id"],
      address: formState.address,
      status: formState.status,
    };
    try {
      const response = await axiosInstance.post(
        `${BASE_URL}/device/${userId}`, payload
      );
      const data = response.data;
      setLoading(false);
      if (data.code === 200) {
        toastSuccess(data.message);
        setFormState({
          branch: "",
          restaurant: "",
          address: "",
          status: "",
        });
        setTimeout(() => {
          navigate(-1);
        }, 1700);
      } else{
        toastError(data.message)
      }
    } catch (error) {
      toastError(error.code)
      setLoading(false);
    }
    
  };

  const submitHandler = (e) => {
    e.preventDefault();
    apiCall();
  };

  return (
    <div>
      <div className="breadcrump">
        <Link to="/admin/restaurants" className="breadcrumb-not-active">
          Home
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link to="/admin/restaurants" className="breadcrumb-active">
          All Restaurants
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link
          onClick={(event) => event.preventDefault()}
          className="breadcrumb-active"
        >
          {company_name}
        </Link>
      </div>
      <div className="App-container-header-table-wrapper">
        <div className="App-container-header">
          <p>{company_name}</p> - <p>Add Device</p>
        </div>
        <div className="App-container-table">
          <div className="restaurant-table-wrapper">
            <div style={{ paddingTop: "33px" }}>
              <MiniSidebar />
            </div>
            <div className="table-container">
              <div className="App-table restaurant-app-table">
                <div className="App-container-row-body-full">
                  <div className="App-input-group">
                    <label htmlFor="">Branch Name</label>
                    <div className="App-input-group-select">
                      <select name="branch" id="" onChange={changeHandler}>
                        <option value="">Select Branch</option>
                        {branches?.map((branch) => (
                          <option value={branch.id} key={branch.id}>
                            {branch.branch_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="App-input-group">
                    <label htmlFor="">Enter Device URL Address</label>
                    <input
                      type="text"
                      id=""
                      name="address"
                      value={formState.address}
                      onChange={changeHandler}
                      placeholder="URL Address"
                      className="App-input-add"
                      required
                    />
                  </div>
                  <div className="App-input-group">
                    <label htmlFor="">Status</label>
                    <div className="App-input-group-select">
                      <select name="status" id="" onChange={changeHandler}>
                        <option value="">Select status</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div
                  className="card-footer-container"
                >
                  {saved && (
                    <div className="saved-toast">
                      <span>
                        <CheckCircleOutline />
                      </span>
                      <span style={{ marginLeft: "10px" }}>
                        <strong>Success</strong>. You successfully added a new
                        branch.
                      </span>
                    </div>
                  )}
                  <div className="App-input-group-buttons">
                    <div className="App-input-group-button form-app-button">
                      <div className="App-button-add" onClick={submitHandler}>
                        Save Details
                      </div>
                    </div>
                    <div className="App-input-group-button form-app-button">
                      <div
                        className="App-button-cancel"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer theme="colored" autoClose={1500} hideProgressBar={true} />
    </div>
  );
};

export default AddDevice;
