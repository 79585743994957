import React from 'react'
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
    {
      name: '2020',
      pv: 24,
    },
    {
      name: '2021',
      pv: 13,
    },
    {
      name: '2022',
      pv: 9,
    },
    {
      name: '2023',
      pv: 3,
    },
  ];


const YearlyOrders = () => {
  return (
    <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={100}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          {/* <CartesianGrid strokeDasharray="3" /> */}
          <XAxis dataKey="name" height={50} label={{ value: 'Year', position: 'insideBottom' }} />
          <YAxis label={{ value: 'Order Count', angle: -90, position: 'insideLeft' }} />
          <Tooltip />
          {/* <Legend /> */}
          <Bar dataKey="pv" fill="#8884d8" activeBar={<Rectangle fill="pink" stroke="blue" />} />
        </BarChart>
      </ResponsiveContainer>
  )
}

export default YearlyOrders