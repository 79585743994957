import {useState, useEffect} from 'react'
import {
    ComposedChart,
    Line,
    Bar,
    Cell,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
  } from "recharts";
  


const ConversionTotalSales = () => {
  const [chartWidth, setChartWidth] = useState(800);
  const [chartHeight, setChartHeight] = useState(500);

  const updateDimensions = () => {
    const newWidth = window.innerWidth * 0.8; 
    const newHeight = window.innerHeight * 0.6; 

    setChartWidth(newWidth);
    setChartHeight(newHeight);
  };

  useEffect(() => {
    // Set initial dimensions
    updateDimensions();

    // Attach event listener to update dimensions on window resize
    window.addEventListener('resize', updateDimensions);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, [window.innerWidth]);

  const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'red', 'pink', 'black'];

  const data = [
    {
        name: "Tuesday Special",
        sales: 1400,
        pv: 680,
        amt: 1700,
        cnt: 380,
      },
    {
      name: "Discount 25%",
      sales: 590,
      pv: 800,
      amt: 1400,
      cnt: 490,
    },
    {
      name: "Lunch Special",
      sales: 868,
      pv: 967,
      amt: 1506,
      cnt: 590,
    },
    {
      name: "Happy Hour",
      sales: 1397,
      pv: 1098,
      amt: 989,
      cnt: 350,
    },
    {
      name: "Discount 25%",
      sales: 1200, 
      pv: 1000,
      amt: 1218,
      cnt: 480,
    },
    {
      name: "Lunch Special",
      sales: 1520,
      pv: 1108,
      amt: 1100,
      cnt: 460,
    },
    {
      name: "Happy Hour",
      sales: 1400,
      pv: 680,
      amt: 1700,
      cnt: 380,
    },
  ];

  return (
    <ComposedChart
      width={chartWidth}
      height={680}
      data={data}
      margin={{
        top: 20,
        right: 20,
        bottom: 20,
        left: 20,
      }}
    >
      <CartesianGrid stroke="#f5f5f5" />
      <XAxis dataKey="name" height={150} dy={60} angle={-90} label={{ value: 'Promotion Name', position: 'insideBottom' }}/>
      <YAxis label={{ value: 'Sales (ksh)', angle: -90, position: 'insideLeft' }} />
      <Tooltip />
      <Bar dataKey="sales" barSize={50} fill="#413ea0" >
      {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={colors[index % 20]} />
        ))}
      </Bar>
      <Line type="monotone" dataKey="sales" stroke="#ff7300" />
    </ComposedChart>
  )
}

export default ConversionTotalSales