import React, { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SideBar from "./Components/SideBar";
import ReportsTopBar from "./Components/ReportsTopBar";
import Table from "react-bootstrap/Table";
import ChartFooter from "./Components/ChartFooter";
import { useReactToPrint } from "react-to-print";
import { BASE_URL, axiosInstance } from "../../../Utils/Config";

const CustomerOrderReport = () => {
  const componentPDF = useRef();
  const location = useLocation();
  const data_state = location.state.data;
  const [details, setDetails] = useState({
    restaurant_name: "",
    branch_name: "",
    data_generated: "",
  });

  useEffect(() => {
    fetchBranch();
  }, []);

  const fetchBranch = async () => {
    const response = await axiosInstance.get(
      `${BASE_URL}/branch/${data_state["branch_id"]}`
    );
    const data = response.data;
    if (data.code === 200) {
      setDetails((prevDetails) => ({
        ...prevDetails,
        restaurant_name: data.branch.company_name,
        branch_name: data.branch.branch_name,
      }));
    }
  };

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "Daily Analysis",
  });

  return (
    <div className="App-body-restaurants">
      <div className="App-main-container">
        <SideBar />
        <div className="App-container-restaurants">
          <ReportsTopBar 
            title="Customer Orders Report" 
            restaurant={details.restaurant_name}
            branch={details.branch_name}
            period={`${data_state["from_time"]} - ${data_state["to_time"]}`}
          />
          <div className="table-container" style={{ marginTop: "30px" }}>
            {/* <div
              className="App-table restaurants-app-table"
              style={{ padding: 0, maxHeight: "60vh", border: '1px solid red' }}
            > */}
              <Table
                stripped="true"
                bordered
                responsive
                size="xl"
                style={{ width: "100%", overflowX: "auto" }}
              >
                <thead id="Table-heading-wrapper">
                  <tr>
                    <th id="t-heading">Order Date/Time</th>
                    <th id="t-heading">Order #</th>
                    <th id="t-heading">Order Via</th>
                    <th id="t-heading">Payment mode</th>
                    <th id="t-heading">Table #/Device</th>
                    <th id="t-heading">Order Total</th>
                    <th id="t-heading">Reference #</th>
                    <th id="t-heading">Customer Phone</th>
                    <th id="t-heading">Order Status</th>
                    <th id="t-heading">Order Details</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td id="t-body"></td>
                    <td id="t-body"></td>
                    <td id="t-body"></td>
                    <td id="t-body"></td>
                    <td id="t-body"></td>
                    <td id="t-body"></td>
                    <td id="t-body"></td>
                    <td id="t-body"></td>
                    <td id="t-body-status">
                      <div className="company-status">
                        <p id="status" className="active">
                          Active
                        </p>
                      </div>
                    </td>
                    <td id="t-body"></td>
                  </tr>
                  <tr>
                    <td id="t-body"></td>
                    <td id="t-body"></td>
                    <td id="t-body"></td>
                    <td id="t-body"></td>
                    <td id="t-body"></td>
                    <td id="t-body"></td>
                    <td id="t-body"></td>
                    <td id="t-body"></td>
                    <td id="t-body-status">
                      <div className="company-status">
                        <p id="status" className="active">
                          Active
                        </p>
                      </div>
                    </td>
                    <td id="t-body"></td>
                  </tr>
                  <tr>
                    <td id="t-body"></td>
                    <td id="t-body"></td>
                    <td id="t-body"></td>
                    <td id="t-body"></td>
                    <td id="t-body"></td>
                    <td id="t-body"></td>
                    <td id="t-body"></td>
                    <td id="t-body"></td>
                    <td id="t-body-status">
                      <div className="company-status">
                        <p id="status" className="active">
                          Active
                        </p>
                      </div>
                    </td>
                    <td id="t-body"></td>
                  </tr>
                  <tr>
                    <td id="t-body"></td>
                    <td id="t-body"></td>
                    <td id="t-body"></td>
                    <td id="t-body"></td>
                    <td id="t-body"></td>
                    <td id="t-body"></td>
                    <td id="t-body"></td>
                    <td id="t-body"></td>
                    <td id="t-body-status">
                      <div className="company-status">
                        <p id="status" className="active">
                          Active
                        </p>
                      </div>
                    </td>
                    <td id="t-body"></td>
                  </tr>
                  <tr>
                    <td id="t-body"></td>
                    <td id="t-body"></td>
                    <td id="t-body"></td>
                    <td id="t-body"></td>
                    <td id="t-body"></td>
                    <td id="t-body"></td>
                    <td id="t-body"></td>
                    <td id="t-body"></td>
                    <td id="t-body-status">
                      <div className="company-status">
                        <p id="status" className="active">
                          Active
                        </p>
                      </div>
                    </td>
                    <td id="t-body"></td>
                  </tr>
                </tbody>
              </Table>
            {/* </div> */}
          </div>
          <ChartFooter />
          <div className="footer-btns">
            <button className="pdf-btn" onClick={() => generatePDF()}>
              Download PDF
            </button>
          </div>
          <div style={{display: "none"}}>
            <div ref={componentPDF}>
              <PrintableTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PrintableTable = () => {
  return (
    <div>
      <Table
        stripped="true"
        bordered
        responsive
        size="xl"
        style={{ width: "100%", overflowX: "auto" }}
      >
        <thead id="Table-heading-wrapper">
          <tr>
            <th id="t-heading">Order Date/Time</th>
            <th id="t-heading">Order #</th>
            <th id="t-heading">Order Via</th>
            <th id="t-heading">Payment mode</th>
            <th id="t-heading">Table #/Device</th>
            <th id="t-heading">Order Total</th>
            <th id="t-heading">Reference #</th>
            <th id="t-heading">Customer Phone</th>
            {/* <th id="t-heading">Order Details</th> */}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ height: "50px", width: "50px" }}></td>
            <td style={{ height: "50px", width: "50px" }}></td>
            <td style={{ height: "50px", width: "50px" }}></td>
            <td style={{ height: "50px", width: "50px" }}></td>
            <td style={{ height: "50px", width: "50px" }}></td>
            <td style={{ height: "50px", width: "50px" }}></td>
            <td style={{ height: "50px", width: "50px" }}></td>
            <td style={{ height: "50px", width: "50px" }}></td>
            {/* <td style={{height: "50px", width: "50px"}}></td> */}
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default CustomerOrderReport;
