import { useEffect, useState } from "react";
import { useLocation, useParams, Link, useNavigate } from "react-router-dom";
import MiniSidebar from "./MiniSidebar";
import { CheckCircleOutline, Edit } from "@mui/icons-material";
import { ToastContainer } from "react-toastify";
import {
  BASE_URL,
  axiosInstance,
  toastError,
  toastSuccess,
  validateBranch,
} from "../../../Utils/Config";
import Loader from "../../../Utils/Loader";

const PaymentBranch = () => {
  const { company_name, branch_id } = useParams();
  const {userId} = JSON.parse(sessionStorage.getItem("userId"))
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [method, setMethod] = useState("");
  const [saved, setSaved] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPaymentMethods();
  }, []);

  const fetchPaymentMethods = async () => {
    try {
      const response = await axiosInstance.get(`${BASE_URL}/payment_method`);
      const data = response.data;
      if (data.code === 200) {
        setPaymentMethods(data.paymentMethods);
      } else {
        toastError(data.message);
      }
    } catch (error) {
      toastError(error.code);
    }
  };

  const apiCall = async () => {
    const payload = {
      paymentMethod: method,
      branch: branch_id,
    };
    try {
      const response = await axiosInstance.post(`${BASE_URL}/payment/${userId}`, payload);
      const data = response.data;
      if (data.code === 200) {
        toastSuccess(data.message);
        setTimeout(() => {
          navigate(-1);
        }, 1700);
      }
    } catch (error) {
      toastError(error.code);
    }
  };

  return (
    <div>
      <div className="breadcrump">
        <Link to="/admin/restaurants" className="breadcrumb-not-active">
          Home
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link to="/admin/restaurants" className="breadcrumb-active">
          All Restaurants
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link
          onClick={(event) => event.preventDefault()}
          className="breadcrumb-active"
        >
          {company_name}
        </Link>
      </div>
      <div className="App-container-header-table-wrapper">
        <div className="App-container-header">
          <p>{company_name}</p> - <p>Edit Branch</p>
        </div>
        <div className="App-container-table">
          <div className="restaurant-table-wrapper">
            <div style={{ marginTop: "33px" }}>
              <MiniSidebar />
            </div>
            <div className="table-container">
              <div className="App-table restaurant-app-table">
                <div className="App-container-row-body-full">
                  <div className="App-input-group">
                    <label htmlFor="">Choose a payment method</label>
                    <div className="App-input-group-select">
                      <select
                        name="method"
                        id=""
                        onChange={(e) => setMethod(e.target.value)}
                      >
                        <option value="">select method</option>
                        {paymentMethods?.map(
                          (payment) =>
                            payment.name !== null && (
                              <option key={payment.id} value={payment.id}>
                                {payment.name}
                              </option>
                            )
                        )}
                      </select>
                    </div>
                  </div>
                </div>
                <div
                  className="card-footer-container"
                  style={{ paddingTop: "10px" }}
                >
                  {saved && (
                    <div className="saved-toast">
                      <span>
                        <CheckCircleOutline />
                      </span>
                      <span style={{ marginLeft: "10px" }}>
                        <strong>Success</strong>. You successfully added a new
                        menu category.
                      </span>
                    </div>
                  )}
                  <div
                    className="App-input-group-buttons"
                    style={{ paddingTop: "50px" }}
                  >
                    <div className="App-input-group-button form-app-button">
                      <div className="App-button-add" onClick={apiCall}>
                        Save Details
                      </div>
                    </div>
                    <div className="App-input-group-button form-app-button">
                      <div
                        className="App-button-cancel"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer theme="colored" autoClose={1500} hideProgressBar={true} />
    </div>
  );
};

export default PaymentBranch;
