import React from 'react'
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend
  } from "recharts";
  
  const data = [
    {
      name: "08:00",
      total: 240,
    },
    {
      name: "09:00",
      total: 250,
    },
    {
      name: "10:00",
      total: 130,
    },
    {
      name: "11:00",
      total: 210,
    },
    {
      name: "12:00",
      total: 120,
    },
    {
      name: "13:00",
      total: 134,
    },
    {
      name: "14:00",
      total: 200,
    },
    {
      name: "15:00",
      total: 279,
    },
    {
      name: "16:00",
      total: 280,
    },
    {
      name: "17:00",
      total: 260,
    },
    {
      name: "18:00",
      total: 240,
    },
    {
      name: "19:00",
      total: 211,
    },
    {
      name: "20:00",
      total: 150,
    },
    {
      name: "21:00",
      total: 170,
    },
    {
      name: "22:00",
      total: 230,
    },
    {
      name: "23:00",
      total: 120,
    },
    {
      name: "00:00",
      total: 85,
    },
    {
      name: "01:00",
      total: 190,
    },
    {
      name: "02:00",
      total: 290,
    },
    {
      name: "03:00",
      total: 200,
    },
    {
      name: "04:00",
      total: 220,
    },
    {
      name: "05:00",
      total: 230,
    },
    {
      name: "06:00",
      total: 200,
    },
    {
      name: "07:00",
      total: 205,
    },
  ];

const OrderByHour = () => {
  return (
    <BarChart
      width={1000}
      height={300}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5
      }}
    >
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      {/* <Legend /> */}
      <Bar dataKey="total" fill="#8884d8" />
    </BarChart>
  )
}

export default OrderByHour