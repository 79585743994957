import { CheckCircleOutline, Edit, InsertPhotoOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import logo from "../../../Images/logo.webp";
import { ToastContainer } from "react-toastify";
import Loader from "../../../Utils/Loader";
import { useNavigate, useSearchParams, Link, useParams, useLocation } from "react-router-dom";
import {
  BASE_URL,
  IMAGE_URL,
  toastError,
  toastSuccess,
  validateData,
  validate,
  ID,
  TOKEN,
  axiosInstance,
} from "../../../Utils/Config";
import Header from "./Components/Header";
import SideBar from "./Components/SideBar";
import QuickLinks from "./Components/QuickLinks";

function EditRestaurant() {
  document.title = "Edit Restaurant";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);
  const [logo, setLogo] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [searchParam] = useSearchParams();
  const restaurant_id = searchParam.get("id");
  const { id } = useParams()
  const location = useLocation()
  const item = location.state.item
  const {userId} = JSON.parse(sessionStorage.getItem("userId"))
  const [formState, setFormState] = useState({
    company_name: item['company_name'],
    telephone: item['telephone'],
    contact_name: item['contact_name'],
    email_address: item['email_address'],
    physical_address: item['physical_address'],
    // restaurant_url: item['restaurant_url'],
    status: item['status'],
    pin_number: item['pin'],
    box: item['box'],
    image: IMAGE_URL + item['logo']
  });

  const changeHandler = (event) => {
    const { name, value, type, files } = event.target;
    if (type === "file") {
      const file = event.target.files[0];
      setLogo(URL.createObjectURL(files[0]));
      setFormState((prevState) => ({
        ...prevState,
        image: file,
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const apiCall = async () => {
    const formData = new FormData();
    formData.append("company_name", formState.company_name);
    formData.append("telephone", formState.telephone);
    formData.append("contact_name", formState.contact_name);
    formData.append("email_address", formState.email_address);
    formData.append("physical_address", formState.physical_address);
    formData.append("status", formState.status);
    formData.append("pin", formState.pin_number);
    formData.append("box", formState.box);
    const fileInput = document.querySelector('input[type="file"]');
    const file = fileInput?.files[0];
    if (file) {
      formData.append("logo", file);
    }
    try {
      const response = await axiosInstance.put(`${BASE_URL}/restaurant/${id}/${userId}`, formData)
      const data = response.data
      if (data.code === 200) {
        toastSuccess(data.message)
        setFormState({
          company_name: "",
          telephone: "",
          contact_name: "",
          email_address: "",
          physical_address: "",
          // restaurant_url: "",
          status: "",
          pin_number: "",
          box: "",
          image: "",
        })
        setTimeout(() => {
          navigate(-1);
        }, 1700);
      } else {
        toastError(data.message)
      }
    } catch (error) {
      setLoading(false)
      toastError(error.code)
    }

  }

  const submitData = (event) => {
    event.preventDefault();
    const validate = validateData(
      formState.company_name,
      formState.telephone,
      formState.contact_name,
      formState.email_address,
      formState.physical_address,
      // formState.restaurant_url,
      formState.status
    );
    if (validate === null) {
      setLoading(true);
      apiCall();
    } else {
      setLoading(false)
      toastError(validate);
    }
  };

  return (
    <div>
      <div className="breadcrump">
        <Link to="/admin/restaurants" className="breadcrumb-not-active">
          Home
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link
          onClick={() => navigate(-1)}
          className="breadcrumb-not-active"
        >
          All Restaurants
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link
          onClick={(event) => event.preventDefault()}
          className="breadcrumb-active"
        >
          Edit Restaurant
        </Link>
      </div>

      <div className="App-container-header-table-wrapper">
        <div className="App-container-header">
          <p>Edit Restaurant</p>
        </div>
        <div className="App-container-table">
          <div className="App-table edit-app-table">
            {loading === true ? (
              <div className="App-loader">
                <Loader />
              </div>
            ) : (
              <></>
            )}
            <div className="App-container-row-body">
              <div className="App-input-group">
                <label htmlFor="">Company Name</label>
                <div className="input-container">
                  <input
                    type="text"
                    id=""
                    name="company_name"
                    placeholder="Company Name"
                    value={formState.company_name}
                    onChange={changeHandler}
                    className="App-input-add"
                    required
                  />
                  <span className="input-group-icon">
                    <Edit />
                  </span>
                </div>
              </div>
              <div className="App-input-group">
                <label htmlFor="">Email</label>
                <div className="input-container">
                  <input
                    type="email"
                    id=""
                    name="email_address"
                    placeholder="Email"
                    value={formState.email_address}
                    onChange={changeHandler}
                    className="App-input-add"
                    required
                  />
                  <span className="input-group-icon">
                    <Edit />
                  </span>
                </div>
              </div>
              <div className="App-input-group">
                <label htmlFor="">Telephone</label>
                <div className="input-container">
                  <input
                    type="tel"
                    id=""
                    name="telephone"
                    placeholder="Telephone"
                    value={formState.telephone}
                    onChange={changeHandler}
                    className="App-input-add"
                    required
                  />
                  <span className="input-group-icon">
                    <Edit />
                  </span>
                </div>
              </div>
              <div className="App-input-group">
                <label htmlFor="">Physical Address/Location</label>
                <div className="input-container">
                  <input
                    type="text"
                    id=""
                    name="physical_address"
                    placeholder="Physical Address/Location"
                    value={formState.physical_address}
                    onChange={changeHandler}
                    className="App-input-add"
                    required
                  />
                  <span className="input-group-icon">
                    <Edit />
                  </span>
                </div>
              </div>
              <div className="App-input-group">
                <label htmlFor="">Contact Person</label>
                <div className="input-container">
                  <input
                    type="text"
                    id=""
                    name="contact_name"
                    placeholder="Contact Person"
                    value={formState.contact_name}
                    onChange={changeHandler}
                    className="App-input-add"
                    required
                  />
                  <span className="input-group-icon">
                    <Edit />
                  </span>
                </div>
              </div>
              <div className="App-input-group">
                <label htmlFor="">Status</label>
                <select name="status" id="" value={formState.status}
                  onChange={changeHandler} >
                  <option value="">select status</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>
              <div className="App-input-group">
                <label htmlFor="">Pin number</label>
                <input
                  type="text"
                  id=""
                  name="pin_number"
                  placeholder="Pin Number"
                  value={formState.pin_number}
                  onChange={changeHandler}
                  className="App-input-add"
                  required
                />
              </div>
              <div className="App-input-group">
                <label htmlFor="">P.O Box</label>
                <input
                  type="text"
                  id=""
                  name="box"
                  placeholder="P.O Box"
                  value={formState.box}
                  onChange={changeHandler}
                  className="App-input-add"
                  required
                />
              </div>
              <div className="App-input-group">
                <label htmlFor="">Upload Picture</label>
                <div
                  className="image-container"
                  onClick={() =>
                    document.querySelector(".input-field").click()
                  }
                >
                  <input
                    type="file"
                    name=""
                    id=""
                    className="input-field"
                    accept="image/*"
                    hidden
                    onChange={changeHandler}
                  />
                  {formState.image ? (
                    <img src={formState.image} alt={photo} width={80} height={80} />
                  ) : (
                    <InsertPhotoOutlined
                      style={{
                        fontSize: "80px",
                        color: "rgba(0, 0, 0, 0.5)",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="card-footer-container">
              {saved && (
                <div className="saved-toast">
                  <span>
                    <CheckCircleOutline />
                  </span>
                  <span style={{ marginLeft: "10px" }}>
                    <strong>Success</strong>. You Successfully edited
                    restaurant.
                  </span>
                </div>
              )}
              <div className="App-input-group-buttons">
                <div className="App-input-group-button form-app-button">
                  <div className="App-button-add" onClick={submitData}>
                    Save Details
                  </div>
                </div>
                <div className="App-input-group-button form-app-button">
                  <div
                    className="App-button-cancel"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <ToastContainer theme="colored" autoClose={1500} hideProgressBar={true} />
    </div>
  );
}

export default EditRestaurant;
