import React from "react";

const ViewOrder = () => {
  return (
    <div className="view-orders-wrapper">
      <div className="code-order-wrapper">
        <div className="order-wrapper-header">
          <p>qr code</p>
        </div>
        <div className="code-order-container">
          <div className="code-order-address">
            <div className="address-header">
              <p>pronto restaurant</p>
            </div>
            <div className="address-body">
              <p>Kilimani Branch</p>
              <p>p.o box 13688 nairobi, Kenya</p>
              <p>tel: 0719517888</p>
              <p>PIN No: P09556KLS</p>
            </div>
          </div>
          <div className="code-order-type">
            <span>** </span>
            <span>Take away</span>
            <span> **</span>
          </div>
          <div className="order-details">
            <div className="order-no">
              <span className="receipt-labels">Order #: </span>
              <span>12345</span>
            </div>
            <div className="bill-no">
              <span className="receipt-labels">Bill #: </span>
              <span>4567</span>
            </div>
            <div className="table-no">
              <span className="receipt-labels">Table #: </span>
              <span>3</span>
            </div>
            <div className="order-date">
              <p>21-April-23 09:54:32 AM</p>
            </div>
          </div>
          <div className="items-content-wrapper">
            <table
              style={{ width: "100%", marginTop: "18px" }}
              className="orders-table"
            >
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>QTY:</th>
                  <th>ITEM:</th>
                  <th>PRICE:</th>
                  <th>TOTAL:</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ textAlign: "center" }}>
                    <p>2</p>
                  </td>
                  <td>
                    <p>Veggie Fillet Bugger</p>
                  </td>
                  <td>
                    <p>500</p>
                  </td>
                  <td>
                    <p>1000</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: "center" }}>
                    <p>2</p>
                  </td>
                  <td>
                    <p>Veggie Fillet Bugger</p>
                  </td>
                  <td>
                    <p>500</p>
                  </td>
                  <td>
                    <p>1000</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: "center" }}>
                    <p>2</p>
                  </td>
                  <td>
                    <p>Veggie Fillet Bugger</p>
                  </td>
                  <td>
                    <p>500</p>
                  </td>
                  <td>
                    <p>1000</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: "center" }}>
                    <p>2</p>
                  </td>
                  <td>
                    <p>Veggie Fillet Bugger</p>
                  </td>
                  <td>
                    <p>500</p>
                  </td>
                  <td>
                    <p>1000</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="order-totals">
              <p>Total: KES 2,500.00</p>
            </div>
            <div className="transaction-details">
              <span id="transaction-id">MPESA</span>
              <span>-2,500.00</span>
            </div>
            <div id="transaction-footer">** Thank you for your support **</div>
          </div>
        </div>
      </div>
      <div className="device-order-wrapper">
        <div className="order-wrapper-header">
          <p>device</p>
        </div>
        <div className="device-order-container">
          <div className="code-order-address">
            <div className="address-header">
              <p>pronto restaurant</p>
            </div>
            <div className="address-body">
              <p>Kilimani Branch</p>
              <p>p.o box 13688 nairobi, Kenya</p>
              <p>tel: 0719517888</p>
              <p>PIN No: P09556KLS</p>
            </div>
          </div>
          <div className="code-order-type">
            <span>** </span>
            <span>Take away</span>
            <span> **</span>
          </div>
          <div className="order-details">
            <div className="order-no">
              <span className="receipt-labels">Order #: </span>
              <span>12345</span>
            </div>
            <div className="bill-no">
              <span className="receipt-labels">Bill #: </span>
              <span>4567</span>
            </div>
            <div className="table-no">
              <span className="receipt-labels">Device #: </span>
              <span>3</span>
            </div>
            <div className="order-date">
              <p>21-April-23 09:54:32 AM</p>
            </div>
          </div>
          <div className="items-content-wrapper">
            <table
              style={{ width: "100%", marginTop: "18px" }}
              className="orders-table"
            >
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>QTY:</th>
                  <th>ITEM:</th>
                  <th>PRICE:</th>
                  <th>TOTAL:</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ textAlign: "center" }}>
                    <p>2</p>
                  </td>
                  <td>
                    <p>Veggie Fillet Bugger</p>
                  </td>
                  <td>
                    <p>500</p>
                  </td>
                  <td>
                    <p>1000</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: "center" }}>
                    <p>2</p>
                  </td>
                  <td>
                    <p>Veggie Fillet Bugger</p>
                  </td>
                  <td>
                    <p>500</p>
                  </td>
                  <td>
                    <p>1000</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: "center" }}>
                    <p>2</p>
                  </td>
                  <td>
                    <p>Veggie Fillet Bugger</p>
                  </td>
                  <td>
                    <p>500</p>
                  </td>
                  <td>
                    <p>1000</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: "center" }}>
                    <p>2</p>
                  </td>
                  <td>
                    <p>Veggie Fillet Bugger</p>
                  </td>
                  <td>
                    <p>500</p>
                  </td>
                  <td>
                    <p>1000</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="order-totals">
              <p>Total: KES 2,500.00</p>
            </div>
            <div className="transaction-details">
              <span id="transaction-id">MPESA</span>
              <span>-2,500.00</span>
            </div>
            <div id="transaction-footer">** Thank you for your support **</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewOrder;
