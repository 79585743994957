import { Add, CheckCircleOutline } from "@mui/icons-material";
import React from "react";
import logo from "../../../Images/logo.webp";
import { ToastContainer } from "react-toastify";
import Loader from "../../../Utils/Loader";
import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  BASE_URL,
  toastError,
  toastSuccess,
  validateData,
  axiosInstance,
} from "../../../Utils/Config";
import Header from "./Components/Header";
import SideBar from "./Components/SideBar";

const AddUser = () => {
  document.title = "Add User";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);
  const {userId} = JSON.parse(sessionStorage.getItem("userId"))

  const [formState, setFormState] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    password: "",
    role: "",
  });

  const changeHandler = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const apiCall = async () => {
    const payload = {
      firstname: formState.firstname,
      lastname: formState.lastname,
      username: formState.email,
      phone: formState.phone,
      password: formState.password
      // role: "Admin",
    };
    try {
      const response = await axiosInstance.post(`${BASE_URL}/register`, payload);
      const data = response.data;
      setLoading(false);
      if (data.code === 200) {
        toastSuccess(data.message);
        setFormState({
          firstname: "",
          lastname: "",
          email: "",
          phone: "",
          role: "",
        });
        setTimeout(() => {
          navigate(-1);
        }, 1700);
      } else {
        toastError(data.message)
      }
    } catch (error) {
      toastError(error.code);
      setLoading(false);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault()
    apiCall()
  }

  return (
    <div>
      <div className="breadcrump">
        <Link to="/admin/restaurants" className="breadcrumb-not-active">
          Home
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link onClick={() => navigate(-1)} className="breadcrumb-not-active">
          Users
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link
          onClick={(event) => event.preventDefault()}
          className="breadcrumb-active"
        >
          Add User
        </Link>
      </div>

      <div className="App-container-header-table-wrapper">
        <div className="App-container-header">
          <p>Add User</p>
        </div>
        <div className="App-container-table">
          <div className="App-table restaurant-app-table">
            {loading === true ? (
              <div className="App-loader">
                <Loader />
              </div>
            ) : (
              <></>
            )}
            <div className="App-container-row-body">
              <div className="App-input-group">
                <label htmlFor="">First Name</label>
                <input
                  type="text"
                  id=""
                  name="firstname"
                  placeholder="First Name"
                  value={formState.firstname}
                  onChange={changeHandler}
                  className="App-input-add"
                  required
                />
              </div>
              <div className="App-input-group">
                <label htmlFor="">Last Name</label>
                <input
                  type="text"
                  id=""
                  name="lastname"
                  placeholder="Last Name"
                  value={formState.lastname}
                  onChange={changeHandler}
                  className="App-input-add"
                  required
                />
              </div>
              <div className="App-input-group">
                <label htmlFor="">Email</label>
                <input
                  type="email"
                  id=""
                  name="email"
                  placeholder="Email"
                  value={formState.email}
                  onChange={changeHandler}
                  className="App-input-add"
                  required
                />
              </div>
              <div className="App-input-group">
                <label htmlFor="">Password</label>
                <input
                  type="password"
                  id=""
                  name="password"
                  placeholder="Password"
                  value={formState.password}
                  onChange={changeHandler}
                  className="App-input-add"
                  required
                />
              </div>
              <div className="App-input-group">
                <label htmlFor="">Select User Role</label>
                <select name="role" id="" onChange={changeHandler}>
                  <option value="">Select user role</option>
                  <option value="admin">Admin</option>
                  <option value="user">User</option>
                </select>
              </div>
              <div className="App-input-group">
                <label htmlFor="">Telephone</label>
                <input
                  type="tel"
                  id=""
                  name="phone"
                  placeholder="Telephone"
                  value={formState.phone}
                  onChange={changeHandler}
                  className="App-input-add"
                  required
                />
              </div>
            </div>
            <div className="card-footer-container">
              {saved && (
                <div className="saved-toast">
                  <span>
                    <CheckCircleOutline />
                  </span>
                  <span style={{ marginLeft: "10px" }}>
                    <strong>Success</strong>. You Successfully added a new user.
                  </span>
                </div>
              )}
              <div className="App-input-group-buttons">
                <div className="App-input-group-button form-app-button">
                  <div
                    className="App-button-add"
                       onClick={submitHandler}
                  >
                    Save Details
                  </div>
                </div>
                <div className="App-input-group-button form-app-button">
                  <div
                    className="App-button-cancel"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer theme="colored" autoClose={1500} hideProgressBar={true} />
    </div>
  );
};

export default AddUser;
