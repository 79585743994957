import { Route } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';


export const BASE_URL = "https://api.orderease.co.ke/api";
export const IMAGE_URL = "https://documents.orderease.co.ke/"
export const WEBSITE_URL = "https://orderease.co.ke"

export const TOKEN = "token";
export const ID = "id";

export const axiosInstance = axios.create({
  baseURL: BASE_URL,
})

axiosInstance.interceptors.request.use(
  (config) => {
     // Get the token from local storage
    const token = JSON.parse(localStorage.getItem('authToken'))?.token;

    // Set the Bearer token in the Authorization header
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
    (error) => {
      return Promise.reject(error);
    }
  
)

export const toastSuccess = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT
  });
};

export const toastStockSuccess = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT
  });
};

export const toastError = (message) => {
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT,
  });
};

export function validateBranch(branch_name, telephone, contact_person, email_address, physical_address, status) {
  if (branch_name.trim() === "") {
    return "Please input Branch Name";
  } else if (telephone.trim() === "") {
    return "Please input Telephone";
  } else if (contact_person.trim() === "") {
    return "Please input Contact Person";
  } else if (email_address.trim() === "") {
    return "Please input Email Address";
  } else if (physical_address.trim() === "") {
    return "Please input Physical Address";
  } else if (status.trim() === "") {
    return "Please input Status";
  } else {
    return null;
  }

}

export function validateSubItemOption(sub_item_id, required) {
  if (sub_item_id === '') {
    return "Please select Menu Item";
  } else if (required === '') {
    return "Please select required / not required";
  } else {
    return null;
  }

}

export function validateEditOption(name, price) {
  if (name === '') {
    return "Please input Option Name";
  } else if (price === '') {
    return "Please input Option Price";
  } else {
    return null;
  }

}

export function validateMenu(name, price, description, image) {
  if (name === '') {
    return "Please input Item Name";
  } else if (price === '') {
    return "Please input Item Price";
  } else if (description === '') {
    return "Please input Item Description";
  } else if (!image) {
    return "No Image Selected! Please select Item Image.";
  } else {
    return null;
  }

}

export function validateEditMenu(name, price, description) {
  if (name === '') {
    return "Please input Item Name";
  } else if (price === '') {
    return "Please input Item Price";
  } else if (description === '') {
    return "Please input Item Description";
  } else {
    return null;
  }

}

// export function validateAddMenu(formState) {
//   if (formState.title_name === "") {
//     return "Please input Title name";
//   } else if (formState.subtitle_name === "") {
//     return "Please input Subtitle name";
//   } else if (!formState.modifier_id || formState.modifier_id.length === 0) {
//     return "Please select at least one Modifier";
//   } else if (formState.category_id === "") {
//     return "Please select a Category";
//   } else if (formState.item_id === "") {
//     return "Please select an Item";
//   } else {
//     return null;
//   }
// }
export function validateAddMenu(formState) {
  if (formState.category_id === "") {
    return "Please select a Category";
  } else if (formState.item_id === "") {
    return "Please select an Item";
  } else {
    return null;
  }
}

export function validateModifierGroup(modifierGroup) {
  if (modifierGroup.title === "") {
    return "Please input Title name";
  } else if (modifierGroup.subtitle === "") {
    return "Please input Subtitle name";
  } else if (!modifierGroup.modifiers || modifierGroup.modifiers.length === 0) {
    return "Please select at least one Modifier";
  } else {
    return null
  }
}

export function validateUpsell(items, upsell_description, when_to_appear, branch) {
  if (items.length === 0) {
    return "Please select atleast one item.";
  } else if (branch === '') {
    return "Please select Branch";
  } else if (upsell_description === '') {
    return "Please input Description";
  } else if (when_to_appear === '') {
    return "Please select When To Appear";
  } else {
    return null;
  }

}

export function validateEditUpsell(description, when_to_appear, status) {
  if (description === "") {
    return "Please input Description.";
  } else if (when_to_appear === '') {
    return "Please select When To Appear";
  } else if (status === '') {
    return "Please select Status";
  } else {
    return null;
  }

}

export function validatePayment(status) {
  if (status === '') {
    return "Please select Status";
  } else {
    return null;
  }

}



export function validateModifier(name, price) {
  if (name.trim() === '') {
    return "Please input Modifier Name";
  } else if (price === "") {
    return "Please input price.";
  } else {
    return null;
  }

}

export function validateUpdateModifier(name, required, price) {
  if (name === '') {
    return "Please input Modifier Name";
  } else if (required === '') {
    return "Please select Required/Not Required";
  } else if (price === '') {
    return "Please input Price";
  } else {
    return null;
  }

}

// export function validateItem(name, price, category_id) {
//   if (name === '') {
//     return "Please input Modifier Name";
//   } else if (price === '') {
//     return "Please input Price";
//   } else if (category_id === '') {
//     return "Please select Category name";
//   } else {
//     return null;
//   }
// }
export function validateItem(formState) {
  if (formState.name === '') {
    return "Please input Modifier Name";
  } else if (formState.price === '') {
    return "Please input Price";
  } else if (!formState.category_id === '' || formState.category_id.length === 0) {
    return "Please select Category name";
  } else {
    return null;
  }
}

export function validateItemUpdate(name, price, description, image) {
  if (name === '') {
    return "Please input Modifier Name";
  } else if (price === '') {
    return "Please input Price";
  }else if (description === '') {
    return "Please input Description";
  }else if (!image) {
    return "No Image Selected! Please select Category Image.";
  } else {
    return null;
  }
}


export function validateCategory(category_name, branches, image) {
  if (category_name === '') {
    return "Please input Category Name";
  } else if (branches.length === 0) {
    return "Please select atleast one branch.";
  } else if (!image) {
    return "No Image Selected! Please select Image.";
  } else {
    return null;
  }

}

export function validateEditCategory(category_name, status) {
  if (category_name === '') {
    return "Please input Category Name";
  } else if (status === "") {
    return "Please select Status"
  } else {
    return null;
  }

}


export function validateData(company_name, telephone, contact_name, email_address, physical_address, status) {

  if (company_name.trim() === "") {
    return "Please input Company Name";
  } else if (telephone.trim() === "") {
    return "Please input Telephone";
  } else if (contact_name.trim() === "") {
    return "Please input Contact Name";
  } else if (email_address.trim() === "") {
    return "Please input Email Address";
  } else if (physical_address.trim() === "") {
    return "Please input Physical Address";
  } else if (status.trim() === "") {
    return "Please input Status";
  } else {
    return null;
  }

}

export function validateReport(from_time, to_time, restaurant_id, branch_id, report_type){
  if(from_time.trim() === ""){
    return "Please select from date"
  } else if(to_time.trim() === ""){
    return "Please select to date"
  } else if(restaurant_id.trim() === ""){
    return "Please select restaaurant"
  } else if(branch_id.trim() === ""){
    return "Please select branch"
  } else if(report_type.trim() === ""){
    return "Please select the report type"
  } else {
    return null
  }
}

export function validateLogins(username, password) {

  if (username.trim() === "") {
    return "Please input username";
  } else if (password.trim() === "" || password.trim().length < 6) {
    return "Please input 6 characters password";
  } else {
    return null;
  }

}

export function validateContact(email, subject, message) {

  if (email.trim() === "") {
    return "Please input email address";
  } else if (subject.trim() === "") {
    return "Please select subject";
  } else if (message.trim() === "") {
    return "Please input message";
  } else {
    return null;
  }

}

export function validateRegisterTwo(email, confirm_email) {

  if (email.trim() === "") {
    return "Please input email address";
  } else if (confirm_email.trim() === "" || confirm_email.trim() !== email.trim()) {
    return "Please confirm email address";
  } else {
    return null;
  }

}

export function validateRegisterOne(phone, password, confirm_password, terms) {

  if (phone.trim() === "") {
    return "Please input phone number";
  } else if (password.trim() === "" || password.trim().length < 6) {
    return "Please input 6 characters passcode";
  } else if (confirm_password.trim() === "" || confirm_password.trim() !== password.trim()) {
    return "Please confirm passcode";
  } else if (!terms) {
    return "Please agree to terms of service";
  } else {
    return null;
  }

}

export function validatePassword(password, confirm_password) {

  if (password.trim() === "" || password.trim().length < 6) {
    return "Please input 6 characters passcode";
  } else if (confirm_password.trim() === "" || confirm_password.trim() !== password.trim()) {
    return "Please confirm passcode";
  } else {
    return null;
  }

}

export function validateForgot(phone, email) {

  if (phone.trim() === "") {
    return "Please input phone number";
  } else if (email.trim() === "") {
    return "Please input email address";
  } else {
    return null;
  }

}

export function validateCode(code) {

  if (code.trim() === "") {
    return "Please input verification code";
  } else {
    return null;
  }

}

export function validateChangePasword(current_password, new_password, confirm_password) {

  if (current_password.trim() === "" || current_password.trim().length < 6) {
    return "Please enter correct 6 characters of current password";
  } else if (new_password.trim() === "" || new_password.trim().length < 6) {
    return "Please enter correct 6 characters of new password";
  } else if (confirm_password.trim() === "" || confirm_password.trim() !== new_password.trim()) {
    return "Please confirm password";
  } else {
    return null;
  }

}



export function numberFormat(value, decimal) {
  return value.toFixed(decimal).replace(/(\d)(?=(\d{3})+\.\d\d$)/g, "$1,");
}

export const restrictLeters = (evt) => {
  if (evt.which === 8
    || evt.which === 46
    || evt.which === 48
    || evt.which === 49
    || evt.which === 50
    || evt.which === 51
    || evt.which === 52
    || evt.which === 53
    || evt.which === 54
    || evt.which === 55
    || evt.which === 56
    || evt.which === 57) {
  } else {
    evt.preventDefault()
  }
}

export const restrictLetersIncludeDot = (evt) => {
  if (evt.which === 8
    || evt.which === 46
    || evt.which === 48
    || evt.which === 49
    || evt.which === 50
    || evt.which === 51
    || evt.which === 52
    || evt.which === 53
    || evt.which === 54
    || evt.which === 55
    || evt.which === 56
    || evt.which === 57
    || evt.which === 190) {
  } else {
    evt.preventDefault()
  }
}




