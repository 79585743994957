import React, { useState, useEffect } from "react";
import SideBar from "./Components/SideBar";
import Header from "./Components/Header";
import Empty from "../../../Utils/Empty";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import { Link, useNavigate, createSearchParams } from "react-router-dom";
import { Search, AddCircleOutlineRounded } from "@mui/icons-material";
import { axiosInstance, BASE_URL, toastError } from "../../../Utils/Config";
import { ToastContainer } from "react-toastify";

const Users = () => {
	document.title = "Users";
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [showEmpty, setShowEmpty] = useState(false);
	const [query, setQuery] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(10);
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

	const keys = ["firstname", "lastname", "username", "phone", "role"];

	const fetchUsers = async () => {
		try {
			const response = await axiosInstance.get(`${BASE_URL}/users`);
			const data = response.data;
			setLoading(false);
			if (data.code === 200) {
				setData(data.users);
			} else {
				toastError(data.message);
			}
		} catch (error) {
			toastError(error.code);
		}
	};
	const editUser = (item) => {
		// navigate({
		//   pathname: "/admin/edit-user",
		//   search: createSearchParams({
		//     id: id,
		//   }).toString(),
		// });
		navigate(`/dashboard/edit-user/${item.id}`, { state: { item } });
	};

	useEffect(() => {
		fetchUsers();
	}, []);

	return (
		<div className="App-body-restaurants">
			<div className="App-main-container">
				<SideBar />
				<div className="App-container-restaurants">
					<Header />
					<div className="breadcrump">
						<Link to="/admin" className="breadcrumb-not-active">
							Home
						</Link>
						<span className="breadcrumb-arrow">&gt;</span>
						<Link
							onClick={(event) => event.preventDefault()}
							className="breadcrumb-active">
							All users
						</Link>
					</div>

					<div className="App-container-header-table-wrapper">
						<div className="App-container-header">
							<p>Users</p>
						</div>
						<div className="App-container-table">
							<div className="App-container-table-top-bar">
								<div className="Search-bar users-search">
									<Search />
									<input
										type="search"
										name=""
										id=""
										placeholder="Search..."
										onChange={(e) => setQuery(e.target.value)}
									/>
								</div>
								<div
									className="App-right"
									onClick={() => navigate("/dashboard/add-user")}>
									<AddCircleOutlineRounded
										sx={{ color: "white", marginRight: "10px" }}
									/>
									<div className="App-title-right">Add User</div>
								</div>
							</div>

							<div className="App-table users-app-table">
								{showEmpty !== true ? (
									<>
										<Table stripped="true" bordered responsive size="xl">
											<thead id="Table-heading-wrapper">
												<tr>
													<th id="t-heading">First Name</th>
													<th id="t-heading">Last Name</th>
													<th id="t-heading">E-mail</th>
													<th id="t-heading">Phone</th>
													<th id="t-heading">User Role</th>
													{/* <th id="t-heading">Status</th> */}
													<th id="t-heading">Action</th>
												</tr>
											</thead>
											<tbody>
												{currentItems
													?.filter((item) =>
														keys.some((key) =>
															item[key]?.toLowerCase().includes(query)
														)
													)
													.map((item) => (
														<tr key={item.id}>
															<td id="t-body">
																<p>{item.firstname}</p>
															</td>
															<td id="t-body">
																<p>{item.lastname}</p>
															</td>
															<td id="t-body">
																<p>{item.username}</p>
															</td>
															<td id="t-body">
																{item.phone === null ? (
																	<p>N/A</p>
																) : (
																	<p>{item.phone}</p>
																)}
															</td>
															<td id="t-body">
																<p>{item.role}</p>
															</td>
															{/* <td id="t-body-status">
                            {item.status === "1" ? (
                              // <Badge bg="success">Active</Badge>
                              <div className="company-status">
                                <p id="status" className="active">
                                  Active
                                </p>
                              </div>
                            ) : (
                              <div className="company-status">
                                <p id="status" className="inactive">
                                  Inactive
                                </p>
                              </div>
                            )}
                          </td> */}
															<td className="App-table-actions">
																<div
																	className="App-table-action-button"
																	onClick={() => editUser(item)}
																	style={{
																		background: "#337CA0",
																	}}>
																	Edit
																</div>
															</td>
														</tr>
													))}
											</tbody>
										</Table>
										<Pagination className="pagination">
											<Pagination.Prev
												onClick={() =>
													setCurrentPage((prev) => Math.max(prev - 1, 1))
												}
											/>
											{Array.from({
												length: Math.ceil(data?.length / itemsPerPage),
											}).map((page, index) => (
												<Pagination.Item
													key={index + 1}
													active={index + 1 === currentPage}
													onClick={() => setCurrentPage(index + 1)}>
													{index + 1}
												</Pagination.Item>
											))}
											<Pagination.Next
												onClick={() =>
													setCurrentPage((prev) =>
														Math.min(
															prev + 1,
															Math.ceil(data?.length / itemsPerPage)
														)
													)
												}
											/>
										</Pagination>
									</>
								) : (
									<div className="App-empty">
										<Empty />
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<ToastContainer theme="colored" autoClose={1500} hideProgressBar={true} />
		</div>
	);
};

export default Users;
