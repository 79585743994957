import { useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import MiniSidebar from "./MiniSidebar";
import { Search, AddCircleOutlineRounded } from "@mui/icons-material";

const ViewMenu = () => {
	const { company_name } = useParams();
	const navigate = useNavigate();

	return (
		<div>
			<div className="breadcrump">
				<Link to="/dashboard/restaurants" className="breadcrumb-not-active">
					Home
				</Link>
				<span className="breadcrumb-arrow">&gt;</span>
				<Link to="/dashboard/restaurants" className="breadcrumb-active">
					All Restaurants
				</Link>
				<span className="breadcrumb-arrow">&gt;</span>
				<Link
					onClick={(event) => event.preventDefault()}
					className="breadcrumb-active">
					{company_name}
				</Link>
			</div>
			<div className="App-container-header-table-wrapper">
				<div className="App-container-header">
					<p>{company_name}</p>
				</div>
				<div className="App-container-table">
					<div className="restaurant-table-wrapper">
						<div style={{ paddingTop: "33px" }}>
							<MiniSidebar />
						</div>
						<div className="table-container">
							<div className="App-table restaurant-app-table">
								<div
									className="App-container-row-body-full"
									style={{
										border: "1px solid #83B302",
										marginTop: "33px",
										paddingTop: 0,
										borderRadius: "7px",
									}}>
									<div
										className="create-menu-container"
										style={{ paddingBottom: "60px" }}>
										<div className="create-menu-header">
											<div className="header-name">View Menu Item</div>
										</div>
										<div className="category-view-head">
											<label htmlFor="">Menu Category :</label>
											<p>Breakfast</p>
										</div>
										<div>
											<div className="category-label">
												<p>Food Item(s)</p>
											</div>
											<div className="category-items">
												<p className="category-item">Tea</p>
												<p className="category-item">Yams</p>
												<p className="category-item">Mandazi</p>
											</div>
										</div>
										<div>
											<div className="category-label">
												<p>Modifier Title: Size</p>
											</div>
											<div className="category-options-wrapper">
												<div className="options-title">
													<p>Options :</p>
												</div>
												<div className="options-container">
													<p className="option-desc">Extra Cheese</p>
													<p className="option-desc">Not Spicy</p>
													<p className="option-desc">Medium Soda</p>
												</div>
											</div>
											<div style={{ position: "relative" }}>
												<div className="category-specifics">
													<div className="specifics-title">
														<label htmlFor="">Title :</label>
														<p>Size</p>
													</div>
													<div className="specifics-sub-title">
														<label htmlFor="">Sub title :</label>
														<p>Choose One</p>
													</div>
												</div>
												{/* <div className="checkbox-container">
                            <div
                            className="checkbox-wrapper"
                            style={{ marginTop: "15px" }}
                        >
                            <input type="checkbox" name="" id="" />
                            <label htmlFor="" className="checkbox-label">
                            Required
                            </label>
                        </div>
                        </div> */}
											</div>
										</div>
										<div>
											<div className="category-label">
												<p>Modifier Title: Spicy</p>
											</div>
											<div className="category-options-wrapper">
												<div className="options-title">
													<p>Options :</p>
												</div>
												<div className="options-container">
													<p className="option-desc">Non Spicy</p>
													<p className="option-desc">Mild</p>
													<p className="option-desc">Extra Spicy</p>
												</div>
											</div>
											<div className="category-specifics">
												<div className="specifics-title">
													<label htmlFor="">Title :</label>
													<p>Size</p>
												</div>
												<div className="specifics-sub-title">
													<label htmlFor="">Sub title :</label>
													<p>Choose One</p>
												</div>
											</div>
										</div>
										{/* <div className="menu-category-body"></div> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ViewMenu;
