import { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import MiniSidebar from "./MiniSidebar";
import { Search, AddCircleOutlineRounded } from "@mui/icons-material";
import {
  BASE_URL,
  toastError,
  toastSuccess,
  validateAddMenu,
  validateModifierGroup,
  axiosInstance,
} from "../../../Utils/Config";
import { ToastContainer } from "react-toastify";

const AddMenu = () => {
  const { company_name } = useParams();
  const navigate = useNavigate();
  const [category, setCategory] = useState([]);
  const [foodItems, setFoodItems] = useState([]);
  const [modifiers, setModifiers] = useState([]);
  const [branches, setBranches] = useState([]);
  const [modifierGroup, setModifierGroup] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formState, setFormState] = useState({
    item_id: "",
    category_id: "",
    branch: "",
  });

  const [modGroupItems, setModGroupItems] = useState({
    title: "",
    subtitle: "",
    required: 0,
    modifiers: [],
  });
  const companyString = sessionStorage.getItem("Restaurant");
  const company = JSON.parse(companyString);
  const {userId} = JSON.parse(sessionStorage.getItem("userId"))

  const apiCall = async () => {
    const payload = {
      item: formState.item_id,
      category: formState.category_id,
      branch: formState.branch,
      restaurant: company["id"],
      items: modifierGroup,
    };
    try {
      const response = await axiosInstance.post(`${BASE_URL}/menu/${userId}`, payload);
      const data = response.data;
      setLoading(false);
      if (data.code === 200) {
        toastSuccess(data.message);
        setModGroupItems({
          title: "",
          subtitle: "",
          required: 0,
          modifiers: [],
        });
        setFormState({
          item_id: "",
          category_id: "",
          branch: "",
        });
        setTimeout(() => {
          navigate(-1);
        }, 1700);
      } else {
        toastError(data.message);
      }
    } catch (error) {
      toastError(error.code);
      setLoading(false);
    }
  };

  function uncheckAllCheckboxes() {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');

    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
  }

  const addModifierGroup = () => {
    const validate = validateModifierGroup(modGroupItems);
    if (validate === null) {
      toastSuccess("Modifier added successfully");
      setModifierGroup((prevState) => [...prevState, { ...modGroupItems }]);
      fetchModifiers();
      setModGroupItems({
        title: "",
        subtitle: "",
        required: 0,
        modifiers: [],
      });
      uncheckAllCheckboxes();
    } else {
      toastError(validate);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const modGrpChangeHandler = (event) => {
    const { name, value } = event.target;
    setModGroupItems((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleModifierNameChange = (event) => {
    // const { value, checked } = event.target;
    // let newModifierId = [...modGroupItems.modifiers];

    // if (checked) {
    //   newModifierId.push(value);
    // } else {
    //   newModifierId = newModifierId.filter((modifier) => modifier !== value);
    // }
    // setModGroupItems({ ...modGroupItems, modifiers: newModifierId });
    const { value, checked } = event.target;
    setModGroupItems((prevState) => ({
      ...prevState,
      modifiers: checked
        ? [...prevState.modifiers, value]
        : prevState.modifiers.filter((modifier) => modifier !== value),
    }));
  };

  const handleRequired = (event) => {
    const { name } = event.target;
    setModGroupItems((prevState) => ({
      ...prevState,
      [name]: event.target.checked ? 1 : 0,
    }));
  };

  useEffect(() => {
    fetchFoodItems();
    fetchModifiers();
    fetchCategories();
    fetchBranches();
  }, []);

  const fetchFoodItems = async () => {
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}/item/restaurant/${company["id"]}`
      );
      const data = response.data;
      setLoading(false);
      if (data.code === 200) {
        setFoodItems(data.items);
      } else {
        toastError(data.message);
      }
    } catch (error) {
      toastError(error.code);
      setLoading(false);
    }
  };

  const fetchModifiers = async () => {
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}/modifier/restaurant/${company["id"]}`
      );
      const data = response.data;
      if (data.code === 200) {
        setModifiers(data.modifiers);
      } else {
        toastError(data.message);
      }
    } catch (error) {
      alert(error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}/category/restaurant/${company["id"]}`
      );
      const data = response.data;
      setLoading(false);
      if (data.code === 200) {
        setCategory(data.categories);
      } else {
        toastError(data.message);
      }
    } catch (error) {
      toastError(error.code);
      setLoading(false);
    }
  };

  const fetchBranches = async () => {
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}/branch/restaurant/${company["id"]}`
      );
      const data = response.data;
      setLoading(false);
      if (data.code === 200) {
        setBranches(data.branches);
      } else {
        toastError(data.message);
      }
    } catch (error) {
      toastError(error.code);
      setLoading(false);
    }
  };

  const submitData = (e) => {
    e.preventDefault();
    const validate = validateAddMenu(formState);
    if (validate === null) {
      apiCall();
    } else {
      toastError(validate);
    }
  };

  return (
    <div>
      <div className="breadcrump">
        <Link to="/admin/restaurants" className="breadcrumb-not-active">
          Home
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link to="/admin/restaurants" className="breadcrumb-active">
          All Restaurants
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link
          onClick={(event) => event.preventDefault()}
          className="breadcrumb-active"
        >
          {company_name}
        </Link>
      </div>
      <div className="App-container-header-table-wrapper">
        <div className="App-container-header">
          <p>{company_name}</p> - <p>Create Menu</p>
        </div>
        <div className="App-container-table">
          <div className="restaurant-table-wrapper">
            <div style={{ paddingTop: "33px" }}>
              <MiniSidebar />
            </div>
            <div className="table-container">
              <div className="App-table restaurant-app-table">
                <div
                  className="App-container-row-body-full"
                  style={{
                    border: "1px solid #83B302",
                    marginTop: "33px",
                    paddingTop: 0,
                    borderRadius: "7px",
                  }}
                >
                  <div className="create-menu-container">
                    <div className="create-menu-header">
                      <div className="header-name">Create Menu</div>
                    </div>
                    <div className="menu-dropdown-selects">
                      <div className="App-input-group-select">
                        <select
                          name="category_id"
                          id=""
                          onChange={handleChange}
                        >
                          <option value="">Select Category</option>
                          {category?.map((prod) => (
                            <option value={prod.id} key={prod.id}>
                              {prod.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="menu-dropdown-selects">
                      {/* <div className="restaurant-input-group"> */}
                      <div className="App-input-group-select">
                        <select name="branch" id="" onChange={handleChange}>
                          <option value="">Select Branch</option>
                          {branches?.map((branch) => (
                            <option value={branch.id} key={branch.id}>
                              {branch.branch_name}
                            </option>
                          ))}
                        </select>
                      </div>
                      {/* </div> */}
                    </div>
                    <div>
                      <div className="category-label">
                        <p>Select Food Item(s)</p>
                      </div>
                      <div className="menu-search-bars">
                        <div
                          className="Search-bar"
                          style={{ backgroundColor: "#83B3020D" }}
                        >
                          <Search />
                          <input
                            type="search"
                            name=""
                            id=""
                            placeholder="Search Food Item(s)"
                            style={{ padding: "5px 0 5px 0", width: "100%" }}
                          />
                        </div>
                      </div>
                      {foodItems.length > 0 ? (
                        <div className="checkboxes-container">
                          {foodItems.map((item) => (
                            <div className="checkbox-wrapper" key={item.id}>
                              <input
                                type="radio"
                                name="item_id"
                                id=""
                                value={item.id}
                                onChange={handleChange}
                              />
                              <label htmlFor="" className="checkbox-label">
                                {item.name}
                              </label>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div style={{ marginTop: "10px", marginLeft: "20px" }}>
                          <p>There are no items</p>
                        </div>
                      )}
                    </div>
                    <div style={{ marginTop: "32px" }}>
                      <div className="category-label">
                        <p>Select Modifier(s)</p>
                      </div>
                      <div className="menu-search-bars">
                        <div
                          className="Search-bar"
                          style={{ backgroundColor: "#83B3020D" }}
                        >
                          <Search />
                          <input
                            type="search"
                            name=""
                            id=""
                            placeholder="Search Modifier(s)"
                            style={{ padding: "5px 0 5px 0", width: "100%" }}
                          />
                        </div>
                      </div>

                      {modifiers.length > 0 ? (
                        <div
                          className="checkboxes-container"
                          style={{
                            borderBottom: "2px solid #83b302",
                            paddingBottom: "30px",
                          }}
                        >
                          {modifiers.map((modifier) => (
                            <div className="checkbox-wrapper" key={modifier.id}>
                              <input
                                type="checkbox"
                                name="modifiers"
                                id={modifier.id}
                                value={modifier.id}
                                // onChange={modGrpChangeHandler}
                                onChange={handleModifierNameChange}
                                // checked={modGroupItems.modifiers.includes(modifier.id)}
                              />
                              <label
                                htmlFor={modifier.id}
                                className="checkbox-label"
                              >
                                {modifier.name}
                              </label>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div
                          style={{
                            marginTop: "10px",
                            paddingLeft: "20px",
                            borderBottom: "2px solid #83b302",
                            paddingBottom: "30px",
                          }}
                        >
                          <p>There are no modifiers</p>
                        </div>
                      )}
                    </div>
                    <div
                      className="menu-category-inputs"
                      style={{ marginTop: "32px" }}
                    >
                      <div className="App-input-group">
                        <label htmlFor="">Title Name</label>
                        <input
                          type="text"
                          id=""
                          name="title"
                          value={modGroupItems.title}
                          onChange={modGrpChangeHandler}
                          placeholder="Title Name"
                          className="App-input-add"
                          required
                        />
                      </div>
                      <div className="App-input-group">
                        <label htmlFor="">Subtitle Name</label>
                        <input
                          type="text"
                          id=""
                          name="subtitle"
                          value={modGroupItems.subtitle}
                          onChange={modGrpChangeHandler}
                          placeholder="Subtitle Name"
                          className="App-input-add"
                          required
                        />
                      </div>
                      <div
                        className="checkbox-wrapper"
                        style={{ marginTop: "15px" }}
                      >
                        <input
                          type="checkbox"
                          name="required"
                          checked={modGroupItems.required}
                          onChange={modGrpChangeHandler}
                          id=""
                        />
                        <label htmlFor="" className="checkbox-label">
                          Required
                        </label>
                      </div>
                    </div>
                    <div className="modifier-button">
                      <div className="App-right" onClick={addModifierGroup}>
                        <AddCircleOutlineRounded
                          sx={{ color: "white", marginRight: "10px" }}
                        />
                        <div className="App-title-right">Add Modifier</div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="card-footer-container"
                    style={{ paddingTop: "10px" }}
                  >
                    <div className="App-input-group-buttons">
                      <div className="App-input-group-button form-app-button">
                        <div className="App-button-add" onClick={submitData}>
                          Submit
                        </div>
                      </div>
                      <div className="App-input-group-button form-app-button">
                        <div
                          className="App-button-cancel"
                          onClick={() => navigate(-1)}
                        >
                          Cancel
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer theme="colored" autoClose={1500} hideProgressBar={true} />
    </div>
  );
};

export default AddMenu;
