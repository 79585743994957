import React, { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SideBar from "./Components/SideBar";
import ReportsTopBar from "./Components/ReportsTopBar";
import PaymentModes from "./Components/charts/PaymentModes";
import FailedTransactions from "./Components/charts/FailedTransactions";
import ChartFooter from "./Components/ChartFooter";
import { useReactToPrint } from "react-to-print";
import { BASE_URL, axiosInstance } from "../../../Utils/Config";

const PaymentAnalysis = () => {
  const componentPDF = useRef();
  const location = useLocation();
  const data_state = location.state.data;
  const [details, setDetails] = useState({
    restaurant_name: "",
    branch_name: "",
    data_generated: "",
  });

  useEffect(() => {
    fetchBranch();
  }, []);

  const fetchBranch = async () => {
    const response = await axiosInstance.get(
      `${BASE_URL}/branch/${data_state["branch_id"]}`
    );
    const data = response.data;
    if (data.code === 200) {
      setDetails((prevDetails) => ({
        ...prevDetails,
        restaurant_name: data.branch.company_name,
        branch_name: data.branch.branch_name,
      }));
    }
  };

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "Daily Analysis",
  });

  return (
    <div className="App-body-restaurants">
      <div className="App-main-container">
        <SideBar />
        <div className="App-container-restaurants">
          <ReportsTopBar
            title="Payment Analysis"
            restaurant={details.restaurant_name}
            branch={details.branch_name}
            period={`${data_state["from_time"]} - ${data_state["to_time"]}`}
            genDate={data_state["gen_date"]}
            year={data_state["year"]}
          />
          <div ref={componentPDF}>
            <div className="weekly-graphs-container">
              <div className="week-sales-container">
                <label
                  htmlFor=""
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontWeight: 600,
                    marginBottom: "10px",
                  }}
                >
                  Mode of Payments - April 2023
                </label>
                <PaymentModes />
              </div>
              <div className="week-orders-container">
                <label
                  htmlFor=""
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontWeight: 600,
                    marginBottom: "10px",
                  }}
                >
                  Failed Transactions - June 2023
                </label>
                <FailedTransactions />
              </div>
            </div>
            <ChartFooter />
          </div>
          <div className="footer-btns">
            <button className="pdf-btn" onClick={() => generatePDF()}>
              Download PDF
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentAnalysis;
