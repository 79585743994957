import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MiniSidebar from "./MiniSidebar";
import { CheckCircleOutline } from "@mui/icons-material";
import {
  BASE_URL,
  toastError,
  toastSuccess,
  validateBranch,
  axiosInstance,
} from "../../../Utils/Config";
import { ToastContainer } from "react-toastify";

const AddBranch = () => {
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);
  // const { company_name } = useParams();
  const navigate = useNavigate();
  const companyString = sessionStorage.getItem("Restaurant");
  const company = JSON.parse(companyString);
  const {userId} = JSON.parse(sessionStorage.getItem("userId"))

  const [formState, setFormState] = useState({
    branch_name: "",
    telephone: "",
    contact_person: "",
    email_address: "",
    physical_address: "",
    status: "",
  });

  const changeHandler = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const apiCall = async () => {
    try {
      const payload = {
        company_name: company["company_name"],
        restaurant: company["id"],
        branch_name: formState.branch_name,
        telephone: formState.telephone,
        contact_person: formState.contact_person,
        email_address: formState.email_address,
        physical_address: formState.physical_address,
        status: formState.status,
      };
      const response = await axiosInstance.post(`${BASE_URL}/branch/${userId}`, payload);
      const data = response.data;
      setLoading(false);
      if (data.code === 200) {
        toastSuccess(data.message);
        setFormState({
          branch_name: "",
          telephone: "",
          contact_person: "",
          email_address: "",
          physical_address: "",
          status: "",
        });
        setTimeout(() => {
          navigate(-1);
        }, 1700);
      } else {
        toastError(data.message)
      }
    } catch (error) {
      toastError(error.code);
      setLoading(false);
    }
  };
  const createBranch = (event) => {
    event.preventDefault();
    const validate = validateBranch(
      formState.branch_name,
      formState.telephone,
      formState.contact_person,
      formState.email_address,
      formState.physical_address,
      formState.status
    );
    if (validate === null) {
      setLoading(true);
      apiCall();
    } else {
      setLoading(false);
      toastError(validate);
    }
  };

  return (
    <div>
      <div className="breadcrump">
        <Link to="/admin/restaurants" className="breadcrumb-not-active">
          Home
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link to="/admin/restaurants" className="breadcrumb-active">
          All Restaurants
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link
          onClick={(event) => event.preventDefault()}
          className="breadcrumb-active"
        >
          {/* {company_name} */}
          {company["company_name"]}
        </Link>
      </div>

      <div className="App-container-header-table-wrapper">
        <div className="App-container-header">
          <p>{company["company_name"]}</p> - <p>Add Branch</p>
        </div>
        <div className="App-container-table">
          <div className="restaurant-table-wrapper">
            <div style={{ marginTop: "33px" }}>
              <MiniSidebar />
            </div>
            <div className="table-container">
              <div className="App-table restaurant-app-table">
                <div className="App-container-row-body">
                  <div className="App-input-group">
                    <label htmlFor="">Branch Name</label>
                    <input
                      type="text"
                      id=""
                      name="branch_name"
                      placeholder="Branch Name"
                      value={formState.branch_name}
                      onChange={changeHandler}
                      className="App-input-add"
                      required
                    />
                  </div>
                  <div className="App-input-group">
                    <label htmlFor="">Email</label>
                    <input
                      type="email"
                      id=""
                      name="email_address"
                      placeholder="Email"
                      value={formState.email_address}
                      onChange={changeHandler}
                      className="App-input-add"
                      required
                    />
                  </div>
                  <div className="App-input-group">
                    <label htmlFor="">Telephone</label>
                    <input
                      type="tel"
                      id=""
                      name="telephone"
                      placeholder="Telephone"
                      value={formState.telephone}
                      onChange={changeHandler}
                      className="App-input-add"
                      required
                    />
                  </div>
                  <div className="App-input-group">
                    <label htmlFor="">Physical Address</label>
                    <input
                      type="text"
                      id=""
                      name="physical_address"
                      placeholder="Physical Address"
                      value={formState.physical_address}
                      onChange={changeHandler}
                      className="App-input-add"
                      required
                    />
                  </div>
                  <div className="App-input-group">
                    <label htmlFor="">Contact Person</label>
                    <input
                      type=""
                      id=""
                      name="contact_person"
                      placeholder="Contact Person"
                      value={formState.contact_person}
                      onChange={changeHandler}
                      className="App-input-add"
                      required
                    />
                  </div>
                  <div className="App-input-group">
                    <label htmlFor="">Status</label>
                    <div className="App-input-group-select">
                    <select name="status" id="" onChange={changeHandler}>
                      <option value="">select status</option>
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </select>
                    </div>
                  </div>
                </div>
                <div className="card-footer-container">
                  {saved && (
                    <div className="saved-toast">
                      <span>
                        <CheckCircleOutline />
                      </span>
                      <span style={{ marginLeft: "10px" }}>
                        <strong>Success</strong>. You successfully added a new
                        branch.
                      </span>
                    </div>
                  )}
                  <div className="App-input-group-buttons">
                    <div className="App-input-group-button form-app-button">
                      <div className="App-button-add" onClick={createBranch}>
                        Save Details
                      </div>
                    </div>
                    <div className="App-input-group-button form-app-button">
                      <div
                        className="App-button-cancel"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer theme="colored" autoClose={1500} hideProgressBar={true} />
    </div>
  );
};

export default AddBranch;
