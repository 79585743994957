import { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import MiniSidebar from "./MiniSidebar";
import { CheckCircleOutline, InsertPhotoOutlined } from "@mui/icons-material";
import {
  BASE_URL,
  axiosInstance,
  toastError,
  toastSuccess,
  validateItem,
} from "../../../Utils/Config";
import { ToastContainer } from "react-toastify";

const AddItem = () => {
  const [showCategories, setShowCategories] = useState(false);
  const [categories, setCategories] = useState([]);
  const [branches, setBranches] = useState([])
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);
  const { company_name } = useParams();
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const [formState, setFormState] = useState({
    name: "",
    price: "",
    description: "",
    photo: "",
    category_id: [],
    branch: ""
  });
  const itemString = sessionStorage.getItem("Restaurant");
  const item = JSON.parse(itemString);
  const {userId} = JSON.parse(sessionStorage.getItem("userId"))

  const showCategoryHandler = () => {
    setShowCategories((prevState) => !prevState);
  };

  const changeHandler = (event) => {
    const { name, type, files } = event.target;

    if (type === "file") {
      const file = event.target.files[0];
      setImage(URL.createObjectURL(files[0]));
      setFormState((prevState) => ({
        ...prevState,
        photo: file, // Store the file itself
      }));
    } else if (type === "checkbox") {
      const categoryId = event.target.value;
      if (formState.category_id.includes(categoryId)) {
        const updatedCategoryIds = formState.category_id.filter(
          (id) => id !== categoryId
        );
        setFormState((prevState) => ({
          ...prevState,
          category_id: updatedCategoryIds,
        }));
      } else {
        setFormState((prevState) => ({
          ...prevState,
          category_id: [...prevState.category_id, categoryId],
        }));
      }
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [name]: event.target.value,
      }));
    }
  };

  const priceHandler = (e) => {
    const { value } = e.target;
    const input = value;
    const regex = /^\d*\.?\d{0,2}$/;
    if (regex.test(input) || input === "") {
      setFormState((prevState) => ({
        ...prevState,
        price: input,
      }));
    }
  };

  const apiCall = async () => {
    const formData = new FormData();
    formData.append("restaurant", item['id']);
    formData.append("name", formState.name);
    formData.append("price", formState.price);
    formData.append("description", formState.description);
    formData.append("category", formState.category_id);
    formData.append("branch", formState.branch)
    const fileInput = document.querySelector('input[type="file"]');
    const file = fileInput?.files[0];
    if (file) {
      formData.append("image", file);
    }

    try {
      const response = await axiosInstance.post(`${BASE_URL}/item/${userId}`, formData);
      const data = response.data;
      setLoading(false);
      if (data.code === 200) {
        toastSuccess(data.message);
        setFormState({
          name: "",
          price: "",
          description: "",
          photo: "",
          category_id: [],
          branch: ""
        });
        setTimeout(() => {
          navigate(-1);
        }, 1700);
      } else {
        toastError(data.message);
      }
    } catch (error) {
      toastError(error.code);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchBranches()
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}/category/restaurant/${item["id"]}`
      );
      const data = response.data;
      setLoading(false);
      if (data.code === 200) {
        setCategories(data.categories);
      } else {
        toastError(data.message);
      }
    } catch (error) {
      toastError(error.code);
      setLoading(false);
    }
  };

  const fetchBranches = async () => {
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}/branch/restaurant/${item["id"]}`
      );
      const data = response.data;
      setLoading(false);
      if (data.code === 200) {
        setBranches(data.branches);
      } else {
        toastError(data.message);
      }
    } catch (error) {
      toastError(error.code);
      setLoading(false);
    }
  };

  const submitData = (e) => {
    e.preventDefault();
    const validate = validateItem(formState);
    if (validate === null) {
      apiCall();
    } else {
      toastError(validate);
    }
  };

  return (
    <div>
      <div className="breadcrump">
        <Link to="/admin/restaurants" className="breadcrumb-not-active">
          Home
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link to="/admin/restaurants" className="breadcrumb-active">
          All Restaurants
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link
          onClick={(event) => event.preventDefault()}
          className="breadcrumb-active"
        >
          {company_name}
        </Link>
      </div>
      <div className="App-container-header-table-wrapper">
        <div className="App-container-header">
          <p>{company_name}</p> - <p>Add Item</p>
        </div>
        <div className="App-container-table">
          <div className="restaurant-table-wrapper">
            <div style={{ paddingTop: "33px" }}>
              <MiniSidebar />
            </div>
            <div className="table-container">
              <div className="App-table restaurant-app-table">
                <div className="App-container-row-body-full">
                  <div className="App-input-group">
                    <label htmlFor="">Item Name</label>
                    <input
                      type="text"
                      id=""
                      name="name"
                      placeholder="Item Name"
                      value={formState.name}
                      onChange={changeHandler}
                      className="App-input-add"
                      required
                    />
                  </div>
                  <div className="App-input-group">
                    <label htmlFor="">Price/Unit</label>
                    <input
                      type="text"
                      id=""
                      name="price"
                      placeholder="Price/Unit"
                      value={formState.price}
                      onChange={priceHandler}
                      className="App-input-add"
                      required
                    />
                  </div>
                  <div className="App-input-group">
                    <label htmlFor="">Item Description</label>
                    <input
                      type="text"
                      id=""
                      name="description"
                      placeholder="Item Description"
                      value={formState.description}
                      onChange={changeHandler}
                      className="App-input-add"
                      required
                    />
                  </div>
                  <div className="App-input-group-multiselect">
                    <label htmlFor="">Select Category</label>
                    <button onClick={showCategoryHandler} className="show-btn">
                      Select Category - Multiple/All
                    </button>
                    {showCategories && (
                      <div className="show-panel">
                        {categories ? (
                          categories.map((category) => (
                            <span key={category.id}>
                              <input
                                type="checkbox"
                                name="category_id"
                                id=""
                                value={category.id}
                                onChange={changeHandler}
                              />
                              <label htmlFor="">{category.name}</label>
                            </span>
                          ))
                        ) : (
                          <p>There are no categories</p>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="restaurant-input-group">
                  <label htmlFor="">Select branch</label>
                  <div className="App-input-group-select">
                    <select name="branch" id="" onChange={changeHandler}>
                      <option value="">Select Branch</option>
                          {branches?.map(branch => (
                            <option value={branch.id} key={branch.id}>{branch.branch_name}</option>
                          ))}
                    </select>
                  </div>
                  </div>
                  <div className="App-input-group">
                    <label htmlFor="">Upload Picture</label>
                    <div
                      className="image-container"
                      onClick={() =>
                        document.querySelector(".input-field").click()
                      }
                    >
                      <input
                        type="file"
                        name=""
                        id=""
                        className="input-field"
                        accept="image/*"
                        hidden
                        onChange={changeHandler}
                      />
                      {formState.photo ? (
                        <img src={image} alt={image} width={80} height={80} />
                      ) : (
                        <InsertPhotoOutlined
                          style={{
                            fontSize: "80px",
                            color: "rgba(0, 0, 0, 0.5)",
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="card-footer-container"
                  style={{ paddingTop: "10px" }}
                >
                  {saved && (
                    <div className="saved-toast">
                      <span>
                        <CheckCircleOutline />
                      </span>
                      <span style={{ marginLeft: "10px" }}>
                        <strong>Success</strong>. You successfully added a new
                        item.
                      </span>
                    </div>
                  )}
                  <div className="App-input-group-buttons">
                    <div className="App-input-group-button form-app-button">
                      <div
                        className="App-button-add"
                        onClick={submitData}
                        // onClick={()=> console.log(formState)}
                      >
                        Save Details
                      </div>
                    </div>
                    <div className="App-input-group-button form-app-button">
                      <div
                        className="App-button-cancel"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer theme="colored" autoClose={3500} hideProgressBar={true} />
    </div>
  );
};

export default AddItem;
