import React, { useRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SideBar from "./Components/SideBar";
import ReportsTopBar from "./Components/ReportsTopBar";
import WeeklyTotalSales from "./Components/charts/WeeklyTotalSales";
import WeeklyOrders from "./Components/charts/WeeklyOrders";
import WeeklyCategorySales from "./Components/charts/WeeklyCategorySales";
import ChartFooter from "./Components/ChartFooter";
import { useReactToPrint } from "react-to-print";
import { axiosInstance, BASE_URL } from "../../../Utils/Config";

const WeeklyAnalysis = () => {
  document.title = "Reports";
  const componentPDF = useRef();
  const location = useLocation();
  const data_state = location.state.data;
  const [details, setDetails] = useState({
    restaurant_name: "",
    branch_name: "",
    data_generated: "",
  });

  useEffect(() => {
    fetchBranch();
  }, []);

  const fetchBranch = async () => {
    const response = await axiosInstance.get(
      `${BASE_URL}/branch/${data_state["branch_id"]}`
    );
    const data = response.data;
    if (data.code === 200) {
      setDetails((prevDetails) => ({
        ...prevDetails,
        restaurant_name: data.branch.company_name,
        branch_name: data.branch.branch_name,
      }));
    }
  };

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "Daily Analysis",
  });

  return (
    <div className="App-body-restaurants">
      <div className="App-main-container">
        <SideBar />
        <div className="App-container-restaurants">
          {/* <Header /> */}
          <ReportsTopBar 
            title="Weekly Sales Analysis" 
            restaurant={details.restaurant_name}
            branch={details.branch_name}
            period={`${data_state["from_time"]} - ${data_state["to_time"]}`}
            genDate={data_state["gen_date"]}
            year={data_state["year"]}
          />
          <div ref={componentPDF}>
            <div className="weekly-graphs-container">
              <div className="week-sales-container">
                <label
                  htmlFor=""
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontWeight: 600,
                    marginBottom: "10px",
                  }}
                >
                  Weekly Total Sales - June 2023
                </label>
                <WeeklyTotalSales />
              </div>
              <div className="week-orders-container">
                <label
                  htmlFor=""
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontWeight: 600,
                    marginBottom: "10px",
                  }}
                >
                  Weekly Total Orders - June 2023
                </label>
                <WeeklyOrders />
              </div>
              <div className="week-category-container">
                <label
                  htmlFor=""
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontWeight: 600,
                    marginBottom: "10px",
                  }}
                >
                  Weekly Total Category Sales - June 2023
                </label>
                <WeeklyCategorySales />
              </div>
            </div>
            <ChartFooter />
          </div>
          <div className="footer-btns">
            <button className="pdf-btn" onClick={() => generatePDF()}>
              Download PDF
            </button>
          </div>
          {/* <div style={{display: "none"}}>
            <div ref={componentPDF}>
              <PrintableCharts />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

const PrintableCharts = () => {
  return (
    <div>
      <div className="weekly-graphs-container">
        <div className="week-sales-container">
          <label
            htmlFor=""
            style={{
              width: "100%",
              textAlign: "center",
              fontWeight: 600,
              marginBottom: "10px",
            }}
          >
            Weekly Total Sales - June 2023
          </label>
          <WeeklyTotalSales />
        </div>
        <div className="week-orders-container">
          <label
            htmlFor=""
            style={{
              width: "100%",
              textAlign: "center",
              fontWeight: 600,
              marginBottom: "10px",
            }}
          >
            Weekly Total Orders - June 2023
          </label>
          <WeeklyOrders />
        </div>
        <div className="week-category-container">
          <label
            htmlFor=""
            style={{
              width: "100%",
              textAlign: "center",
              fontWeight: 600,
              marginBottom: "10px",
            }}
          >
            Weekly Total Category Sales - June 2023
          </label>
          <WeeklyCategorySales />
        </div>
      </div>
      <ChartFooter />
    </div>
  );
};

export default WeeklyAnalysis;
