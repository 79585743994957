import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./reportsTopBar.css";
import { BASE_URL, axiosInstance } from "../../../../Utils/Config";

const ReportsTopBar = ({ title, restaurant, period, branch, genDate }) => {
  const [headInfo, setHeadInfo] = useState({
    year: 0,
    month: 0,
    week: 0,
    day: 0,
  });
  const location = useLocation();
  const date = new Date();
  let year = date.getFullYear();

  const fetchData = async () => {
    const response = await axiosInstance.get(`${BASE_URL}/summary`);
    const data = response.data;
    setHeadInfo((prevState) => ({
      ...prevState,
      year: data.ytdSales,
      month: data.monthSales,
      week: data.weekSales,
      day: data.daySales,
    }));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="topbar-container">
      <div className="topbar-header">
        <p>{title}</p>
        <div className="header-period">Date generated: {genDate}</div>
        {/* <div className="header-period">Date generated: 12-09-2023 09:10:59</div> */}
      </div>
      <div className="topbar-body-container">
        <div className="topbar-top">
          <div className="input-container-report restaurant">
            <label htmlFor="">Restaurant Name</label>
            <input type="text" name="" id="" value={restaurant} />
          </div>
          <div className="input-container-report period">
            <label htmlFor="">Period</label>
            <input type="text" name="" id="" value={period} />
          </div>
          <div className="input-container-report branch">
            <label htmlFor="">Branch</label>
            <input type="text" name="" id="" value={branch} />
          </div>
        </div>
        <div className="topbar-bottom">
          <div className="card-container">
            <div className="card-header">
              <p>YTD Sales - {year}</p>
            </div>
            <div className="card-body">
              <p>Ksh {headInfo.year === null ? "N/A" : headInfo.year}</p>
            </div>
          </div>
          <div className="card-container">
            <div className="card-header">
              <p>Sales This Month</p>
            </div>
            <div className="card-body">
              <p>Ksh {headInfo.month === null ? "N/A" : headInfo.month}</p>
            </div>
          </div>
          <div className="card-container">
            <div className="card-header">
              <p>Sales This Week</p>
            </div>
            <div className="card-body">
              <p>Ksh {headInfo.week === null ? "N/A" : headInfo.week}</p>
            </div>
          </div>
          <div className="card-container">
            <div className="card-header">
              <p>Sales Today</p>
            </div>
            <div className="card-body">
              <p>Ksh {headInfo.day === null ? "N/A" : headInfo.day}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportsTopBar;
