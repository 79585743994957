import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { BASE_URL, axiosInstance, toastError, toastSuccess, validateReport } from "../../../Utils/Config";
import { ToastContainer } from "react-toastify";

const Reports = () => {
    const navigate = useNavigate()
    const [state, setState] = useState("")
    const [data, setData] = useState([])
    const [branches, setBranches] = useState([]);
    const companyString = sessionStorage.getItem("Restaurant");
    const company = JSON.parse(companyString);
    const [formState, setFormState] = useState({
        from_time: "",
        to_time: "",
        restaurant_id: "",
        branch_id: "",
        report_type: "",
        gen_date: "",
    })

    useEffect(() => {
        fetchRestaurants()
        // fetchBranches()
    }, [])

    useEffect(() => {
        fetchBranches()
    }, [formState.restaurant_id])

    const fetchRestaurants = async () => {
        try {
            const response = await axiosInstance.get(`${BASE_URL}/restaurant`)
            const data = response.data
            if (data.code === 200) {
                setData(data.restaurants)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const fetchBranches = async () => {
        try {
            const response = await axiosInstance.get(
                `${BASE_URL}/branch/restaurant/${formState.restaurant_id}`
            );
            const data = response.data;
            console.log("Data: ", data)
            if (data.code === 200) {
                setBranches(data.branches);
            }
        } catch (error) {
            console.error(error)
        }
    };

    const navigateHandler = () => {
        const date = new Date()
        let year = date.getFullYear()
        const options = { timeZone: "Africa/Nairobi" }
        const formattedTime = date.toLocaleString("en-US", options)
        setFormState((prevState)=> ({
            ...prevState,
            gen_date: formattedTime
        }))
        console.log("Before navigation: ", formState)

        const validate = validateReport(
            formState.from_time,
            formState.to_time,
            formState.restaurant_id,
            formState.branch_id,
            formState.report_type
        )
        if (validate === null) {
            if (formState.report_type === "daily") {
                navigate("/reports/daily-analysis", { state: { data: formState } })
                console.log("Gemn Date: ",formState)
            } else if (formState.report_type === "weekly") {
                navigate("/reports/weekly-analysis", { state: { data: formState } })
                console.log("Gemn Date: ",formState)
            } else if (formState.report_type === "monthly") {
                navigate("/reports/annual-analysis", { state: { data: formState } })
                console.log("Gemn Date: ",formState)
            } else if (formState.report_type === "annual") {
                navigate("/reports/annual-analysis", { state: { data: formState } })
                console.log("Gemn Date: ",formState)
            } else if (formState.report_type === "order-by-hour") {
                navigate("/reports/order-hour-analysis", { state: { data: formState } })
                console.log("Gemn Date: ",formState)
            } else if (formState.report_type === "customer") {
                navigate("/reports/customer-order-report", { state: { data: formState } })
                console.log("Gemn Date: ",formState)
            } else if (formState.report_type === "payment") {
                navigate("/reports/payment-analysis", { state: { data: formState } })
                console.log("Gemn Date: ",formState)
            } else if (formState.report_type === "campaign") {
                navigate("/reports/campaign-promotion-analysis", { state: { data: formState } })
                console.log("Gemn Date: ",formState)
            } else {
                return;
            }
        } else {
            toastError(validate)
        }
    }

    const changeHandler = (e) => {
        const { name, value } = e.target
        setFormState(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const apiCall = async () => {
        const payload = {
            from: formState.from_time,
            to: formState.to_time,
            branch: formState.branch_id,
            resturant: company["id"]
        }
        try {
            const response = await axiosInstance.post(`${BASE_URL}`)
        } catch (error) {
            console.error("An error occured: ", error)
        }
    }

    return (
        <div>
            <div className="breadcrump">
                <Link to="/admin/restaurants" className="breadcrumb-not-active">
                    Home
                </Link>
                <span className="breadcrumb-arrow">&gt;</span>
                <Link
                    onClick={(event) => event.preventDefault()}
                    className="breadcrumb-active"
                >
                    Reports
                </Link>
            </div>
            <div className="App-container-header-table-wrapper">
                <div className="App-container-header">
                    <p>Reports</p>
                </div>
                <div className="App-container-table">
                    <div className="App-table reports-app-table">
                        <div className="reports-container">
                            <div className="reports-inputs-container">
                                <div className="restaurant-input-group">
                                    <label>From</label>
                                    <input type="date" placeholder='select date' name="from_time" value={formState.from_time} onChange={changeHandler} />
                                </div>
                                <div className="restaurant-input-group">
                                    <label>To</label>
                                    <input type="date" placeholder='select date' name="to_time" value={formState.to_time} onChange={changeHandler} />
                                </div>
                                <div className="restaurant-input-group">
                                    <div className="App-input-group-select">
                                        <select name="restaurant_id" id="" onChange={changeHandler}>
                                            <option value="">Select Restaurant</option>
                                            {data.length > 0 &&
                                                data.map((restaurant) => (
                                                    <option value={restaurant.id} key={restaurant.id}>{restaurant.company_name}</option>
                                                ))}
                                        </select>

                                    </div>
                                </div>
                                <div className="restaurant-input-group">
                                    <div className="App-input-group-select">
                                        <select name="branch_id" id="" onChange={changeHandler}>
                                            <option value="">Select Branch</option>
                                            {branches.length > 0 &&
                                                branches.map(branch => (
                                                    <option value={branch.id} key={branch.id}>{branch.branch_name}</option>
                                                ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="restaurant-input-group">
                                    <div className="App-input-group-select">
                                        <select name="report_type" id="" onChange={changeHandler}>
                                            <option value="">Select Report</option>
                                            <option value="daily">Daily Sales Analysis</option>
                                            <option value="weekly">Week Sales Analysis</option>
                                            <option value="monthly">Monthly Sales Analysis</option>
                                            <option value="annual">Annual Sales Analysis</option>
                                            <option value="order-by-hour">Orders By The Hour</option>
                                            <option value="customer">Customer Orders</option>
                                            <option value="payment">Payment Analysis</option>
                                            <option value="campaign">Campaigns & Promotions</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="reports-generate-btn">
                                {/* <button onClick={()=>navigate('/reports/daily-analysis')}>Generate</button> */}
                                <button onClick={navigateHandler}>Generate</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer theme="colored" autoClose={1500} hideProgressBar={true} />
        </div>
    )
}

export default Reports