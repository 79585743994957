import { useState } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import MiniSidebar from "./MiniSidebar";
import {
  CheckCircleOutline,
  InsertPhotoOutlined,
  Edit,
} from "@mui/icons-material";
import { ToastContainer } from "react-toastify";
import {
  BASE_URL,
  IMAGE_URL,
  axiosInstance,
  toastError,
  toastSuccess,
  validateItemUpdate,
} from "../../../Utils/Config";

const EditItem = () => {
  const [saved, setSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const { company_name } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const item = location.state.data;
  const {userId} = JSON.parse(sessionStorage.getItem("userId"))
  const [photo, setPhoto] = useState(null);
  const [formState, setFormState] = useState({
    name: item["name"],
    price: item["price"],
    description: item["description"],
    image: IMAGE_URL + item["image"],
    outOfStock: null,
  });

  const changeHandler = (event) => {
    const { name, type, files } = event.target;

    if (type === "checkbox") {
      setFormState((prevState) => ({
        ...prevState,
        [name]: event.target.checked ? 1 : 0,
      }));
    } else if (type === "file") {
      const file = event.target.files[0];
      setPhoto(URL.createObjectURL(files[0]));
      setFormState((prevState) => ({
        ...prevState,
        image: file, // Store the file itself
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [name]: event.target.value,
      }));
    }
  };

  const priceHandler = (e) => {
    const { value } = e.target;
    const input = value;
    const regex = /^\d*\.?\d{0,2}$/;
    if (regex.test(input) || input === "") {
      setFormState((prevState) => ({
        ...prevState,
        price: input,
      }));
    }
  };

  const apiCall = async () => {
    const formData = new FormData();
    formData.append("restaurant", item["id"]);
    formData.append("name", formState.name);
    formData.append("price", formState.price);
    formData.append("description", formState.description);
    formData.append("status", null)
    const fileInput = document.querySelector('input[type="file"]');
    const file = fileInput?.files[0];
    if (file) {
      formData.append("image", file);
    }
    try {
      const response = await axiosInstance.put(
        `${BASE_URL}/item/${item["id"]}/${userId}`, formData
      );
      const data = response.data;
      setLoading(false);
      if (data.code === 200) {
        toastSuccess(data.message);
        setFormState({
          name: "",
          price: "",
          description: "",
          image: "",
          outOfStock: null,
        });
        setTimeout(() => {
          navigate(-1);
        }, 1700);
      } else {
        toastError(data.message)
      }
    } catch (error) {
      toastError(error.code);
      setLoading(false)
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const validate = validateItemUpdate(formState.name, formState.price, formState.description,formState.image);
    if (validate === null) {
      apiCall();
    } else {
      toastError(validate);
    }
  };

  return (
    <div>
      <div className="breadcrump">
        <Link to="/admin/restaurants" className="breadcrumb-not-active">
          Home
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link to="/admin/restaurants" className="breadcrumb-active">
          All Restaurants
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link
          onClick={(event) => event.preventDefault()}
          className="breadcrumb-active"
        >
          {company_name}
        </Link>
      </div>
      <div className="App-container-header-table-wrapper">
        <div className="App-container-header">
          <p>{company_name}</p> - <p>Edit Item</p>
        </div>
        <div className="App-container-table">
          <div className="restaurant-table-wrapper">
            <div style={{ paddingTop: "33px" }}>
              <MiniSidebar />
            </div>
            <div className="table-container">
              <div className="App-table restaurant-app-table">
                <div className="App-container-row-body-full">
                  <div className="App-input-group">
                    <label htmlFor="">Item Name</label>
                    <div className="input-container">
                      <input
                        type="text"
                        id=""
                        name="name"
                        placeholder="Item Name"
                        value={formState.name}
                        onChange={changeHandler}
                        className="App-input-add"
                        required
                      />
                      <span className="input-group-icon">
                        <Edit />
                      </span>
                    </div>
                  </div>
                  <div className="App-input-group">
                    <label htmlFor="">Price/unit</label>
                    <div className="input-container">
                      <input
                        type="text"
                        id=""
                        name="price"
                        placeholder="Price/Unit"
                        value={formState.price}
                        onChange={priceHandler}
                        className="App-input-add"
                        required
                      />
                      <span className="input-group-icon">
                        <Edit />
                      </span>
                    </div>
                  </div>
                  <div className="App-input-group">
                    <label htmlFor="">Item Description</label>
                    <div className="input-container">
                      <input
                        type="text"
                        id=""
                        name="description"
                        placeholder="Item Description"
                        value={formState.description}
                        onChange={changeHandler}
                        className="App-input-add"
                        required
                      />
                      <span className="input-group-icon">
                        <Edit />
                      </span>
                    </div>
                  </div>
                  <div
                    className="checkbox-wrapper"
                    style={{ marginTop: "15px" }}
                  >
                    <input type="checkbox" name="" id="" />
                    <label htmlFor="" className="checkbox-label">
                      Out of Stock
                    </label>
                  </div>
                  <div className="App-input-group">
                    <label htmlFor="">Upload Picture</label>
                    <div
                      className="image-container"
                      onClick={() =>
                        document.querySelector(".input-field").click()
                      }
                    >
                      <input
                        type="file"
                        name=""
                        id=""
                        className="input-field"
                        accept="image/*"
                        hidden
                        onChange={changeHandler}
                      />
                      {formState.image ? (
                        <img src={formState.image} alt={photo} width={80} height={80} />
                      ) : (
                        <InsertPhotoOutlined
                          style={{
                            fontSize: "80px",
                            color: "rgba(0, 0, 0, 0.5)",
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="card-footer-container"
                  style={{ paddingTop: "10px" }}
                >
                  {saved && (
                    <div className="saved-toast">
                      <span>
                        <CheckCircleOutline />
                      </span>
                      <span style={{ marginLeft: "10px" }}>
                        <strong>Success</strong>. You successfully edited an
                        item.
                      </span>
                    </div>
                  )}
                  <div className="App-input-group-buttons">
                    <div className="App-input-group-button form-app-button">
                      <div
                        className="App-button-add"
                        //   onClick={submitData}
                        onClick={submitHandler}
                      >
                        Save Details
                      </div>
                    </div>
                    <div className="App-input-group-button form-app-button">
                      <div
                        className="App-button-cancel"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer theme="colored" autoClose={1500} hideProgressBar={true} />
    </div>
  );
};

export default EditItem;
