import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import SideBar from "./Components/SideBar";
import ReportsTopBar from "./Components/ReportsTopBar";
import ComposedBarChart from "./Components/charts/ComposedBarChart";
import SalesByDayChart from "./Components/charts/SalesByDayChart";
import { BASE_URL, axiosInstance } from "../../../Utils/Config";
import ChartFooter from "./Components/ChartFooter";
import { useReactToPrint } from "react-to-print";

const DailyAnalysis = () => {
  document.title = "Reports";
  const componentPDF = useRef();
  const location = useLocation();
  const data_state = location.state.data;
  const [details, setDetails] = useState({
    restaurant_name: "",
    branch_name: "",
    data_generated: "",
  });

  useEffect(() => {
    fetchBranch();
  }, []);

  const fetchBranch = async () => {
    const response = await axiosInstance.get(
      `${BASE_URL}/branch/${data_state["branch_id"]}`
    );
    const data = response.data;
    if (data.code === 200) {
      setDetails((prevDetails) => ({
        ...prevDetails,
        restaurant_name: data.branch.company_name,
        branch_name: data.branch.branch_name,
      }));
    }
  };

  const fetchRestaurant = async () => {
    const response = await axiosInstance.get(
      `${BASE_URL}/restaurant/${data_state["restaurant_id"]}`
    );
    const data = response.data;
    if (data.code === 200) {
      setDetails((prevDetails) => ({
        ...prevDetails,
        restaurant_name: data.restaurant.company_name,
      }));
    }
  };

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "Daily Analysis",
  });

  return (
    <div className="App-body-restaurants">
      <div className="App-main-container">
        <SideBar />
        <div className="App-container-restaurants">
          {/* <Header /> */}
          <ReportsTopBar
            title="Daily Sales Analysis"
            restaurant={details.restaurant_name}
            branch={details.branch_name}
            period={`${data_state["from_time"]} - ${data_state["to_time"]}`}
            genDate={data_state["gen_date"]}
            year={data_state["year"]}
          />
          <div className="daily-graphs-container">
            <div className="left-container">
              <label
                htmlFor=""
                style={{ width: "100%", textAlign: "center", fontWeight: 600 }}
              >
                Daily Sales - April 2023
              </label>
              <div className="chart-container">
                <ComposedBarChart />
              </div>
            </div>
            <div className="right-container">
              <label
                htmlFor=""
                style={{ width: "100%", textAlign: "center", fontWeight: 600 }}
              >
                Sales by Day of the Week - April 2023
              </label>
              <div className="chart-container">
                <SalesByDayChart />
              </div>
            </div>
            <div className="right-details"></div>
            <ChartFooter />
            <div className="footer-btns">
              <button className="pdf-btn" onClick={() => generatePDF()}>Download PDF</button>
            </div>
          </div>
          <div style={{display: "none"}}>
            <div ref={componentPDF}>
              <PrintableChart />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PrintableChart = () => {
  return (
    <div>
      <div className="daily-graphs-container">
        <div className="left-container-download">
          <label
            htmlFor=""
            style={{ width: "100%", textAlign: "center", fontWeight: 600 }}
          >
            Daily Sales - April 2023
          </label>
          <div className="chart-container-download">
            <ComposedBarChart />
          </div>
        </div>
        <div className="right-container-download">
          <label
            htmlFor=""
            style={{ width: "100%", textAlign: "center", fontWeight: 600 }}
          >
            Sales by Day of the Week - April 2023
          </label>
          <div className="chart-container-download">
            <SalesByDayChart />
          </div>
        </div>
        <ChartFooter />
      </div>
    </div>
  );
};

export default DailyAnalysis;
