import {useState, useEffect} from "react";
import '../../../../../App.css'
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";



const ComposedBarChart = () => {
  const [chartWidth, setChartWidth] = useState(800);
  const [chartHeight, setChartHeight] = useState(500);

  const updateDimensions = () => {
    const newWidth = window.innerWidth * 0.9; 
    const newHeight = window.innerHeight * 0.6; 

    setChartWidth(newWidth);
    setChartHeight(newHeight);
  };

  useEffect(() => {
    // Set initial dimensions
    updateDimensions();

    // Attach event listener to update dimensions on window resize
    window.addEventListener('resize', updateDimensions);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, [window.innerWidth]);

  const data = [
    {
      name: "1/01/23",
      sales: 590,
      pv: 800,
      amt: 1400,
      cnt: 490,
    },
    {
      name: "2/01/23",
      sales: 868,
      pv: 967,
      amt: 1506,
      cnt: 590,
    },
    {
      name: "3/01/23",
      sales: 1397,
      pv: 1098,
      amt: 989,
      cnt: 350,
    },
    {
      name: "4/01/23",
      sales: 1480,
      pv: 1200,
      amt: 1228,
      cnt: 480,
    },
    {
      name: "5/01/23",
      sales: 1520,
      pv: 1108,
      amt: 1100,
      cnt: 460,
    },
    {
      name: "6/01/23",
      sales: 1400,
      pv: 680,
      amt: 1700,
      cnt: 380,
    },
  ];

  return (
    <ComposedChart
      width={chartWidth}
      height={500}
      data={data}
      margin={{
        top: 20,
        right: 20,
        bottom: 20,
        left: 20,
      }}
    >
      <CartesianGrid stroke="#f5f5f5" />
      <XAxis dataKey="name" angle={-90} textAnchor="end" interval={0} height={80} />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="sales" barSize={15} fill="#413ea0" />
      <Line type="monotone" dataKey="sales" stroke="#ff7300" />
    </ComposedChart>
  );
};

export default ComposedBarChart;
