import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useState, useEffect } from "react";
import Loader from "../../Utils/Loader";
import {
  BASE_URL,
  toastError,
  toastSuccess,
  validateLogins,
  ID,
  TOKEN,
} from "../../Utils/Config";
import axios from "axios";
import { async } from "q";

function Login() {
  document.title = "Login";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    sessionStorage.clear();
    localStorage.clear();
  }, []);

  const [formState, setFormState] = useState({
    username: "",
    password: "",
    price: "",
  });

  const apiCall = async () => {
    const bodyData = {
      username: formState.username,
      password: formState.password,
    };
    try {
      const response = await axios.post(`${BASE_URL}/login`, bodyData);
      const data = await response.data;
      if (data.code === 200) {
        const token = data.token;
        const userId = data.user.id;
        localStorage.setItem("authToken", JSON.stringify({ token }));
        sessionStorage.setItem("userId", JSON.stringify({ userId }));
        navigate("/dashboard/restaurants");
      } else {
        return;
      }
    } catch (error) {
      alert(error);
      setLoading(false);
    }
  };

  const loginUser = (event) => {
    event.preventDefault();
    const validate = validateLogins(formState.username, formState.password);
    if (validate === null) {
      setLoading(true);
      apiCall();
    } else {
      toastError(validate);
    }
  };

  const priceHandler = (e) => {
    const { value } = e.target;
    const input = value;
    const regex = /^\d*\.?\d{0,2}$/;
    if (regex.test(input) || input === "") {
      setFormState((prevState) => ({
        ...prevState,
        price: input,
      }));
    }
  };

  return (
    <div className="App-margin">
      <div className="col-lg-6 col-md-6 col-sm-6 App-container">
        {loading === true ? (
          <div className="App-loader">
            <Loader />
          </div>
        ) : (
          <></>
        )}
        <div className="App-image" />
        <div className="App-container-row">
          <div className="App-container-column">
            <div className="App-title-white">Username</div>
          </div>
          <div className="App-container-column">
            <input
              type="text"
              value={formState.username}
              onChange={(e) =>
                setFormState({ ...formState, username: e.target.value })
              }
              className="App-input"
              required
            />
          </div>
        </div>

        <div className="App-container-row">
          <div className="App-container-column">
            <div className="App-title-white">Password</div>
          </div>

          <div className="App-container-column">
            <input
              type="password"
              value={formState.password}
              onChange={(e) =>
                setFormState({ ...formState, password: e.target.value })
              }
              className="App-input"
              required
            />
          </div>
        </div>
        {/* <div className="App-container-row">
          <div className="App-container-column">
            <input
              type="number"
              placeholder="price"
              name="price"
              value={formState.price}
              onChange={priceHandler}
              className="App-input"
            />
          </div>
        </div> */}

        {/* <div className="App-button" onClick={loginUser}>Log In</div> */}
        <div
          className="App-button"
          //  onClick={()=>{navigate('/dashboard/restaurants')}}
          onClick={loginUser}
        >
          Log In
        </div>
        <div className="App-forgot">Reset Password</div>
      </div>
      <ToastContainer theme="colored" autoClose={3500} hideProgressBar={true} />
    </div>
  );
}

export default Login;
