import { AddCircleOutlineRounded, Search } from "@mui/icons-material";
import { React, useEffect, useState, useRef } from "react";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
  BASE_URL,
  ID,
  TOKEN,
  toastError,
  toastSuccess,
  axiosInstance,
} from "../../../Utils/Config";
import { ToastContainer, toast } from "react-toastify";
import Badge from "react-bootstrap/Badge";
import Empty from "../../../Utils/Empty";
import { Link } from "react-router-dom";

const SystemLogs = () => {
  const [showEmpty, setShowEmpty] = useState(false);
  const [logs, setLogs] = useState([]);
  const [query, setQuery] = useState("");
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = logs?.slice(indexOfFirstItem, indexOfLastItem);

  const keys = ["activity"];

  const formatToEAT = (timestamp) => {
    const date = new Date(timestamp);
    const options = { timeZone: "Africa/Nairobi" };
    return date.toLocaleString("en-US", options);
  };

  const fetchLogs = async () => {
    try {
      const response = await axiosInstance.get(`${BASE_URL}/orders/logs`);
      const data = response.data;
      setLogs(data);
    } catch (error) {
      console.error("An error occurred: ", error);
    }
  };

  useEffect(() => {
    fetchLogs();
  }, []);

  return (
    <div>
      <div className="breadcrump">
        <Link to="/admin" className="breadcrumb-not-active">
          Home
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link
          onClick={(event) => event.preventDefault()}
          className="breadcrumb-active"
        >
          All Restaurants
        </Link>
      </div>

      <div className="App-container-header-table-wrapper">
        <div className="App-container-header">
          <p>Activity Logs</p>
        </div>
        <div className="App-container-table">
          <div className="App-container-table-top-bar">
            <div className="top-bar-calendar">
              <input type="date" name="" id="" />
            </div>
            <div className="Search-bar">
              <Search />
              <input
                type="search"
                name=""
                id=""
                placeholder="Search..."
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
          </div>

          <div className="App-table restaurants-app-table">
            {showEmpty !== true ? (
              <>
                <Table stripped="true" bordered responsive size="xl">
                  <thead id="Table-heading-wrapper">
                    <tr>
                      <th id="t-heading">Date / Time stamp</th>
                      <th id="t-heading">User</th>
                      <th id="t-heading">User Role</th>
                      <th id="t-heading">Activity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems
                      ?.filter((item) =>
                        keys.some((key) =>
                          item[key]?.toLowerCase().includes(query)
                        )
                      )
                      .map((log) => {
                        const formattedTime = formatToEAT(log.createdAt);
                        return (
                          <tr>
                            <td
                              id="t-body"
                              style={{ width: "25%", height: "80px" }}
                            >
                              {/* <p>{log.createdAt}</p> */}
                              <p>{formattedTime}</p>
                            </td>
                            <td
                              id="t-body"
                              style={{ width: "25%", height: "80px" }}
                            >
                              {/* <p>{log.users.username}</p> */}
                              <p>
                                {log.users.firstname} {log.users.lastname}
                              </p>
                            </td>
                            <td
                              id="t-body"
                              style={{ width: "25%", height: "80px" }}
                            >
                              <p>{log.users.role}</p>
                            </td>
                            <td
                              id="t-body"
                              style={{ width: "25%", height: "80px" }}
                            >
                              <p>{log.activity}</p>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
                <Pagination className="pagination">
                  <Pagination.Prev
                    onClick={() =>
                      setCurrentPage((prev) => Math.max(prev - 1, 1))
                    }
                  />
                  {Array.from({
                    length: Math.ceil(logs?.length / itemsPerPage),
                  }).map((page, index) => (
                    <Pagination.Item
                      key={index + 1}
                      active={index + 1 === currentPage}
                      onClick={() => setCurrentPage(index + 1)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    onClick={() =>
                      setCurrentPage((prev) =>
                        Math.min(
                          prev + 1,
                          Math.ceil(logs?.length / itemsPerPage)
                        )
                      )
                    }
                  />
                </Pagination>
              </>
            ) : (
              <div className="App-empty">
                <Empty />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SystemLogs;
