import { useState, useEffect } from 'react'
import OrdersIcon from '../../../../Images/OdersMadeIcon.svg'
import RestaurantsIcon from '../../../../Images/RestaurantsIcon.svg'
import DevicesIcon from '../../../../Images/Devices.svg'
import SalesIcon from '../../../../Images/Sales.svg'
import Loader from '../../../../Utils/Loader'
import { BASE_URL, axiosInstance } from '../../../../Utils/Config'

function Header() {
    // const { id } = JSON.parse(sessionStorage.getItem("Restaurant"))
    // const { userId } = JSON.parse(sessionStorage.getItem("userId"))
    const [loading, setLoading] = useState(true)
    const [headerData, setHeaderData] = useState({
        orders: 0,
        restaurants: 0,
        devices: 0,
        sales: 0
    })

    const fetchData = async () => {
        try {
            const response = await axiosInstance.get(`${BASE_URL}/summary`)
            const data = response.data
            if (response.status === 200) {
                setHeaderData((prevData) => ({
                    ...prevData,
                    orders: data.orders,
                    restaurants: data.restaurants,
                    // devices: data.devices,
                    sales: data.ytdSales
                }))
                setLoading(false)
            }
        } catch (error) {
            console.error("An error occurred: ", error)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div className="App-container-cards-grid">
            <div className="App-container-cards-column">
                <div className="App-container-cards-icon">
                    <img src={OrdersIcon} alt="" />
                </div>
                {/* <div className="App-container-cards">{headerData.orders}</div> */}
                <div className="App-container-cards">
                    {loading ? <Loader /> : <>{headerData.orders}</>}
                </div>
                <div className="App-title-black-cards">Orders Made</div>
            </div>

            <div className="App-container-cards-column">
                <div className="App-container-cards-icon">
                    <img src={RestaurantsIcon} alt="" />
                </div>
                <div className="App-container-cards">
                    {loading ? <Loader /> : <>{headerData.restaurants}</>}
                </div>
                <div className="App-title-black-cards">Restaurants</div>
            </div>

            <div className="App-container-cards-column">
                <div className="App-container-cards-icon">
                    <img src={DevicesIcon} alt="" />
                </div>
                <div className="App-container-cards">
                    {loading ? <Loader /> : <>{headerData.devices}</>}
                </div>
                <div className="App-title-black-cards">Devices</div>
            </div>

            <div className="App-container-cards-column">
                <div className="App-container-cards-icon">
                    <img src={SalesIcon} alt="" />
                </div>
                <div className="App-container-cards">
                    {loading ? <Loader /> : <>{headerData.sales}</>}
                </div>
                <div className="App-title-black-cards">YTD Sales (Ksh)</div>
            </div>
        </div>
    )
}

export default Header;