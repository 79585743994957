import SideBar from "./Components/SideBar";
import Header from "./Components/Header";
import { Outlet } from "react-router-dom";

const Admin = () => {

  return (
    <div className="App-body-restaurants">
      <div className="App-main-container">
        <SideBar />
        <div className="App-container-restaurants">
          <Header />
        <Outlet/>
        </div>
      </div>
    </div>
  );
};

export default Admin;
