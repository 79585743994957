import { useState, useEffect } from "react";
import MiniSidebar from "./MiniSidebar";
import {
  AddCircleOutlineRounded,
  Search,
  ArrowDropDown,
} from "@mui/icons-material";
import { useParams, Link, useNavigate } from "react-router-dom";
import Empty from "../../../Utils/Empty";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import { axiosInstance, toastError, BASE_URL } from "../../../Utils/Config";

const Devices = () => {
  const [branch, setBranch] = useState(1);
  const [branches, setBranches] = useState([]);
  const [devices, setDevices] = useState([]);
  const { company_name } = useParams();
  const navigate = useNavigate();
  const [active, setActive] = useState(true);
  const itemString = sessionStorage.getItem("Restaurant");
  const item = JSON.parse(itemString);
  const {userId} = JSON.parse(sessionStorage.getItem("userId"))
  const [query, setQuery] = useState('')
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = devices?.slice(indexOfFirstItem, indexOfLastItem);

  const keys = ['address']

  useEffect(() => {
    fetchBranches();
  }, []);

  useEffect(() => {
    fetchDevices();
    // console.log("Current Devices array: ", devices)
  }, [branch, active]);

  // useEffect(()=>{
  //   fetchDevices()
  // },[active])

  const fetchBranches = async () => {
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}/branch/restaurant/${item["id"]}`
      );
      const data = response.data;
      if (data.code === 200) {
        setBranches(data.branches);
      } else {
        toastError(data.message);
      }
    } catch (error) {
      toastError(error.code);
    }
  };

  const changeHandler = (event) => {
    setBranch(event.target.value);
    // fetchDevices()
  };

  const fetchDevices = async () => {
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}/device/branch/${branch}`
      );
      const data = response.data;
      if (data.code === 200) {
        // console.log("Devices: ",data)
        setDevices(data?.devices);
      } else {
        toastError(data.message);
      }
    } catch (error) {
      toastError(error.code);
    }
  };

  const handleActivate = async (device) => {
    setActive((prevState) => !prevState);
    const payload = {
      branch: device.branch,
      restaurant: item["id"],
      address: device.address,
      status: active,
    }
    const response = await axiosInstance.put(`${BASE_URL}/device/${device.id}/${userId}`, payload)
    const data = response.data
    console.log("Response data: ", data)
  };

  const formatToEAT = (timestamp) => {
    const date = new Date(timestamp);
    const options = { timeZone: "Africa/Nairobi" };
    return date.toLocaleString("en-US", options);
  };

  return (
    <div>
      <div className="breadcrump">
        <Link to="/admin/restaurants" className="breadcrumb-not-active">
          Home
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link to="/admin/restaurants" className="breadcrumb-active">
          All Restaurants
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link
          onClick={(event) => event.preventDefault()}
          className="breadcrumb-active"
        >
          {company_name}
        </Link>
      </div>
      <div className="App-container-header-table-wrapper">
        <div className="App-container-header">
          <p>{company_name}</p> - <p>Devices</p>
        </div>
        <div className="App-container-table">
          <div className="App-container-table-top-bar">
            <div
              className="Search-bar"
              style={{ backgroundColor: "#83B3020D", height: "35px" }}
            >
              <Search />
              <input
                type="search"
                name=""
                id=""
                placeholder="Search..."
                style={{ backgroundColor: "inherit" }}
                onChange={(e) => setQuery(e.target.value)} 
              />
            </div>
            <div
              className="Search-bar"
              style={{ maxWidth: "230px", width: "230px" }}
            >
              <select name="branch" id="" onChange={changeHandler}>
                <option value="">Select Branch</option>
                {branches?.map((branch) => (
                  <option value={branch.id} key={branch.id}>
                    {branch.branch_name}
                  </option>
                ))}
              </select>
              {/* <ArrowDropDown/> */}
            </div>

            <div
              className="App-right"
              onClick={() =>
                navigate(`/admin/restaurants/${company_name}/add-device`)
              }
            >
              <AddCircleOutlineRounded
                sx={{ color: "white", marginRight: "10px" }}
              />
              <div className="App-title-right">Add Device</div>
            </div>
          </div>
          <div className="restaurant-table-wrapper">
            <MiniSidebar />
            <div className="table-container">
              {/* Branch name: */}
              <div className="App-table restaurants-app-table">
                {devices.length > 0 ? (
                  <>
                  <Table stripped="true" bordered responsive size="xl">
                    <thead id="Table-heading-wrapper">
                      <tr>
                        <th id="t-heading">Date Created</th>
                        <th id="t-heading">Device Address</th>
                        <th id="t-heading">Online/Offline Time</th>
                        <th id="t-heading">Status</th>
                        <th id="t-heading">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems?.filter(device =>
                      keys.some(key => device[key].toLowerCase().includes(query))
                    ).map((device) => {
                      const formattedTime = formatToEAT(device.createdAt)
                      return (
                        <tr key={device.id}>
                          <td id="t-body">
                            <p>{formattedTime}</p>
                          </td>
                          <td id="t-body">
                            <p>{device.address}</p>
                          </td>
                          <td id="t-body">
                            <p></p>
                          </td>
                          <td id="t-body-status">
                            <div className="company-status">
                              {device.status === "true" ? (
                                <p id="status" className="active">
                                  Active
                                </p>
                              ) : (
                                <p id="status" className="inactive">
                                  Inactive
                                </p>
                              )}
                            </div>
                          </td>
                          <td className="App-table-actions">
                            {device.status === "true" ? (
                              <div
                              className="App-table-action-button"
                              onClick={()=>handleActivate(device)}
                              style={{
                                backgroundColor: "#FF1D15",
                              }}
                            >
                              Deactivate
                            </div>
                            ) : (
                              <div
                              className="App-table-action-button"
                              onClick={()=>handleActivate(device)}
                              style={{
                                backgroundColor: "#1a9826",
                              }}
                            >
                              Activate
                            </div>
                            )}
                            {/* <div
                              className="App-table-action-button"
                              onClick={()=>handleActivate(device)}
                              style={{
                                backgroundColor: "#FF1D15",
                              }}
                            >
                              Deactivate
                            </div> */}
                          </td>
                        </tr>
                      )})}
                    </tbody>
                  </Table>
                  <Pagination className="pagination">
                  <Pagination.Prev
                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                  />
                  {Array.from({ length: Math.ceil(devices?.length / itemsPerPage) }).map(
                    (page, index) => (
                      <Pagination.Item
                        key={index + 1}
                        active={index + 1 === currentPage}
                        onClick={() => setCurrentPage(index + 1)}
                      >
                        {index + 1}
                      </Pagination.Item>
                    )
                  )}
                  <Pagination.Next
                    onClick={() =>
                      setCurrentPage((prev) =>
                        Math.min(prev + 1, Math.ceil(devices?.length / itemsPerPage))
                      )
                    }
                  />
                </Pagination>
                  </>
                ) : (
                  <div className="App-empty">
                    <Empty />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Devices;
