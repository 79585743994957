import { useState, useEffect } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import MiniSidebar from "./MiniSidebar";
import {
  CheckCircleOutline,
  InsertPhotoOutlined,
  Edit,
  Category,
} from "@mui/icons-material";
import { ToastContainer } from "react-toastify";
import { BASE_URL, axiosInstance, toastError, toastSuccess } from "../../../Utils/Config";

const EditPromotion = () => {
  const { company_name, id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const prom = location.state.data;
  const itemString = sessionStorage.getItem("Restaurant");
  const item = JSON.parse(itemString);
  const {userId} = JSON.parse(sessionStorage.getItem("userId"))
  const [formState, setFormState] = useState({
    promotion_name: prom["name"],
    discount_value: prom["discountValue"],
    start_date: prom["startDate"],
    end_date: prom["endDate"],
    from: prom["startTime"],
    to: prom["endTime"],
    discount_type: prom["discountType"],
    days: prom["days"],
  });
  const daysName = ["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"];

  const changeHandler = (event) => {
    const { name, value, type, checked } = event.target;

    setFormState((prevState) => {
      if (type === "checkbox") {
        const updatedDays = checked
          ? [...prevState.days, parseInt(value)]
          : prevState.days.filter((day) => day !== parseInt(value));
        return {
          ...prevState,
          days: updatedDays,
        };
      } else {
        return {
          ...prevState,
          [name]: value,
        };
      }
    });
  };

  const apiCall = async () => {
    const payload = {
      name: formState.promotion_name,
      discountType: formState.discount_type,
      discountValue: formState.discount_value,
      branch: prom.branch["id"],
      start: formState.start_date + " " + formState.from,
      end: formState.end_date + " " + formState.to,
      // item: formState.item,
      days: formState.days,
      restaurant: String(item["id"]),
    };
    try {
      const response = await axiosInstance.put(
        `${BASE_URL}/promotion/${id}/${userId}`,
        payload
      );
      const data = response.data;
      if(data.code === 200){
        toastSuccess(data.message)
        setTimeout(()=>{
          navigate(-1)
        },1700)
      } else {
        toastError(data.message)
      }
    } catch (error) {
      toastError(error.code);
    }
  };

  const updateModifier = () => {
    apiCall();
  };

  return (
    <div>
      <div className="breadcrump">
        <Link to="/admin/restaurants" className="breadcrumb-not-active">
          Home
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link to="/admin/restaurants" className="breadcrumb-active">
          All Restaurants
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link
          onClick={(event) => event.preventDefault()}
          className="breadcrumb-active"
        >
          {company_name}
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link
          className="breadcrumb-active"
          to={`/admin/restaurants/${company_name}/promotions`}
        >
          Promotions
        </Link>
      </div>
      <div className="App-container-header-table-wrapper">
        <div className="App-container-header">
          <p>{company_name}</p> - <p>Edit Promotion</p>
        </div>
        <div className="App-container-table">
          <div className="restaurant-table-wrapper">
            <div style={{ paddingTop: "33px" }}>
              <MiniSidebar />
            </div>
            <div className="table-container">
              <div className="App-table restaurant-app-table">
                <div className="App-container-row-body-full">
                  <div className="App-input-group">
                    <label htmlFor="">Promotion Name</label>
                    <div className="input-container">
                      <input
                        type="text"
                        id=""
                        name="promotion_name"
                        placeholder="Promotion Name"
                        value={formState.promotion_name}
                        onChange={changeHandler}
                        className="App-input-add"
                        required
                      />
                      <span className="input-group-icon">
                        <Edit />
                      </span>
                    </div>
                  </div>
                  {formState.discount_type === "percentage" ? (
                    <div className="App-input-group">
                      <label htmlFor="">Discount (%)</label>
                      <div className="input-container">
                        <input
                          type="number"
                          id=""
                          name="discount_value"
                          value={formState.discount_value}
                          onChange={changeHandler}
                          placeholder="Discount"
                          className="App-input-add"
                          required
                        />
                        <span className="input-group-icon">
                          <Edit />
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className="App-input-group">
                      <label htmlFor="">Discount Amount (Ksh)</label>
                      <div className="input-container">
                        <input
                          type="number"
                          id=""
                          name="discount_value"
                          value={formState.discount_value}
                          onChange={changeHandler}
                          placeholder="Discount Amount"
                          className="App-input-add"
                          required
                        />
                        <span className="input-group-icon">
                          <Edit />
                        </span>
                      </div>
                    </div>
                  )}
                  {/* <div className="App-input-group">
                    <label htmlFor="">Branches</label>
                    <div className="input-container">
                      <button
                        onClick={showCategoryHandler}
                        className="show-btn"
                      >
                        Select Branch - Multiple/All
                      </button>
                      {showCategories && (
                        <div className="show-panel">
                          {branches ? (
                            branches.map((branch) => (
                              <span key={branch.id}>
                                <input
                                  type="checkbox"
                                  name="branch_id"
                                  id=""
                                  value={branch.id}
                                  onChange={changeHandler}
                                />
                                <label htmlFor="">{branch.branch_name}</label>
                              </span>
                            ))
                          ) : (
                            <p>There is no active branches</p>
                          )}
                        </div>
                      )}
                      <span className="input-group-icon">
                        <Edit />
                      </span>
                    </div>
                  </div> */}
                  <div className="App-input-group">
                    <label htmlFor="">Start Date</label>
                    <div className="input-container">
                      <input
                        type="date"
                        id=""
                        name="start_date"
                        value={formState.start_date}
                        onChange={changeHandler}
                        placeholder="Start Date"
                        className="App-input-add"
                        required
                      />
                      <span className="input-group-icon">
                        <Edit />
                      </span>
                    </div>
                  </div>
                  <div className="App-input-group">
                    <label htmlFor="">End Date</label>
                    <div className="input-container">
                      <input
                        type="date"
                        id=""
                        name="end_date"
                        value={formState.end_date}
                        onChange={changeHandler}
                        placeholder="End Date"
                        className="App-input-add"
                        required
                      />
                      <span className="input-group-icon">
                        <Edit />
                      </span>
                    </div>
                  </div>
                  <div className="App-input-group">
                    <label htmlFor="">From (Time)</label>
                    <div className="input-container">
                      <input
                        type="time"
                        id=""
                        name="from"
                        value={formState.from}
                        onChange={changeHandler}
                        placeholder="Contact Person"
                        className="App-input-add"
                        required
                      />
                      <span className="input-group-icon">
                        <Edit />
                      </span>
                    </div>
                  </div>
                  <div className="App-input-group">
                    <label htmlFor="">To (Time)</label>
                    <div className="input-container">
                      <input
                        type="time"
                        id=""
                        name="to"
                        value={formState.to}
                        onChange={changeHandler}
                        placeholder="Contact Person"
                        className="App-input-add"
                        required
                      />
                      <span className="input-group-icon">
                        <Edit />
                      </span>
                    </div>
                  </div>
                  {/* <div className="App-input-group">
                    <label htmlFor="">Item Name</label>
                    <div className="input-container">
                      <div className="App-input-group-select">
                        <select
                          name="item"
                          id="item"
                          //    onChange={changeHandler}
                        >
                          <option value="">Select Item</option>
                          {items.length > 0 &&
                            items.map((item) => (
                              <option value={item.name}>{item.name}</option>
                            ))}
                        </select>
                      </div>
                      <span className="input-group-icon">
                        <Edit />
                      </span>
                    </div>
                  </div> */}
                  <div className="days-of-week">
                    {daysName.map((day, index) => (
                      <div className="checkbox-wrapper" key={index}>
                        <input
                          type="checkbox"
                          name="days"
                          id=""
                          value={index + 1}
                          checked={formState.days.includes(index + 1)}
                          onChange={changeHandler}
                        />
                        <label htmlFor="" className="checkbox-label">
                          {day}
                        </label>
                      </div>
                    ))}
                  </div>

                  <div
                    className="card-footer-container"
                    style={{ paddingTop: "10px" }}
                  >
                    <div className="App-input-group-buttons">
                      <div className="App-input-group-button form-app-button">
                        <div
                          className="App-button-add"
                          onClick={updateModifier}
                        >
                          Save Details
                        </div>
                      </div>
                      <div className="App-input-group-button form-app-button">
                        <div
                          className="App-button-cancel"
                          onClick={() => navigate(-1)}
                        >
                          Cancel
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer theme="colored" autoClose={1500} hideProgressBar={true} />
    </div>
  );
};

export default EditPromotion;
