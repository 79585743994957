import { useState } from "react";
import MiniSidebar from "./MiniSidebar";
import { AddCircleOutlineRounded, Search } from "@mui/icons-material";
import { useParams, Link } from "react-router-dom";
import Empty from "../../../Utils/Empty";
import Table from "react-bootstrap/Table";

const Device = () => {

  const [showEmpty, setShowEmpty] = useState(false);
  const { company_name } = useParams();

  return (
    <div>
      <div className="breadcrump">
        <Link to="/admin/restaurants" className="breadcrumb-not-active">
          Home
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link to="/admin/restaurants" className="breadcrumb-active">
          All Restaurants
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link
          onClick={(event) => event.preventDefault()}
          className="breadcrumb-active"
        >
          {company_name}
        </Link>
      </div>
      <div className="App-container-header-table-wrapper">
        <div className="App-container-header">
          <p>{company_name}</p> - <p>Devices</p>
        </div>
        <div className="App-container-table">
          <div className="App-container-table-top-bar">
            <div
              className="Search-bar"
              style={{ backgroundColor: "#83B3020D", height:"35px" }}
            >
              <Search />
              <input
                type="search"
                name=""
                id=""
                placeholder="Search..."
                style={{ backgroundColor: "inherit" }}
              />
            </div>
          </div>
          <div className="restaurant-table-wrapper">
            <MiniSidebar />
            <div className="table-container">
              {/* Branch name: */}
              <div className="App-table restaurants-app-table">
                {showEmpty !== true ? (
                  <Table stripped="true" bordered responsive size="xl">
                    <thead id="Table-heading-wrapper">
                      <tr>
                        <th id="t-heading">Last Updated</th>
                        <th id="t-heading">Device Address</th>
                        <th id="t-heading">Online/Offline Time</th>
                        <th id="t-heading">Status</th>
                        <th id="t-heading">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          id="t-body"
                          style={{ width: "20%", height: "80px" }}
                        ></td>
                        <td
                          id="t-body"
                          style={{ width: "20%", height: "80px" }}
                        >
                          <p>www.orderease.com/device2</p>
                        </td>
                        <td
                          id="t-body"
                          style={{ width: "20%", height: "80px" }}
                        >
                          <p>05/05/2023 08:00:05 Online</p>
                        </td>
                        <td
                          id="t-body-status"
                          style={{ width: "15%", height: "80px" }}
                        >
                          <div className="company-status">
                            <p id="status" className="active">
                              Active
                            </p>
                          </div>
                        </td>
                        <td className="Device-table-actions">
                          <div
                            className="Device-table-action-button"
                            //   onClick={() => handleClick(item)}
                            style={{
                              backgroundColor: "#FF1D15",
                            }}
                          >
                            Deactivate
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td
                          id="t-body"
                          style={{ width: "14%", height: "80px" }}
                        ></td>
                        <td
                          id="t-body"
                          style={{ width: "14%", height: "80px" }}
                        ></td>
                        <td
                          id="t-body"
                          style={{ width: "14%", height: "80px" }}
                        ></td>
                        <td
                          id="t-body-status"
                          style={{ width: "14%", height: "80px" }}
                        >
                          <div className="company-status">
                            <p id="status" className="inactive">
                              Inactive
                            </p>
                          </div>
                        </td>
                        <td className="Device-table-actions">
                          <div
                            className="Device-table-action-button"
                            //   onClick={() => handleClick(item)}
                            style={{
                              backgroundColor: "#FF1D15",
                            }}
                          >
                            Deactivate
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td
                          id="t-body"
                          style={{ width: "14%", height: "80px" }}
                        ></td>
                        <td
                          id="t-body"
                          style={{ width: "14%", height: "80px" }}
                        ></td>
                        <td
                          id="t-body"
                          style={{ width: "14%", height: "80px" }}
                        ></td>
                        <td
                          id="t-body-status"
                          style={{ width: "14%", height: "80px" }}
                        ></td>
                        <td className="App-table-actions"></td>
                      </tr>
                      <tr>
                        <td
                          id="t-body"
                          style={{ width: "14%", height: "80px" }}
                        ></td>
                        <td
                          id="t-body"
                          style={{ width: "14%", height: "80px" }}
                        ></td>
                        <td
                          id="t-body"
                          style={{ width: "14%", height: "80px" }}
                        ></td>
                        <td
                          id="t-body-status"
                          style={{ width: "14%", height: "80px" }}
                        ></td>
                        <td
                          id="t-body"
                          style={{ width: "14%", height: "80px" }}
                        ></td>
                      </tr>
                    </tbody>
                  </Table>
                ) : (
                  <div className="App-empty">
                    <Empty />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Device;
