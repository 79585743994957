import { useState, useEffect } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import MiniSidebar from "./MiniSidebar";
import { CheckCircleOutline, InsertPhotoOutlined } from "@mui/icons-material";
import { IMAGE_URL } from "../../../Utils/Config";

const ViewMenuCategory = () => {
  const [saved, setSaved] = useState(false);
  const { company_name } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const item = location.state.data;
  const [time, setTime] = useState("n/a");

  useEffect(() => {
    if (item.updatedAt === null) return;
    setTime(formatToEAT(item.updatedAt));
    // const formattedTime = formatToEAT(item.updatedAt)
    console.log("Item: ", item);
  }, []);

  const formatToEAT = (timestamp) => {
    const date = new Date(timestamp);
    const options = { timeZone: "Africa/Nairobi" };
    return date.toLocaleString("en-US", options);
  };

  return (
    <div>
      <div className="breadcrump">
        <Link to="/admin/restaurants" className="breadcrumb-not-active">
          Home
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link to="/admin/restaurants" className="breadcrumb-active">
          All Restaurants
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link
          onClick={(event) => event.preventDefault()}
          className="breadcrumb-active"
        >
          {company_name}
        </Link>
      </div>
      <div className="App-container-header-table-wrapper">
        <div className="App-container-header">
          <p>{company_name}</p> - <p>View Menu Category</p>
        </div>
        <div className="App-container-table">
          <div className="restaurant-table-wrapper">
            <div style={{ paddingTop: "33px" }}>
              <MiniSidebar />
            </div>
            <div className="table-container">
              <div className="App-table restaurant-app-table">
                <div
                  className="App-container-row-body-full"
                  style={{
                    border: "1px solid #83B302",
                    marginTop: "33px",
                    paddingTop: 0,
                    borderRadius: "7px",
                  }}
                >
                  <div className="menu-category-container">
                    <div className="menu-category-header">
                      <div className="category-icon">
                        <img src={`${IMAGE_URL}${item["image"]}`} alt="" />
                      </div>
                      <div className="category-name">Breakfast</div>
                    </div>
                    <div className="menu-category-body">
                      <div className="details-container">
                        <div className="detail-label">
                          <p>Category Name :</p>
                        </div>
                        <div className="detail-input">
                          <p>{item["name"]}</p>
                        </div>
                      </div>
                      <div className="details-container">
                        <div className="detail-label">
                          <p>Last Updated :</p>
                        </div>
                        <div className="detail-input">
                          <p>
                            {time}
                            {/* {item["last_updated"]} */}
                          </p>
                        </div>
                      </div>
                      {/* <div className="details-container">
                        <div className="details-label">
                          <Link style={{ color: "#83b302" }}>Photo</Link>
                        </div>
                      </div> */}
                      <div className="details-container">
                        <div className="detail-label">
                          <p>Branches :</p>
                        </div>
                        <div className="detail-input">
                          {item.branchCategories?.map((branch, index) => (
                            <span>
                              {branch.branch.branch_name}
                              {index < item.branchCategories.length - 1 && ", "}
                            </span>
                          ))}
                          {/* <p>Kilimani, CBD, Junction, TRM</p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div
                  className="card-footer-container"
                  style={{ paddingTop: "10px" }}
                >
                  <div className="App-input-group-buttons">
                    <div className="App-input-group-button form-app-button">
                      <div
                        className="App-button-add"
                        //   onClick={submitData}
                      >
                        Save Details
                      </div>
                    </div>
                    <div className="App-input-group-button form-app-button">
                      <div
                        className="App-button-cancel"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewMenuCategory;
