import {
  Add,
  Edit,
  Clear,
  Visibility,
  AddCircleOutlineRounded,
  Search,
} from "@mui/icons-material";
import { React, useEffect, useState, useRef } from "react";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import { useNavigate } from "react-router-dom";
import {
  BASE_URL,
  IMAGE_URL,
  ID,
  TOKEN,
  toastError,
  toastSuccess,
  axiosInstance
} from "../../../Utils/Config";
import { ToastContainer, toast } from "react-toastify";
import Badge from "react-bootstrap/Badge";
import Empty from "../../../Utils/Empty";
import { Link } from "react-router-dom";
import SideBar from "./Components/SideBar";
import Header from "./Components/Header";
import QuickLinks from "./Components/QuickLinks";
import Loader from "../../../Utils/Loader";

function Restaurant() {
  document.title = "Restaurants";
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const token = localStorage.getItem(TOKEN);
  const id = localStorage.getItem(ID);
  var count = 1;
  const [showEmpty, setShowEmpty] = useState(false);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState('')
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

  const keys = ['company_name', 'physical_address', 'telephone', 'email_address', 'contact_name', 'status']

  useEffect(() => {
    fetchRestaurants()
    if(sessionStorage.getItem("Restaurant")){
      sessionStorage.removeItem("Restaurant")
    }
  }, []);

  const fetchRestaurants = async () => {
    try {
      const response = await axiosInstance.get(`${BASE_URL}/restaurant`)
      const data = response.data
      setLoading(false)
      if (data.code === 200) {
        setData(data.restaurants)
      } else {
        toastError(data.message)
      }
    } catch (error) {
      toastError(error.code)
      setLoading(false)
    }
  }

  const editRestaurant = (item) => {
    navigate(`/dashboard/edit-restaurant/${item.id}`, { state: { item } })
  };

  const viewRestaurant = (item) => {
    if (item.status === "Active") {
      const itemString = JSON.stringify(item);
      sessionStorage.setItem('Restaurant', itemString);
      navigate(`/dashboard/restaurants/${item.company_name}`)
    } else {
      toastError(item.company_name + " Not Active");
    }


  };

  return (
    <div>
      <div className="breadcrump">
        <Link to="/dashboard/restaurants" className="breadcrumb-not-active">
          Home
        </Link>
        <span className="breadcrumb-arrow">&gt;</span>
        <Link
          onClick={(event) => event.preventDefault()}
          className="breadcrumb-active"
        >
          All Restaurants
        </Link>
      </div>

      <div className="App-container-header-table-wrapper">
        <div className="App-container-header">
          <p>All Restaurants</p>
        </div>
        <div className="App-container-table">
          <div className="App-container-table-top-bar">
            <div className="Search-bar">
              <Search />
              <input type="search" name="" id="" placeholder="Search..." onChange={(e) => setQuery(e.target.value)} />
            </div>
            <div
              className="App-right"
              onClick={() => navigate("/dashboard/add-restaurant")}
            >
              <AddCircleOutlineRounded
                sx={{ color: "white", marginRight: "10px" }}
              />
              <div className="App-title-right">Restaurant</div>
            </div>
          </div>

          <div className="App-table restaurants-app-table">
            {loading === true ? (
              <div className="App-loader">
                <Loader />
              </div>
            ) : (
              <></>
            )}
            {showEmpty !== true ? (
              <>
                <Table stripped="true" bordered responsive size="xl">
                  <thead id="Table-heading-wrapper">
                    <tr>
                      {/* <th>#</th> */}
                      <th id="t-heading">COMPANY NAME</th>
                      <th id="t-heading">PHYSICAL ADDRESS</th>
                      <th id="t-heading">TELEPHONE</th>
                      <th id="t-heading">EMAIL</th>
                      <th id="t-heading">CONTACT NAME</th>
                      <th id="t-heading">PHOTO</th>
                      <th id="t-heading">STATUS</th>
                      <th id="t-heading">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems?.filter(item =>
                      keys.some(key => item[key].toLowerCase().includes(query))
                    ).map((item) => (
                      <tr key={item.id}>
                        {/* <td>{count++}</td> */}
                        <td id="t-body">
                          <p>{item.company_name}</p>
                        </td>
                        <td id="t-body">
                          <p>{item.physical_address}</p>
                        </td>
                        <td id="t-body">
                          <p>{item.telephone}</p>
                        </td>
                        <td id="t-body">
                          <p>{item.email_address}</p>
                        </td>
                        <td id="t-body">
                          <p>{item.contact_name}</p>
                        </td>
                        <td id="t-body-image">
                                <div id="p-image">
                                  <img
                                    src={`${IMAGE_URL}${item.logo}`}
                                    alt="image"
                                  />
                                </div>
                              </td>
                        <td id="t-body-status">
                          {item.status === "Active" ? (
                            // <Badge bg="success">Active</Badge>
                            <div className="company-status">
                              <p id="status" className="active">Active</p>
                            </div>
                          ) : (
                            <div className="company-status">
                              <p id="status" className="inactive">Inactive</p>
                            </div>
                          )}
                        </td>
                        <td className="App-table-actions">
                          <div
                            className="App-table-action-button"
                            onClick={() => viewRestaurant(item)}
                            style={{
                              background: "#337CA0",
                            }}
                          >
                            View
                          </div>
                          <div
                            className="App-table-action-button"
                            onClick={() => editRestaurant(item)}
                            style={{
                              backgroundColor: "#464E47",
                            }}
                          >
                            Edit
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pagination className="pagination">
                  <Pagination.Prev
                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                  />
                  {Array.from({ length: Math.ceil(data?.length / itemsPerPage) }).map(
                    (page, index) => (
                      <Pagination.Item
                        key={index + 1}
                        active={index + 1 === currentPage}
                        onClick={() => setCurrentPage(index + 1)}
                      >
                        {index + 1}
                      </Pagination.Item>
                    )
                  )}
                  <Pagination.Next
                    onClick={() =>
                      setCurrentPage((prev) =>
                        Math.min(prev + 1, Math.ceil(data?.length / itemsPerPage))
                      )
                    }
                  />
                </Pagination>

              </>
            ) : (
              <div className="App-empty">
                <Empty />
              </div>
            )}
          </div>
        </div>
      </div>

      <ToastContainer theme="colored" autoClose={3500} hideProgressBar={true} />
    </div>
  );
}

export default Restaurant;
