import { useState, useEffect } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import MiniSidebar from "./MiniSidebar";
import {
	CheckCircleOutline,
	InsertPhotoOutlined,
	Edit,
} from "@mui/icons-material";
import { ToastContainer } from "react-toastify";
import {
	BASE_URL,
	axiosInstance,
	toastError,
	toastSuccess,
	validateItemUpdate,
} from "../../../Utils/Config";

const EditQrCode = () => {
	const [saved, setSaved] = useState(false);
	const { company_name, id } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const { qrcode } = location.state;
	const { userId } = JSON.parse(sessionStorage.getItem("userId"));
	const [formState, setFormState] = useState({
		tableNo: qrcode["tableNumber"],
	});

	const changeHandler = (event) => {
		const { name, value } = event.target;
		setFormState((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const submitHandler = async () => {
		const payload = {
			tableNumber: formState.tableNo,
		};
		try {
			const response = await axiosInstance.put(
				`${BASE_URL}/qr/${id}/${userId}`,
				payload
			);
			const data = response.data;
			if (data.code === 200) {
				toastSuccess(data.message);
				setTimeout(() => {
					navigate(-1);
				}, 1700);
			} else {
				toastError(data.message);
			}
		} catch (error) {
			toastError(error.code);
		}
	};

	return (
		<div>
			<div className="breadcrump">
				<Link to="/dashboard/restaurants" className="breadcrumb-not-active">
					Home
				</Link>
				<span className="breadcrumb-arrow">&gt;</span>
				<Link to="/dashboard/restaurants" className="breadcrumb-active">
					All Restaurants
				</Link>
				<span className="breadcrumb-arrow">&gt;</span>
				<Link
					onClick={(event) => event.preventDefault()}
					className="breadcrumb-active">
					{company_name}
				</Link>
				<span className="breadcrumb-arrow">&gt;</span>
				<Link
					to={`/dashboard/restaurants/${company_name}/qr-codes`}
					className="breadcrumb-active">
					QR Codes
				</Link>
			</div>
			<div className="App-container-header-table-wrapper">
				<div className="App-container-header">
					<p>{company_name}</p> - <p>Edit QR Code</p>
				</div>
				<div className="App-container-table">
					<div className="restaurant-table-wrapper">
						<div style={{ paddingTop: "33px" }}>
							<MiniSidebar />
						</div>
						<div className="table-container">
							<div className="App-table restaurant-app-table">
								<div className="App-container-row-body-full">
									<div className="App-input-group">
										<label htmlFor="">Table Number</label>
										<div className="input-container">
											<input
												type="text"
												id=""
												name="tableNo"
												placeholder="Table Number"
												value={formState.tableNo}
												onChange={changeHandler}
												className="App-input-add"
												required
											/>
										</div>
									</div>
								</div>
								<div
									className="card-footer-container"
									style={{ paddingTop: "50px" }}>
									{saved && (
										<div className="saved-toast">
											<span>
												<CheckCircleOutline />
											</span>
											<span style={{ marginLeft: "10px" }}>
												<strong>Success</strong>. You successfully added a new
												menu category.
											</span>
										</div>
									)}
									<div className="App-input-group-buttons">
										<div className="App-input-group-button form-app-button">
											<div className="App-button-add" onClick={submitHandler}>
												Save Details
											</div>
										</div>
										<div className="App-input-group-button form-app-button">
											<div
												className="App-button-cancel"
												onClick={() => navigate(-1)}>
												Cancel
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ToastContainer theme="colored" autoClose={1500} hideProgressBar={true} />
		</div>
	);
};

export default EditQrCode;
