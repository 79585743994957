import { useState, useEffect } from "react";
import Empty from "../../../Utils/Empty";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import MiniSidebar from "./MiniSidebar";
import { AddCircleOutlineRounded, Search } from "@mui/icons-material";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
	BASE_URL,
	toastError,
	axiosInstance,
	toastSuccess,
} from "../../../Utils/Config";
import { ToastContainer } from "react-toastify";

const Promotions = () => {
	const navigate = useNavigate();
	const [showEmpty, setShowEmpty] = useState(false);
	const [data, setData] = useState([]);
	const { company_name } = useParams();
	const itemString = sessionStorage.getItem("Restaurant");
	const item = JSON.parse(itemString);
	const [query, setQuery] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(10);
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

	const keys = ["name", "itemName"];

	const editPromotion = (data) => {
		navigate(
			`/dashboard/restaurants/${company_name}/edit-promotion/${data.id}`,
			{
				state: { data },
			}
		);
	};

	const deletePromotion = async (id) => {
		const isConfirmed = window.confirm(
			"Are you sure you want to delete this promotion"
		);
		if (isConfirmed) {
			try {
				const response = await axiosInstance.delete(
					`${BASE_URL}/promotion/${id}`
				);
				const data = response.data;
				if (data.code === 200) {
					toastSuccess(data.message);
					fetchPromotions();
				} else {
					toastError(data.message);
				}
			} catch (error) {
				toastError(error.code);
			}
		} else {
			return;
		}
	};

	useEffect(() => {
		fetchPromotions();
	}, []);

	const fetchPromotions = async () => {
		const response = await axiosInstance.get(
			`${BASE_URL}/promotion/restaurant/${item["id"]}`
		);
		const data = response.data;
		if (data.code === 200) {
			const updatedPromotions = data.promotions.map((promotion) => {
				const startDateTimeParts = promotion.start.split(" ");
				const startDate = startDateTimeParts[0];
				const startTime = startDateTimeParts[1];

				const endDateTimeParts = promotion.end.split(" ");
				const endDate = endDateTimeParts[0];
				const endTime = endDateTimeParts[1];

				return {
					...promotion,
					startDate,
					startTime,
					endDate,
					endTime,
				};
			});
			setData(updatedPromotions);
		}
	};

	return (
		<div>
			<div className="breadcrump">
				<Link to="/dashboard/restaurants" className="breadcrumb-not-active">
					Home
				</Link>
				<span className="breadcrumb-arrow">&gt;</span>
				<Link to="/dashboard/restaurants" className="breadcrumb-active">
					All Restaurants
				</Link>
				<span className="breadcrumb-arrow">&gt;</span>
				<Link
					onClick={(event) => event.preventDefault()}
					className="breadcrumb-active">
					{company_name}
				</Link>
			</div>
			<div className="App-container-header-table-wrapper">
				<div className="App-container-header">
					<p>{company_name}</p> - <p>Promotions</p>
				</div>
				<div className="App-container-table">
					<div className="App-container-table-top-bar">
						<div
							className="Search-bar"
							style={{ backgroundColor: "#83B3020D" }}>
							<Search />
							<input
								type="search"
								name=""
								id=""
								placeholder="Search..."
								style={{ backgroundColor: "inherit" }}
								onChange={(e) => setQuery(e.target.value)}
							/>
						</div>
						<div
							className="App-right"
							//   onClick={() => navigate("/dashboard/add-restaurant")}
							onClick={() =>
								navigate(
									`/dashboard/restaurants/${company_name}/add-promotions`
								)
							}>
							<AddCircleOutlineRounded
								sx={{ color: "white", marginRight: "10px" }}
							/>
							<div className="App-title-right">Add Promotion</div>
						</div>
					</div>
					<div className="restaurant-table-wrapper">
						<MiniSidebar />
						<div className="table-container">
							<div className="App-table restaurants-app-table">
								{data.length > 0 ? (
									<>
										<Table stripped="true" bordered responsive size="xl">
											<thead id="Table-heading-wrapper">
												<tr>
													{/* <th>#</th> */}
													<th id="t-heading">Promotion Name</th>
													<th id="t-heading">Item Name</th>
													<th id="t-heading">Discount</th>
													<th id="t-heading">Branch</th>
													<th id="t-heading">Period(Dates)</th>
													<th id="t-heading">Time</th>
													<th id="t-heading">Status</th>
													<th id="t-heading">Action</th>
												</tr>
											</thead>
											<tbody>
												{currentItems
													?.filter((promotion) =>
														keys.some((key) =>
															promotion[key]?.toLowerCase().includes(query)
														)
													)
													.map((promotion) => {
														return (
															<tr key={promotion.id}>
																<td id="t-body">
																	<p>{promotion.name}</p>
																</td>
																<td id="t-body">
																	<p>{promotion.itemName}</p>
																</td>
																{promotion.discountType === "percentage" ? (
																	<td id="t-body">
																		<p>{promotion.discountValue}%</p>
																	</td>
																) : (
																	<td id="t-body">
																		<p>{promotion.discountValue} Ksh</p>
																	</td>
																)}
																<td id="t-body">
																	{/* <p>{promotion.branch.branch_name}</p> */}
																	<p>
																		{promotion.branch &&
																			promotion.branch?.branch_name}
																	</p>
																</td>
																<td id="t-body">
																	<p>
																		{promotion.startDate} - {promotion.endDate}
																	</p>
																</td>
																<td id="t-body">
																	<p>
																		{promotion.startTime} - {promotion.endTime}
																	</p>
																</td>
																<td id="t-body-status">
																	<div className="company-status">
																		<p id="status" className="active">
																			Active
																		</p>
																	</div>
																</td>
																<td className="App-table-actions">
																	<div
																		className="App-table-action-button"
																		onClick={() => editPromotion(promotion)}
																		style={{
																			backgroundColor: "#337CA0",
																		}}>
																		Edit
																	</div>
																	<div
																		className="App-table-action-button"
																		onClick={() =>
																			deletePromotion(promotion.id)
																		}
																		style={{
																			backgroundColor: "red",
																		}}>
																		Delete
																	</div>
																</td>
															</tr>
														);
													})}
											</tbody>
										</Table>
										<Pagination className="pagination">
											<Pagination.Prev
												onClick={() =>
													setCurrentPage((prev) => Math.max(prev - 1, 1))
												}
											/>
											{Array.from({
												length: Math.ceil(data?.length / itemsPerPage),
											}).map((page, index) => (
												<Pagination.Item
													key={index + 1}
													active={index + 1 === currentPage}
													onClick={() => setCurrentPage(index + 1)}>
													{index + 1}
												</Pagination.Item>
											))}
											<Pagination.Next
												onClick={() =>
													setCurrentPage((prev) =>
														Math.min(
															prev + 1,
															Math.ceil(data?.length / itemsPerPage)
														)
													)
												}
											/>
										</Pagination>
									</>
								) : (
									<div className="App-empty">
										<Empty />
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<ToastContainer theme="colored" autoClose={1500} hideProgressBar={true} />
		</div>
	);
};

export default Promotions;
